// Absolute
import React from 'react';

// Custom hooks
import { useShallowSelector, useActions } from '@helpers/hooks';

import MyProfile from './MyProfile';
import * as authActions from '@actions/auth';

// Selectors
import * as authSelectors from '@reducers/auth';

export default function ConnectedMyProfile() {
  const user = useShallowSelector(authSelectors.userSelector);
  const isProfileUserDataSaved = useShallowSelector(authSelectors.isProfileUserDataSavedSelector);
  const isUploadingPhoto = useShallowSelector(authSelectors.isUploadingPhotoSelector);
  const isPhotoLoaded = useShallowSelector(authSelectors.isPhotoLoadedSelector);
  const uploadPhotoError = useShallowSelector(authSelectors.uploadPhotoErrorSelector);
  const attemptForSaveProfileData = useShallowSelector(authSelectors.attemptForSaveProfileDataSelector);
  const saveProfileUserDataError = useShallowSelector(authSelectors.saveProfileUserDataErrorSelector);
  const fetchProfileUserError = useShallowSelector(authSelectors.fetchProfileUserErrorSelector);

  const isPasswordSaved = useShallowSelector(authSelectors.isPasswordSavedSelector);
  const savePasswordError = useShallowSelector(authSelectors.savePasswordErrorSelector);
  const attemptForSavePassword = useShallowSelector(authSelectors.attemptForSavePasswordSelector);

  const {
    fetchProfileUser,
    saveProfileUserData,
    uploadPhoto,
    resetIsPhotoLoadedVariable,
    saveUserPassword,
    resetIsPasswordSavedVariable,
    resetIsProfileUserDataSavedVariable
  } = useActions(authActions);

  const props = {
    user,
    saveProfileUserData,
    fetchProfileUser,
    uploadPhoto,
    isProfileUserDataSaved,
    isUploadingPhoto,
    isPhotoLoaded,
    attemptForSaveProfileData,
    saveProfileUserDataError,
    uploadPhotoError,
    fetchProfileUserError,
    resetIsPhotoLoadedVariable,
    saveUserPassword,
    isPasswordSaved,
    savePasswordError,
    attemptForSavePassword,
    resetIsPasswordSavedVariable,
    resetIsProfileUserDataSavedVariable
  };

  return <MyProfile {...props} />;
}
