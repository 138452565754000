// Libraries
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PropTypesAirbnb from 'airbnb-prop-types';
// Components
import CheckedFormInput from '../CheckedFormInput';
import Table from '../Table/Table';
import Pagination from '../Pagination/Pagination';
import Modal from '../Modal/Modal';
import { Input } from '../UI';
// Utils
import * as valueHelpers from '@helpers/valueHelpers';
import * as objectHelpers from '@helpers/objectHelpers';
import * as functionHelpers from '@helpers/functionHelpers';
import * as errorHelpers from '@helpers/errorHelpers';
import * as validationHelpers from '@helpers/validate';
import { useActions } from '@helpers/hooks';
import * as settingsActions from '@actions/settings';
import { generalLimitsSelector, individualLimitsSelector } from '@reducers/settings';
// Constants
import * as constants from '@constants';
// Styles
import * as Styled from './styles';
import * as StyledUI from '../UI';

LimitSettings.propTypes = {
  backendError: PropTypesAirbnb.or([PropTypes.object, PropTypesAirbnb.explicitNull]).isRequired,
  isSaving: PropTypes.bool.isRequired
};

const tableHeaders = ['User ID', 'Buying daily limit', 'Selling daily limit', 'Selling transaction limit', 'Button'];

export default function LimitSettings({ backendError, isSaving }) {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [changeModalData, setChangeModalData] = useState({
    id: null,
    email: '',
    buyDailyLimitUSD: '',
    sellDailyLimitUSD: '',
    sellTxLimitUSD: ''
  });
  const generalLimits = useSelector(generalLimitsSelector);
  const individualLimits = useSelector(individualLimitsSelector);
  const {
    fetchGeneralLimits,
    fetchIndividualLimits,
    removeIndividualLimit,
    setIndividualLimit,
    setGeneralLimit
  } = useActions(settingsActions);

  const validateLimitField = useMemo(
    () =>
      validationHelpers.createRangeFieldValidator({
        from: constants.MIN_EEX_LIMIT_USD,
        to: constants.MAX_EEX_LIMIT_USD
      }),
    []
  );

  const generalFormInitial = useMemo(
    () => ({
      buyDailyLimitUSD: generalLimits?.buyDailyLimitUSD,
      sellDailyLimitUSD: generalLimits?.sellDailyLimitUSD,
      sellTxLimitUSD: generalLimits?.sellTxLimitUSD
    }),
    [generalLimits]
  );

  const individualLimitInitial = useMemo(
    () => ({
      email: '',
      buyDailyLimitUSD: '',
      sellDailyLimitUSD: '',
      sellTxLimitUSD: ''
    }),
    []
  );

  const modalFormInitial = useMemo(
    () => ({
      email: changeModalData.email,
      buyDailyLimitUSD: changeModalData.buyDailyLimitUSD,
      sellDailyLimitUSD: changeModalData.sellDailyLimitUSD,
      sellTxLimitUSD: changeModalData.sellTxLimitUSD
    }),
    [changeModalData]
  );

  const {
    Form,
    values: generalValues,
    meta: { canSubmit: generalCanSubmit, isSubmitted: generalIsSubmitted },
    getFieldMeta: getGeneralFieldMeta
  } = useForm({
    defaultValues: generalFormInitial,
    onSubmit: setGeneralLimit
  });

  const generalFormErrors = functionHelpers.pipe(
    objectHelpers.mapValues((_, key) => getGeneralFieldMeta(key)?.error),
    objectHelpers.filterEntries((_, value) => Boolean(value))
  )(generalValues);

  const generalErrorText = errorHelpers.formatFieldErrors(generalFormErrors);
  const generalSaveSucceeded = generalIsSubmitted && !isSaving && !backendError;
  const generalIsShowingSuccessText = generalSaveSucceeded && objectHelpers.isEmpty(generalFormErrors);
  const generalIsSaveEnabled = generalCanSubmit && objectHelpers.every((_, value) => value !== '')(generalValues);

  const {
    Form: IndividualFrom,
    values: individualValues,
    meta: { canSubmit: individualCanSubmit, isSubmitted: individualIsSubmitted },
    getFieldMeta: getIndividualFieldMeta
  } = useForm({ defaultValues: individualLimitInitial, onSubmit: setIndividualLimit });

  const individualFormErrors = functionHelpers.pipe(
    objectHelpers.mapValues((_, key) => getIndividualFieldMeta(key)?.error),
    objectHelpers.filterEntries((_, value) => Boolean(value))
  )(individualValues);

  const individualErrorText = errorHelpers.formatFieldErrors(individualFormErrors);
  const individualSaveSucceeded = individualIsSubmitted && !isSaving && !backendError;
  const individualIsShowingSuccessText = individualSaveSucceeded && objectHelpers.isEmpty(individualFormErrors);
  const individualIsSaveEnabled =
    individualCanSubmit && objectHelpers.every((_, value) => value !== '')(individualValues);

  const {
    Form: ModalForm,
    values: modalValues,
    meta: { canSubmit: modalCanSubmit, isSubmitted: modalIsSubmitted },
    getFieldMeta: getModalFieldMeta
  } = useForm({
    defaultValues: modalFormInitial,
    onSubmit: payload => {
      setIndividualLimit(payload, currentPage);
    }
  });

  const modalFormErrors = functionHelpers.pipe(
    objectHelpers.mapValues((_, key) => getModalFieldMeta(key)?.error),
    objectHelpers.filterEntries((_, value) => Boolean(value))
  )(modalValues);

  const modalErrorText = errorHelpers.formatFieldErrors(modalFormErrors);
  const modalSaveSucceeded = modalIsSubmitted && !isSaving && !backendError;
  const modalIsShowingSuccessText = modalSaveSucceeded && objectHelpers.isEmpty(modalFormErrors);
  const modalIsSaveEnabled = modalCanSubmit && objectHelpers.every((_, value) => value !== '')(modalValues);

  const setPage = useCallback(
    pageNumber => {
      setCurrentPage(pageNumber);
      fetchIndividualLimits({
        limit: constants.INDIVIDUAL_LIMITS_PAGE,
        skip: (pageNumber - 1) * constants.INDIVIDUAL_LIMITS_PAGE,
        search
      });
    },
    [fetchIndividualLimits, search]
  );

  const removeLimit = useCallback(id => removeIndividualLimit({ id, currentPage }), [currentPage, removeIndividualLimit]);
  const handleSearch = useCallback(
    () =>
      fetchIndividualLimits({
        limit: constants.INDIVIDUAL_LIMITS_PAGE,
        skip: 0,
        search
      }),
    [fetchIndividualLimits, search]
  );

  const handleChange = useCallback(e => setSearch(e.target.value), []);

  // useEffect(() => {
  //   for (const field in backendError) {
  //     const message = backendError[field].message;
  //     setGeneralFieldMeta(field, meta => ({ ...meta, error: message, isTouched: true }));
  //   }
  // }, [backendError, setGeneralFieldMeta]);

  useEffect(() => {
    fetchGeneralLimits();

  }, [fetchGeneralLimits]);

  const rowJSX = useMemo(
    () =>
      individualLimits?.data?.map(
        ({
          buyDailyLimitEEX,
          buyDailyLimitUSD,
          email,
          _id,
          sellDailyLimitEEX,
          sellDailyLimitUSD,
          sellTxLimitEEX,
          sellTxLimitUSD
        }) => ({
          userID: email,
          buyingDailyLimit: (
            <>
              <Styled.RowText>${buyDailyLimitUSD}</Styled.RowText>
              <Styled.RowText light> / {buyDailyLimitEEX?.substr(0, 10)} EEX</Styled.RowText>
            </>
          ),
          sellingDailyLimit: (
            <>
              <Styled.RowText>${sellDailyLimitUSD}</Styled.RowText>
              <Styled.RowText light> / {sellDailyLimitEEX?.substr(0, 10)} EEX</Styled.RowText>
            </>
          ),
          sellingTransactionLimit: (
            <>
              <Styled.RowText>${sellTxLimitUSD}</Styled.RowText>
              <Styled.RowText light> / {sellTxLimitEEX?.substr(0, 10)} EEX</Styled.RowText>
            </>
          ),
          button: (
            <StyledUI.Flex>
              <StyledUI.Button
                style={{
                  width: 150,
                  textTransform: 'none',
                  border: '1px solid rgba(186, 38, 18, 0.2)',
                  color: '#ba2612'
                }}
                onClick={() => removeLimit(_id)}
              >
                Reset to default
              </StyledUI.Button>
              <StyledUI.Button
                style={{
                  width: 150,
                  textTransform: 'none',
                  border: '1px solid rgba(68, 30, 218, 0.2)',
                  color: '#441EDA'
                }}
                onClick={() => {
                  setChangeModalData({
                    email,
                    buyDailyLimitUSD,
                    sellDailyLimitUSD,
                    sellTxLimitUSD,
                    id: _id
                  });
                  setShowModal(true);
                }}
              >
                Change
              </StyledUI.Button>
            </StyledUI.Flex>
          )
        })
      ) ?? [],
    [individualLimits, removeLimit]
  );

  return (
    <>
      <Styled.RootContainer>
        <Styled.FormContainer>
          <Styled.Title>General limits (USD)</Styled.Title>
          <Form>
            <Styled.InputsWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel weight="600">Buying daily limit</Styled.FormLabel>
                <CheckedFormInput
                  type="text"
                  field="buyDailyLimitUSD"
                  check={valueHelpers.isNonNegativeNumericString}
                  validate={validateLimitField}
                  placeholder="Enter new value"
                  data-test="limit-eex-amount-input"
                />
              </StyledUI.FormLabelWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel weight="600">Selling daily limit</Styled.FormLabel>
                <CheckedFormInput
                  type="text"
                  field="sellDailyLimitUSD"
                  check={valueHelpers.isNonNegativeNumericString}
                  validate={validateLimitField}
                  placeholder="Enter new value"
                  autoComplete="on"
                  data-test="limit-user-email-input"
                />
              </StyledUI.FormLabelWrapper>
            </Styled.InputsWrapper>
            <Styled.InputsWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel weight="600">Selling transaction limit</Styled.FormLabel>
                <CheckedFormInput
                  type="text"
                  field="sellTxLimitUSD"
                  check={valueHelpers.isNonNegativeNumericString}
                  validate={validateLimitField}
                  placeholder="Enter new value"
                  data-test="limit-eex-amount-input"
                />
              </StyledUI.FormLabelWrapper>
            </Styled.InputsWrapper>
            <StyledUI.Flex margin="40px 0 0 0">
              <StyledUI.SettingsButton disabled={!generalIsSaveEnabled} data-test="limit-settings-save-btn">
                Save
              </StyledUI.SettingsButton>
              <StyledUI.SaveStatus
                success={generalIsShowingSuccessText}
                margin="0 0 0 30px"
                data-test="limit-settings-save-status-msg"
              >
                {generalIsShowingSuccessText ? 'Changes saved successfully' : generalErrorText}
              </StyledUI.SaveStatus>
            </StyledUI.Flex>
          </Form>
        </Styled.FormContainer>
        <Styled.FormContainer>
          <Styled.Title>Individual user limit (USD)</Styled.Title>
          <IndividualFrom>
            <Styled.InputsWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel>Email</Styled.FormLabel>
                <CheckedFormInput
                  type="email"
                  field="email"
                  validate={validationHelpers.validateEmailField}
                  placeholder="Enter email address"
                  autoComplete="on"
                  data-test="limit-user-email-input"
                />
              </StyledUI.FormLabelWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel>Buying daily limit</Styled.FormLabel>
                <CheckedFormInput
                  type="text"
                  field="buyDailyLimitUSD"
                  check={valueHelpers.isNonNegativeNumericString}
                  validate={validateLimitField}
                  placeholder="Enter new value"
                  data-test="limit-eex-amount-input"
                />
              </StyledUI.FormLabelWrapper>
            </Styled.InputsWrapper>
            <Styled.InputsWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel>Selling daily limit</Styled.FormLabel>
                <CheckedFormInput
                  type="text"
                  field="sellDailyLimitUSD"
                  check={valueHelpers.isNonNegativeNumericString}
                  validate={validateLimitField}
                  placeholder="Enter new value"
                  data-test="limit-eex-amount-input"
                />
              </StyledUI.FormLabelWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel>Selling transaction limit</Styled.FormLabel>
                <CheckedFormInput
                  type="text"
                  field="sellTxLimitUSD"
                  check={valueHelpers.isNonNegativeNumericString}
                  validate={validateLimitField}
                  placeholder="Enter new value"
                  data-test="limit-user-email-input"
                />
              </StyledUI.FormLabelWrapper>
            </Styled.InputsWrapper>
            <StyledUI.Flex margin="40px 0 0 0">
              <StyledUI.SettingsButton disabled={!individualIsSaveEnabled} data-test="limit-settings-save-btn">
                Save
              </StyledUI.SettingsButton>
              <StyledUI.SaveStatus
                success={individualIsShowingSuccessText}
                margin="0 0 0 30px"
                data-test="limit-settings-save-status-msg"
              >
                {individualIsShowingSuccessText ? 'Changes saved successfully' : individualErrorText}
              </StyledUI.SaveStatus>
            </StyledUI.Flex>
          </IndividualFrom>
        </Styled.FormContainer>
      </Styled.RootContainer>
      {showModal && (
        <Modal title="Changing the individual limit" close={() => setShowModal(false)} width="500px">
          <ModalForm>
            <Styled.InputsWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel>Email</Styled.FormLabel>
                <CheckedFormInput
                  type="email"
                  field="email"
                  validate={validationHelpers.validateEmailField}
                  placeholder="Enter email address"
                  disabled
                  data-test="limit-user-email-input"
                />
              </StyledUI.FormLabelWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel>Buying daily limit</Styled.FormLabel>
                <CheckedFormInput
                  type="text"
                  field="buyDailyLimitUSD"
                  check={valueHelpers.isNonNegativeNumericString}
                  validate={validateLimitField}
                  placeholder="Enter new value"
                  data-test="limit-eex-amount-input"
                />
              </StyledUI.FormLabelWrapper>
            </Styled.InputsWrapper>
            <Styled.InputsWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel>Selling daily limit</Styled.FormLabel>
                <CheckedFormInput
                  type="text"
                  field="sellDailyLimitUSD"
                  check={valueHelpers.isNonNegativeNumericString}
                  validate={validateLimitField}
                  placeholder="Enter new value"
                  data-test="limit-eex-amount-input"
                />
              </StyledUI.FormLabelWrapper>
              <StyledUI.FormLabelWrapper>
                <Styled.FormLabel>Selling transaction limit</Styled.FormLabel>
                <CheckedFormInput
                  type="text"
                  field="sellTxLimitUSD"
                  check={valueHelpers.isNonNegativeNumericString}
                  validate={validateLimitField}
                  placeholder="Enter new value"
                  data-test="limit-user-email-input"
                />
              </StyledUI.FormLabelWrapper>
            </Styled.InputsWrapper>
            <StyledUI.Flex margin="40px 0 0 0">
              <StyledUI.SettingsButton disabled={!modalIsSaveEnabled} data-test="limit-settings-save-btn">
                Save
              </StyledUI.SettingsButton>
              <StyledUI.SaveStatus
                success={modalIsShowingSuccessText}
                margin="0 0 0 30px"
                data-test="limit-settings-save-status-msg"
              >
                {modalIsShowingSuccessText ? 'Changes saved successfully' : modalErrorText}
              </StyledUI.SaveStatus>
            </StyledUI.Flex>
          </ModalForm>
        </Modal>
      )}
      <StyledUI.Flex align="center" margin="30px 0 0 0">
        <Styled.Title margin="30px">Users with individual limits</Styled.Title>
        <Input value={search} onChange={handleChange} type="text" name="search" placeholder="Enter user ID" />
        <StyledUI.SettingsButton margin="0 0 0 30px" onClick={handleSearch}>
          Search
        </StyledUI.SettingsButton>
      </StyledUI.Flex>
      <Table items={rowJSX} headers={tableHeaders} />
      <Pagination
        itemsPerPage={constants.INDIVIDUAL_LIMITS_PAGE}
        totalCount={individualLimits?.total}
        setPage={setPage}
        page={currentPage}
      />
    </>
  );
}
