import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

// Styles
import * as StyledUI from '../UI';

// Constants
import * as routes from '@routes';
import * as routerSelectors from '@reducers/router';

// Components
import PaymentOrders from '../PaymentOrders';
import PaymentHistory from '../PaymentHistory';

// Theme
import * as colors from '@theme/colors';

export default function Payments() {
  const currentRoute = useSelector(routerSelectors.pathnameSelector);
  return (
    <>
      <StyledUI.H2 color={colors.black} margin="5px 0 25px 0">
        Payments
      </StyledUI.H2>
      <StyledUI.Tabs>
        <StyledUI.TabLink
          active={currentRoute === routes.PAYMENTS_ORDER}
          to={routes.PAYMENTS_ORDER}
          data-test="fee-settings-tab-link"
        >
          Payout orders
        </StyledUI.TabLink>
        <StyledUI.TabLink
          active={currentRoute === routes.PAYMENTS_HISTORY}
          to={routes.PAYMENTS_HISTORY}
          data-test="limit-settings-tab-link"
        >
          History
        </StyledUI.TabLink>
      </StyledUI.Tabs>
      <Switch>
        <Redirect exact from={routes.PAYMENTS} to={routes.PAYMENTS_ORDER} />
        <Route exact path={routes.PAYMENTS_ORDER} component={PaymentOrders} />
        <Route exact path={routes.PAYMENTS_HISTORY} component={PaymentHistory} />
      </Switch>
    </>
  );
}
