import React, { useEffect } from 'react';
import FeeSettings from './FeeSettings';
import { useShallowSelector, useActions } from '@helpers/hooks';
import * as settingsActions from '@actions/settings';
import * as settingsSelectors from '@reducers/settings';

export default function ConnectedFeeSettings() {
  const feeInEex = useShallowSelector(settingsSelectors.feeInEexSelector);
  const feeInPercents = useShallowSelector(settingsSelectors.feeInPercentsSelector);
  const salePrice = useShallowSelector(settingsSelectors.salePriceSelector);
  const purchasePrice = useShallowSelector(settingsSelectors.purchasePriceSelector);
  const fetchFeeSettingsError = useShallowSelector(settingsSelectors.fetchFeeSettingsErrorSelector);
  const updateFeeSettingsPending = useShallowSelector(settingsSelectors.updateFeeSettingsPendingSelector);
  const updateFeeSettingsError = useShallowSelector(settingsSelectors.updateFeeSettingsErrorSelector);
  const { fetchFeeSettings, updateFeeSettings } = useActions(settingsActions);
  useEffect(() => {
    fetchFeeSettings();
  }, [fetchFeeSettings]);
  const props = {
    currentFeeInEex: feeInEex,
    currentFeeInPercents: feeInPercents,
    currentSalePrice: salePrice,
    currentPurchasePrice: purchasePrice,
    backendError: fetchFeeSettingsError || updateFeeSettingsError,
    isSaving: updateFeeSettingsPending,
    onSave: updateFeeSettings
  };
  return <FeeSettings {...props} />;
}
