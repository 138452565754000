// Absolute imports
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PropTypesAirbnb from 'airbnb-prop-types';

// Styles
import { linkStyles } from '@theme/linkStyles';
import * as StyledUI from '../UI';

// Components
import Loading from '../Loading/Loading';
import PopUp from '../PopUp/PopUp';
import SuccessPopUp from '../SuccessPopUp/SuccessPopUp';
import ErrorPopUp from '../ErrorPopUp/ErrorPopUp';

// Constants
import { RESTORE_PASSWORD_FORM_STEP_1, RESTORE_PASSWORD_FORM_STEP_2 } from '@constants';

// Helpers
import { validateEmailField } from '@helpers';

// Theme
import * as colors from '@theme/colors';

Restore.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  isPasswordReset: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.bool]).isRequired,
  resetPasswordError: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired,
  resetPasswordAttempt: PropTypes.number.isRequired,
  resetIsPasswordResetVariable: PropTypes.func.isRequired
};

export default function Restore({
  resetPassword,
  isPasswordReset,
  resetPasswordError,
  resetPasswordAttempt,
  resetIsPasswordResetVariable
}) {
  const [{ step, email, emailError, isLoading, isBtnDisabled, timesClicked }, setState] = useState({
    step: 1,
    email: '',
    emailError: null,
    isLoading: false,
    isBtnDisabled: false,
    timesClicked: 0
  });

  const [{ isPasswordResetSuccessPopUpShown, isPasswordResetErrorPopUpShown }, setPopUps] = useState({
    isPasswordResetSuccessPopUpShown: false,
    isPasswordResetErrorPopUpShown: false
  });

  useEffect(() => {
    if (isPasswordReset) {
      setPopUps(values => ({
        ...values,
        isPasswordResetSuccessPopUpShown: true
      }));
      setTimeout(() => {
        setPopUps(values => ({
          ...values,
          isPasswordResetSuccessPopUpShown: false
        }));
      }, 2000);
    }
  }, [isPasswordReset, resetPasswordAttempt]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isPasswordReset && step < 2) {
      setState(values => ({
        ...values,
        step: values.step + 1
      }));
    } else if (isPasswordReset === false) {
      setState(values => ({
        ...values,
        isLoading: false,
        isBtnDisabled: false,
        email: '',
        emailError: resetPasswordError
      }));
    }
  }, [isPasswordReset, resetPasswordAttempt]);

  useEffect(() => {
    if (resetPasswordError) {
      setPopUps(values => ({
        ...values,
        isPasswordResetErrorPopUpShown: true
      }));
      setTimeout(() => {
        setPopUps(values => ({
          ...values,
          isPasswordResetErrorPopUpShown: false
        }));
      }, 2000);
    }
  }, [resetPasswordError]);

  const nextStep = () => {
    if (step === 1) {
      const emailError = validateEmail(email);

      if (emailError) {
        setState(values => ({
          ...values,
          emailError,
          email: ''
        }));
        return;
      }

      setState(values => ({
        ...values,
        isLoading: true,
        isBtnDisabled: true,
        emailError: null
      }));

      resetPassword(email);
    }
  };

  const validateEmail = email => {
    if (!email) {
      return 'This field is required';
    }
    const emailError = validateEmailField(email);
    if (emailError) {
      return 'Email is not valid';
    }

    return null;
  };

  const handleChange = event => {
    const {
      target: { value, name }
    } = event;

    setState(values => ({
      ...values,
      [name]: value
    }));
  };

  const sendAgain = () => {
    if (timesClicked < 1) {
      resetIsPasswordResetVariable();
      resetPassword(email);
    }

    setState(values => ({
      ...values,
      timesClicked: values.timesClicked + 1
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    setState(values => ({
      ...values,
      step: 1
    }));
  };

  const resetSteps = () => {
    resetIsPasswordResetVariable();

    setState(values => ({
      ...values,
      isLoading: false,
      email: ''
    }));
  };

  return (
    <StyledUI.FormWrapper>
      <form onSubmit={handleSubmit}>
        {step === RESTORE_PASSWORD_FORM_STEP_1 && (
          <>
            <StyledUI.H2 color={colors.black} size="largest">
              Restore password
            </StyledUI.H2>
            <StyledUI.Label margin="0 0 10px 0">
              Email
              <StyledUI.Input
                value={email}
                onChange={handleChange}
                type="text"
                name="email"
                placeholder="Enter your email to send recovery letter"
                error={emailError}
                data-test="restore-email"
              />
              {emailError && (
                <StyledUI.Error isAbsolute data-test="restore-email-error">
                  {emailError}
                </StyledUI.Error>
              )}
            </StyledUI.Label>

            <StyledUI.FormButton
              background={isLoading ? colors.theLightestViolet : colors.violet}
              onClick={nextStep}
              disabled={isBtnDisabled}
              data-test="restore-button"
            >
              {isLoading ? <Loading /> : <span>Restore password</span>}
            </StyledUI.FormButton>
            <StyledUI.Info data-test="restore-to-sign-in">
              <Link to="/signin" style={linkStyles}>
                I have remembered my password
              </Link>
            </StyledUI.Info>
          </>
        )}

        {step === RESTORE_PASSWORD_FORM_STEP_2 && (
          <>
            <StyledUI.H2 size="largest" color={colors.black}>
              Email sent!
            </StyledUI.H2>
            <StyledUI.Text color={colors.black} margin="0 0 20px 0">
              We have sent you an email with further instructions to restore your password. Please check your inbox
            </StyledUI.Text>
            <StyledUI.FormButton data-test="restore-to-sign-in" onClick={resetSteps}>
              <StyledUI.StyledLink to="/signin">Back to sign in</StyledUI.StyledLink>
            </StyledUI.FormButton>
            <StyledUI.Info
              color={timesClicked > 0 ? colors.theLightestViolet : colors.violet}
              onClick={timesClicked > 0 ? () => {} : sendAgain}
              data-test="restore-send-again"
            >
              Send again
            </StyledUI.Info>
          </>
        )}
        {isPasswordResetSuccessPopUpShown && (
          <PopUp>
            <SuccessPopUp text="Password has been sent." />
          </PopUp>
        )}
        {isPasswordResetErrorPopUpShown && (
          <PopUp>
            <ErrorPopUp text="Try again in a minute." />
          </PopUp>
        )}
      </form>
    </StyledUI.FormWrapper>
  );
}
