// Absolute imports
import styled from 'styled-components/macro';

// Theme
import * as colors from '@theme/colors';

export const Flex = styled.div`
  display: flex;

  ${({ align }) => align && `align-items: ${align}`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ direction }) => direction && `flex-direction: ${direction}`};
  ${({ wrap }) => wrap && `flex-wrap: ${wrap}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: 1px solid ${colors.lightGray}`};
  ${({ borderTop }) => borderTop && `border-top: 1px solid ${colors.lightGray}`};
`;
