/* eslint-disable no-magic-numbers */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as paymentsActions from '@actions/payments';
import {
  payoutInfoDataSelector,
  payoutInfoErrorSelector,
  payoutInfoLoadingSelector,
  payoutInfoWalletErrorSelector
} from '@reducers/payments';

import Widget from '@components/Widget';
import Loading from '../Loading/Loading';
import { shareIconStyles } from './styles';
import { useActions } from '@helpers/hooks';
import ShareIcon from '@components/img/share.svg';

const PayoutWidgets = () => {
  const payoutInfoLoading = useSelector(payoutInfoLoadingSelector);
  const payoutInfoData = useSelector(payoutInfoDataSelector);
  const payoutInfoError = useSelector(payoutInfoErrorSelector);
  const payoutInfoWalletError = useSelector(payoutInfoWalletErrorSelector);
  const { fetchPayoutInfo } = useActions(paymentsActions);

  useEffect(() => {
    fetchPayoutInfo();
  }, [fetchPayoutInfo]);

  return payoutInfoLoading ? (
    <Loading />
  ) : (
    <>
      <Widget title="Paid out" error={payoutInfoWalletError}>{Math.abs(Number(payoutInfoData?.paidOut))} BTC</Widget>
      <Widget title="Need to be paid" error={payoutInfoError}>{Number(payoutInfoData?.needToBePaid)} BTC</Widget>
      <Widget title="Possibility to pay for all orders" error={payoutInfoError || payoutInfoWalletError}>
        {payoutInfoData?.isEnoughToPayAll ? (
          <span style={{ color: '#42ba12' }}>Enough funds</span>
        ) : (
          <span>
            Missing:
            <span style={{ color: '#ba2713' }}>{`${Number(payoutInfoData?.walletBalance - payoutInfoData?.needToBePaid)} BTC`}</span>
          </span>
        )}
      </Widget>
      <Widget title="Wallet balance" error={payoutInfoWalletError}>
        {Number(payoutInfoData?.walletBalance)} BTC
        <a href={`https://blockchain.com/btc/address/${payoutInfoData?.wallet}`} target="_blank" rel="noreferrer">
          <img src={ShareIcon} style={shareIconStyles} alt='wallet link' />
        </a>
      </Widget>
    </>
  );
};

export default PayoutWidgets;
