// @ts-check

/**
 * Compose functions together from left to right
 * @param  {...function} fns Functions to compose
 */
export const pipe = (...fns) =>
  fns.reduce(
    (f, g) => x => g(f(x)),
    x => x
  );

/**
 * Curried map function
 * @param {(arg) => any} fn Mapper function
 * @returns {(mappableObject) => any}
 */
export const map = fn => mappableObject => mappableObject.map(fn);
