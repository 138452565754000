// Absolute imports
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { Root, Text } from './styles';
import * as colors from '@theme/colors';

ErrorPopUp.propTypes = {
  text: PropTypes.string.isRequired
};

export default function ErrorPopUp({ text }) {
  return (
    <Root data-test="error-pop-up">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <circle cx="10" cy="9" r="8" fill={colors.white} />
        </g>
        <path
          d="M10.5312 9.00038L12.8909 6.64061C13.0375 6.49412 13.0375 6.25644 12.8909 6.10996C12.7443 5.96335 12.5069 5.96335 12.3603 6.10996L10.0005 8.46973L7.64061 6.10996C7.494 5.96335 7.25657 5.96335 7.10996 6.10996C6.96335 6.25644 6.96335 6.49412 7.10996 6.64061L9.46986 9.00038L7.10996 11.3602C6.96335 11.5066 6.96335 11.7443 7.10996 11.8908C7.18326 11.964 7.27934 12.0006 7.37528 12.0006C7.47123 12.0006 7.5673 11.964 7.64061 11.8907L10.0005 9.53091L12.3603 11.8907C12.4336 11.964 12.5297 12.0006 12.6256 12.0006C12.7216 12.0006 12.8176 11.964 12.8909 11.8907C13.0375 11.7442 13.0375 11.5065 12.8909 11.36L10.5312 9.00038Z"
          fill={colors.darkRed}
        />
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="20"
            height="20"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.545833 0 0 0 0 0.545833 0 0 0 0 0.545833 0 0 0 0.3 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>{' '}
      <Text>{text}</Text>
    </Root>
  );
}
