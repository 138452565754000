// Absolute
import React, { useEffect, useState } from 'react';

// Components
import Profile from '../Profile/Profile';
import Modal from '../Modal/Modal';
import Image from '../Image/Image';
import Logo from '../Logo';

// Styles
import * as StyledUI from '../UI';
import * as Styled from './styles';

// Constants
import { FIRST_STEP, SECOND_STEP } from '@constants';

// Theme
import * as colors from '@theme/colors';

export default function UsersProfile({
  fetchUser,
  routerProps,
  currentUser,
  user,
  deleteUser,
  isUserDeleted,
  deleteUserError,
  resetCurrentUser,
  signOut
}) {
  const [isModalShown, setIsModalShown] = useState(false);
  const [step, setStep] = useState(FIRST_STEP);
  const [shouldSignOut, setShouldSignOut] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    fetchUser({ id: routerProps.match.params.id });
  }, []);

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.firstName || '');
      setLastName(currentUser.lastName || '');
    }
  }, [currentUser]);

  const closeModal = () => {
    setIsModalShown(false);
    setStep(FIRST_STEP);
  };

  const deleteUserHandler = () => {
    if (step === FIRST_STEP) {
      deleteUser({ email: currentUser.email });
      if (user.email === currentUser.email) {
        setShouldSignOut(true);
      }

      setStep(SECOND_STEP);
    }
  };

  const closeAndReset = event => {
    resetCurrentUser();
    closeModal(event);

    if (shouldSignOut) {
      signOut();
    }
  };

  const showModal = () => {
    setIsModalShown(true);
  };

  const preventReloading = event => {
    event.preventDefault();
  };

  const deleteUserButton = (
    <StyledUI.Button onClick={showModal} margin="0" color={colors.darkRed} border={colors.darkRed}>
      Delete user
    </StyledUI.Button>
  );

  return (
    <>
      <Profile
        submit={preventReloading}
        user={currentUser}
        button={deleteUserButton}
        upload={{
          image:
            currentUser && currentUser.photo ? (
              <Image photo={currentUser.photo} width="40px" height="40px" />
            ) : (
              <StyledUI.AvatarContainer>
                <StyledUI.Flex width="44px" height="44px">
                  <Logo />
                </StyledUI.Flex>
              </StyledUI.AvatarContainer>
            )
        }}
      >
        <>
          <StyledUI.Flex wrap="wrap" justify="space-between">
            <Styled.Label width="46%">
              First name
              <StyledUI.Input data-test="users-profile-firstname" value={firstName} readOnly disable height="40px" />
            </Styled.Label>

            <Styled.Label width="46%">
              Last name
              <StyledUI.Input data-test="users-profile-lastname" value={lastName} readOnly disable height="40px" />
            </Styled.Label>
          </StyledUI.Flex>

          <StyledUI.Flex>
            <Styled.Label>
              Email
              <StyledUI.Input disable height="40px" readOnly value={currentUser ? currentUser.email : ''} />
            </Styled.Label>
          </StyledUI.Flex>
        </>
      </Profile>

      {isModalShown && step === FIRST_STEP ? (
        <Modal
          close={closeModal}
          title={'Delete user'}
          buttons={
            <>
              <StyledUI.Button
                data-test="users-profile-modal-cancel-btn"
                order={2}
                onClick={closeModal}
                color={colors.darkRed}
                border={colors.darkRed}
              >
                Cancel
              </StyledUI.Button>
              <StyledUI.Button
                data-test="users-profile-modal-delete-btn"
                order={1}
                onClick={deleteUserHandler}
                background={colors.darkRed}
                color={colors.white}
                border={colors.darkRed}
              >
                Delete
              </StyledUI.Button>
            </>
          }
        >
          <span>All the user info will be deleted permanently. You will not be able to restore it.</span>
        </Modal>
      ) : isModalShown && step === SECOND_STEP && isUserDeleted ? (
        <Modal
          close={closeAndReset}
          title={'User deleted'}
          buttons={
            <StyledUI.Button
              data-test="users-profile-modal-got-it-btn"
              onClick={closeAndReset}
              background={colors.violet}
              color={colors.white}
              border={colors.violet}
            >
              Got it
            </StyledUI.Button>
          }
        >
          <span>The user has been successfully deleted from the system.</span>
        </Modal>
      ) : isModalShown && step === SECOND_STEP && deleteUserError && !isUserDeleted ? (
        <Modal
          close={closeModal}
          title={"User wasn't deleted"}
          buttons={
            <StyledUI.Button
              data-test="users-profile-modal-ok-btn"
              onClick={closeModal}
              background={colors.darkRed}
              color={colors.white}
              border={colors.darkRed}
            >
              OK
            </StyledUI.Button>
          }
        >
          <span data-test="users-profile-modal-server-error">{deleteUserError}</span>
        </Modal>
      ) : null}
    </>
  );
}
