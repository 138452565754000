import React from 'react';
import LimitSettings from './LimitSettings';
import { useShallowSelector } from '@helpers/hooks';
import * as settingsSelectors from '@reducers/settings';

export default function ConnectedFeeSettings() {
  const updateLimitSettingsPending = useShallowSelector(settingsSelectors.updateLimitSettingsPendingSelector);
  const updateLimitSettingsError = useShallowSelector(settingsSelectors.updateLimitSettingsErrorSelector);
  const props = {
    backendError: updateLimitSettingsError,
    isSaving: updateLimitSettingsPending
  };
  return <LimitSettings {...props} />;
}
