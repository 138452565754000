import React, { useMemo } from 'react';
// Constants
import * as constants from '@constants';
import { REPORT_STATUSES } from '@constants';
// Components
import Table from '../Table/Table';
// Styles
import * as Styled from './styles';
import * as StyledUI from '../UI';
import * as colors from '@theme/colors';

export default function Finance({
  wallets,
  reportData,
  isGeneratingReport,
  reportGenerationBackendError,
  onGenerateReport
}) {
  const headers = useMemo(() => ['Wallet', 'Amount', 'Address', 'Link'], []);
  const displayItems = useMemo(
    () =>
      wallets.map(wallet => ({
        wallet: wallet.name,
        amount: `${wallet.balance} ${wallet.currency}`,
        address: wallet.address,
        link: () => {
          switch (wallet.currency) {
            case constants.CURRENCIES.EEX:
              return <a target="_blank" rel="noopener noreferrer" href={`https://explorer.ethereum-express.com/wallet/${wallet.address}`}>More details</a>;
            case constants.CURRENCIES.BTC:
              return <a target="_blank" rel="noopener noreferrer" href={`https://www.blockchain.com/btc/address/${wallet.address}`}>More details</a>;
            case constants.CURRENCIES.ETH:
              return <a target="_blank" rel="noopener noreferrer" href={`https://www.blockchain.com/eth/address/${wallet.address}`}>More details</a>;
            default:
              return null;
          }
        }
      })),
    [wallets]
  );
  const handleDownloadClick = () => {
    downloadFile('report.csv', reportData);
  };
  const getReportStatus = () => {
    switch (true) {
      case reportData != null:
        return REPORT_STATUSES.SUCCESS;
      case reportGenerationBackendError != null:
        return REPORT_STATUSES.FAILURE;
      case isGeneratingReport:
        return REPORT_STATUSES.IN_PROGRESS;
      default:
        return REPORT_STATUSES.IDLE;
    }
  };
  const reportStatus = getReportStatus();
  const reportStatusText = {
    [REPORT_STATUSES.IN_PROGRESS]: 'In this moment your data file being generated. It can take some time.',
    [REPORT_STATUSES.SUCCESS]: 'Your file is ready!',
    [REPORT_STATUSES.FAILURE]: `Failed generating report: ${reportGenerationBackendError?.message}`
  }[reportStatus];
  const generateButtonText = {
    [REPORT_STATUSES.IDLE]: 'Generate',
    [REPORT_STATUSES.IN_PROGRESS]: 'Generating',
    [REPORT_STATUSES.FAILURE]: 'Try again'
  }[reportStatus];
  return (
    <>
      <StyledUI.H2 color={colors.black} margin="5px 0">
        Finance
      </StyledUI.H2>
      <Table headers={headers} items={displayItems} />
      {reportStatus !== REPORT_STATUSES.IDLE && (
        <Styled.ReportStatusText status={reportStatus}>{reportStatusText}</Styled.ReportStatusText>
      )}
      {reportStatus === REPORT_STATUSES.SUCCESS && (
        <StyledUI.SettingsButton
          disabled={isGeneratingReport}
          margin="15px 0 0 0"
          onClick={handleDownloadClick}
          data-test="finance-download-report-btn"
        >
          Download
        </StyledUI.SettingsButton>
      )}
      {reportStatus !== REPORT_STATUSES.SUCCESS && (
        <StyledUI.SettingsButton
          margin="15px 0 0 0"
          disabled={isGeneratingReport}
          onClick={onGenerateReport}
          data-test="finance-download-report-btn"
        >
          {generateButtonText}
        </StyledUI.SettingsButton>
      )}
    </>
  );
}

function downloadFile(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
