// Absolute imports
import styled from 'styled-components/macro';

// Theme
import * as colors from '@theme/colors';

export const Input = styled.input`
  width: 100%;
  margin: 0;
  display: block;
  padding: 14px 17px 14px;
  min-width: 0;
  background-color: ${colors.white};
  border: 0;
  border-radius: 4px;

  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${colors.black};
  -webkit-tap-highlight-color: transparent;

  ${({ error }) =>
    error &&
    `color: ${colors.red};
     box-shadow: 0px 0px 0px 3px ${colors.red} inset;
    `}

  &::placeholder {
    color: ${colors.middleGray};
  }

  &:focus {
    outline: 0;
  }
`;

export const FileInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;
