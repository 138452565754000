import styled from 'styled-components';
import * as constants from '@constants';
import * as colors from '@theme/colors';

export const ReportStatusText = styled.div`
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ status }) =>
    ({
      [constants.REPORT_STATUSES.IN_PROGRESS]: colors.gray,
      [constants.REPORT_STATUSES.SUCCESS]: colors.success,
      [constants.REPORT_STATUSES.FAILURE]: colors.red
    }[status])};
`;
