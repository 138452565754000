// Absolute
import React, { useState } from 'react';

// Components
import Logo from '../Logo';
import Navigation from '../Navigation/ConnectedNavigation';
import MobileNavigation from '../MobileNavigation/ConnectedMobileNavigation';
import Hamburger from '../Hamburger/Hamburger';

// Styles
import { Container, MobileMenu, Menu } from './styles';

import { LogoContainer, LogoImg, LogoDescription } from '../UI';

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = active => {
    window.scrollTo(0, 0);
    setIsMenuOpen(state => !state);
  };
  return (
    <Container>
      <LogoContainer>
        <LogoImg size="sm">
          <Logo />
        </LogoImg>
        <LogoDescription size="sm">
          Ethereum <br /> Express <br /> Coin
        </LogoDescription>
      </LogoContainer>
      <Navigation />

      <MobileNavigation active={isMenuOpen} handleToggleMenu={handleToggleMenu} />

      <Menu>
        <MobileMenu>
          <Hamburger active={isMenuOpen} onClick={handleToggleMenu} />
        </MobileMenu>
      </Menu>
    </Container>
  );
}
