// @ts-check

/**
 * Transform object by filtering its entries
 * @param {(key, value) => boolean} fn Predicate checking if given entry should be present in result
 * @returns {(object) => any}
 */
export const filterEntries = fn => object =>
  Object.entries(object).reduce((result, [key, value]) => (fn(key, value) ? { ...result, [key]: value } : result), {});

/**
 * Returns true if object doesn't have any keys, false otherwise
 * @param {*} object
 * @returns {boolean}
 */
export const isEmpty = object => {
  // Better than Object.keys, because avoids iterating over all keys and building the result array
  // For functions like isEmpty this is important, because you expect it to be O(1), not O(n)
  if (typeof object !== 'object' || object instanceof Array) {
    throw new Error('isEmpty: wrong argument type');
  }
  for (const _key in object) {
    return false;
  }
  return true;
};

/**
 * Transform object by mapping its values
 * @param {(value, key) => any} fn Function mapping existing value and its key to new value
 * @returns {(object) => any}
 */
export const mapValues = fn => object =>
  Object.entries(object).reduce((result, [key, value]) => ({ ...result, [key]: fn(value, key) }), {});

/**
 * Check if some entries in object satisfy given predicate
 * @param {(key, value) => boolean} fn Predicate checking if entry satisfies some condition
 * @returns {(object) => boolean}
 */
export const some = fn => object =>
  Object.entries(object).reduce((result, [key, value]) => result || fn(key, value), false);

/**
 * Check if all entries in object satisfy given predicate
 * @param {(key, value) => boolean} fn Predicate checking if entry satisfies some condition
 * @returns {(object) => boolean}
 */
export const every = fn => object =>
  Object.entries(object).reduce((result, [key, value]) => result && fn(key, value), true);

const uncurriedMergeDeepRight = (obj1, obj2) => {
  if (
    typeof obj1 !== 'object' ||
    obj1 instanceof Array ||
    typeof obj2 !== 'object' ||
    obj2 instanceof Array ||
    obj1 == null ||
    obj2 == null
  ) {
    return obj2;
  }
  const result = Object.assign({}, obj2);
  for (const key in obj1) {
    if (key in obj2) {
      result[key] = uncurriedMergeDeepRight(obj1[key], obj2[key]);
    } else {
      result[key] = obj1[key];
    }
  }
  return result;
};

/**
 * @callback MergeDeepRight_Overload1
 * @param {*} arg1 First object to merge
 * @param {*} arg2 Second object to merge
 * @returns {*}
 */
/**
 * @callback MergeDeepRight_Overload2
 * @param {*} arg1 First object to merge
 * @returns {(arg2) => any}
 */
/** @type {MergeDeepRight_Overload1 & MergeDeepRight_Overload2} */
export const mergeDeepRight = (...args) => {
  // eslint-disable-next-line no-magic-numbers
  if (args.length === 2) {
    return uncurriedMergeDeepRight(args[0], args[1]);
  } else if (args.length === 1) {
    return arg => uncurriedMergeDeepRight(args[0], arg);
  } else {
    throw new Error('mergeDeepRight: wrong number of arguments');
  }
};

/**
 * Build an object from a list of key-value pairs.
 * @param {*[][]} entries Entries of resulting object
 * @returns {*}
 */
export const fromEntries = entries => entries.reduce((result, [key, value]) => ({ ...result, [key]: value }), {});
