// Absolute
import React from 'react';

// Custom hooks
import { useShallowSelector, useActions } from '@helpers/hooks';

import Users from './Users';
import * as userActions from '@actions/users';
import * as authActions from '@actions/auth';

// Selectors
import * as usersSelectors from '@reducers/users';
import * as authSelectors from '@reducers/auth';

export default function ConnectedUsers() {
  const user = useShallowSelector(authSelectors.userSelector);
  const currentUsers = useShallowSelector(usersSelectors.currentUsersSelector);
  const usersCount = useShallowSelector(usersSelectors.usersCountSelector);
  const fetchUsersError = useShallowSelector(usersSelectors.fetchUsersErrorSelector);
  const isUserDeleted = useShallowSelector(usersSelectors.isUserDeletedSelector);
  const attemptForDeleteUser = useShallowSelector(usersSelectors.attemptForDeleteUserSelector);
  const deleteUserError = useShallowSelector(usersSelectors.deleteUserErrorSelector);
  const isUserInvited = useShallowSelector(usersSelectors.isUserInvitedSelector);
  const inviteUserError = useShallowSelector(usersSelectors.inviteUserErrorSelector);

  const {
    fetchUsers,
    deleteUser,
    resetIsUserDeletedVariable,
    resetDeleteUserErrorVariable,
    inviteUser,
    resetIsUserInvitedVariable,
    resetInviteUserErrorVariable
  } = useActions(userActions);
  const { signOut } = useActions(authActions);

  const props = {
    user,
    currentUsers,
    fetchUsers,
    usersCount,
    fetchUsersError,
    deleteUser,
    isUserDeleted,
    attemptForDeleteUser,
    deleteUserError,
    resetIsUserDeletedVariable,
    resetDeleteUserErrorVariable,
    signOut,
    inviteUser,
    isUserInvited,
    inviteUserError,
    resetIsUserInvitedVariable,
    resetInviteUserErrorVariable
  };
  return <Users {...props} />;
}
