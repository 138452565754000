import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

const sizes = {
  xs: '24px',
  sm: '30px',
  md: '44px',
  lg: '68px'
};

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.white};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${colors.white};
    outline: 0;
  }
`;

export const NavLink = styled(({ isActive, ...restProps }) => <StyledLink {...restProps} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 36px;
  padding-left: 24px;
  padding-right: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  cursor: pointer;
  text-transform: uppercase;
  width: 25%;

  ${props =>
    props.isActive &&
    css`
      background-color: rgba(255, 255, 255, 0.16);
    `}

    @media (max-width: ${breakpoints.lg}px) {
      width: 23%;
    }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${colors.black};
  background-size: cover;
  border-radius: 50%;

  width: ${sizes['md']};
  height: ${sizes['md']};

  ${({ size }) => size && `width: ${sizes[size]}; height: ${sizes[size]};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;
