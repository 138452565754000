// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
// Utils
import useActions from '@helpers/hooks/useActions';
// Actions
import * as financeActions from '@actions/finance';
// Selectors
import * as financeSelectors from '@reducers/finance';
// Components
import Finance from './Finance';
import { useEffect } from 'react';

export default function ConnectedFinance() {
  const wallets = useSelector(financeSelectors.walletsSelector);
  const fetchBalancesPending = useSelector(financeSelectors.fetchBalancesPendingSelector);
  const fetchBalancesError = useSelector(financeSelectors.fetchBalancesErrorSelector);
  const reportData = useSelector(financeSelectors.reportDataSelector);
  const fetchReportPending = useSelector(financeSelectors.fetchReportPendingSelector);
  const fetchReportError = useSelector(financeSelectors.fetchReportErrorSelector);
  const { fetchBalances, fetchReport, clearReportData } = useActions(financeActions);
  useEffect(() => {
    fetchBalances();
    return () => {
      clearReportData();
    };
  }, []);
  return (
    <Finance
      wallets={wallets}
      isLoadingWallets={fetchBalancesPending}
      walletsBackendError={fetchBalancesError}
      reportData={reportData}
      isGeneratingReport={fetchReportPending}
      reportGenerationBackendError={fetchReportError}
      onGenerateReport={fetchReport}
    />
  );
}
