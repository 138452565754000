import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import reduxCookiesMiddleware from 'redux-cookies-middleware';
import { getStateFromCookies } from 'redux-cookies-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createRootReducer } from '@reducers';
import rootSaga from '@sagas';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const cookiePaths = {
  'auth.accessToken': { name: 'accessToken' },
  'auth.isPasswordChanged': { name: 'isPasswordChanged' },
  'auth.resetPasswordToken': { name: 'resetPasswordToken' },
  'auth.user': { name: 'user' },
  'users.currentUser': { name: 'currentUser' }
};

export default function configureStore(preloadedState) {
  const rootReducer = createRootReducer(history);
  const initialState = preloadedState || rootReducer(undefined, {});
  const store = createStore(
    rootReducer,
    getStateFromCookies(initialState, cookiePaths),
    composeWithDevTools(applyMiddleware(reduxCookiesMiddleware(cookiePaths), routerMiddleware(history), sagaMiddleware))
  );
  sagaMiddleware.run(rootSaga);
  return store;
}
