// Absolute
import React from 'react';

// Styles
import * as Styled from './styles';
import * as StyledUI from '../UI';

// Helpers
import { getDate } from '@helpers';

// Theme
import * as colors from '@theme/colors';

export default function Profile({
  user,
  button,
  isMyProfile,
  content,
  upload,
  submit,
  successMessage,
  errors,
  children
}) {
  return (
    <Styled.Root>
      <Styled.Form onSubmit={submit}>
        <StyledUI.H2 color={colors.black}>{'Profile & Settings'}</StyledUI.H2>

        <StyledUI.Flex margin="0 0 10px 0" wrap="nowrap" align="center">
          <StyledUI.AvatarContainer size="lg">{upload && upload.image}</StyledUI.AvatarContainer>
          {upload && upload.button}
        </StyledUI.Flex>

        <StyledUI.Info data-test="profile-info" align="left" margin="0 0 20px 0" color={colors.gray}>
          Since: {user ? getDate(user.createdAt) : <span>Loading...</span>}
        </StyledUI.Info>

        <StyledUI.Flex direction="column">
          {children}
          <StyledUI.Flex wrap="wrap" justify={isMyProfile ? 'flex-start' : 'flex-end'} align="center">
            {button}
            {<StyledUI.SuccessMessage margin="20px 0 0 10px">{successMessage}</StyledUI.SuccessMessage>}
          </StyledUI.Flex>
          <StyledUI.Flex direction="column">
            {errors &&
              errors.map((error, index) => (
                <StyledUI.Error data-test="profile-error" key={index}>
                  {error}
                </StyledUI.Error>
              ))}
          </StyledUI.Flex>
        </StyledUI.Flex>
      </Styled.Form>
    </Styled.Root>
  );
}
