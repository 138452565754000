import React, { useState } from 'react';
import { Select as SemanticUISelect } from 'semantic-ui-react';
import styled from 'styled-components';

import ArrowUp from 'src/icons/ArrowUp';
import ArrowDown from 'src/icons/ArrowDown';
import useHovered from '@helpers/hooks/useHovered';
import Cancel from 'src/icons/Cancel';

const Select = ({ value, onChange, options, placeholder = "Choose" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ref, isHovered] = useHovered();

  const arrowIcon = isOpen ? <ArrowDown /> : <ArrowUp />;

  const changeHandler = (_, data) => {
    onChange(data.value);
  }

  return <Select.Wrapper ref={ref}>
    <Select.Input
      value={value}
      placeholder={placeholder}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onChange={changeHandler}
      options={options}
      clearable
      icon={
        <Select.IconWrapper>
          {isHovered && value ? <Cancel /> : arrowIcon}
        </Select.IconWrapper>
      }
      selectOnBlur={false} 
    />
  </Select.Wrapper>
}

Select.Wrapper = styled.div`
  position: relative;
  margin-top: 8px;
  height: 38px;
  color: #000000 !important;

  .ui.dropdown>.text {
    font-weight: normal;
  }

  .ui.selection.dropdown {
    min-width: 123px;
    width: 100%;
  }

  .ui.selection.active.dropdown, .ui.selection.active.dropdown .menu {
    border-color: #441EDA !important;
  }

  .ui.selection.dropdown:focus {
    border-color: #441EDA !important;
  }
`;

Select.IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  right: 12px;
`;

Select.Input = styled(SemanticUISelect)`
  border: 1px solid #E4E3E8;
  border-radius: 4px;

  &:hover {
    border: 1px solid #441EDA !important;
  }
`;

export default Select;