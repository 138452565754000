import React, { useState } from 'react';
import styled from 'styled-components';
import ClipboardCopyIcon from '../../icons/ClipboardCopyIcon';

export default function({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <ClipboardCopyWrapper className="copy" onClick={handleCopyClick}>
      <ClipboardCopyIcon fill={isCopied ? '#a58dff' : '#441EDA'}/>
    </ClipboardCopyWrapper>
  );
};

const ClipboardCopyWrapper = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  padding: 0;
  opacity: 0;
  transition: .3s;
  
  &:focus {
    outline: 0;
  }
`;