// Libraries
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
// Helpers
import * as objectHelpers from '@helpers/objectHelpers';
// Actions
import * as actions from '@actions/settings';

const defaultState = {
  feeInEex: '',
  feeInPercents: '',
  salePrice: '',
  purchasePrice: '',
  fetchFeeSettingsPending: false,
  fetchFeeSettingsError: null,
  updateFeeSettingsPending: false,
  updateFeeSettingsError: null,
  updateLimitSettingsPending: false,
  updateLimitSettingsError: null,
  userTransactions: {
    isFetching: false,
    error: null,
    page: 0,
    total: 0,
    items: []
  }
};

export default handleActions(
  {
    [actions.fetchFeeSettings]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        fetchFeeSettingsPending: true,
        fetchFeeSettingsError: null
      }),
    [actions.fetchFeeSettingsSuccess]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        feeInEex: payload.feeInEex || state.feeInEex,
        feeInPercents: payload.feeInPercents || state.feeInPercents,
        salePrice: payload.salePrice || state.salePrice,
        purchasePrice: payload.purchasePrice || state.purchasePrice,
        fetchFeeSettingsPending: false,
        fetchFeeSettingsError: null
      }),
    [actions.fetchFeeSettingsFailure]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        fetchFeeSettingsPending: false,
        fetchFeeSettingsError: payload
      }),
    [actions.updateFeeSettings]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        updateFeeSettingsPending: true,
        updateFeeSettingsError: null
      }),
    [actions.updateFeeSettingsSuccess]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        feeInEex: payload.feeInEex || state.feeInEex,
        feeInPercents: payload.feeInPercents || state.feeInPercents,
        salePrice: payload.salePrice || state.salePrice,
        purchasePrice: payload.purchasePrice || state.purchasePrice,
        updateFeeSettingsPending: false,
        updateFeeSettingsError: null
      }),
    [actions.fetchGeneralLimitsSuccess]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        generalLimits: payload.data
      }),
    [actions.fetchIndividualLimitsSuccess]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, { individualLimits: payload.data }),
    [actions.updateFeeSettingsFailure]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        updateFeeSettingsPending: false,
        updateFeeSettingsError: payload
      }),
    [actions.updateLimitSettings]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        updateLimitSettingsPending: true,
        updateLimitSettingsError: null
      }),
    [actions.updateLimitSettingsSuccess]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        updateLimitSettingsPending: false,
        updateLimitSettingsError: null
      }),
    [actions.updateLimitSettingsFailure]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        updateLimitSettingsPending: false,
        updateLimitSettingsError: payload
      }),
    [actions.fetchUserTransactions]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        userTransactions: {
          isFetching: true,
          page: payload.page
        }
      }),
    // [actions.fetchUserTransactionsSuccess]: (state, { payload }) =>
    //   objectHelpers.mergeDeepRight(state, {
    //     userTransactions: {
    //       isFetching: false,
    //       total: payload.total,
    //       items: payload.data
    //     }
    //   }),
    [actions.fetchUserTransactionsFailure]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        userTransactions: {
          isFetching: false
        }
      })
  },
  defaultState
);

export const settingsSelector = state => state.settings;

export const feeInEexSelector = createSelector(settingsSelector, settings => settings.feeInEex);
export const feeInPercentsSelector = createSelector(settingsSelector, settings => settings.feeInPercents);
export const salePriceSelector = createSelector(settingsSelector, settings => settings.salePrice);
export const purchasePriceSelector = createSelector(settingsSelector, settings => settings.purchasePrice);

export const fetchFeeSettingsPendingSelector = createSelector(
  settingsSelector,
  settings => settings.fetchFeeSettingsPending
);

export const fetchFeeSettingsErrorSelector = createSelector(
  settingsSelector,
  settings => settings.fetchFeeSettingsError
);

export const updateFeeSettingsPendingSelector = createSelector(
  settingsSelector,
  settings => settings.updateFeeSettingsPending
);

export const updateFeeSettingsErrorSelector = createSelector(
  settingsSelector,
  settings => settings.updateFeeSettingsError
);

export const updateLimitSettingsPendingSelector = createSelector(
  settingsSelector,
  settings => settings.updateLimitSettingsPending
);

export const updateLimitSettingsErrorSelector = createSelector(
  settingsSelector,
  settings => settings.updateLimitSettingsError
);

export const generalLimitsSelector = createSelector(settingsSelector, ({ generalLimits }) => generalLimits);
export const individualLimitsSelector = createSelector(settingsSelector, ({ individualLimits }) => individualLimits);

export const userTransactionsSelector = createSelector(settingsSelector, settings => settings.userTransactions);

export const userTransactionsIsFetchingSelector = createSelector(
  userTransactionsSelector,
  userTransactions => userTransactions.isFetching
);

export const userTransactionsErrorSelector = createSelector(
  userTransactionsSelector,
  userTransactions => userTransactions.error
);

export const userTransactionsPageSelector = createSelector(
  userTransactionsSelector,
  userTransactions => userTransactions.page
);

export const userTransactionsItemsSelector = createSelector(
  userTransactionsSelector,
  userTransactions => userTransactions.items
);

export const userTransactionsTotalSelector = createSelector(
  userTransactionsSelector,
  userTransactions => userTransactions.total
);
