import styled from 'styled-components';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

export const Wrapper = styled.section`
  position: fixed;
  z-index: 16;
  overflow: auto;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ align }) => align && `align-items: ${align}`}

  width: 100%;
  height: 100%;
  background: rgba(18, 7, 44, 0.9);

  @media (orientation: landscape) and (max-width: ${breakpoints.smToMd}px) {
    align-items: flex-start;
  }
  @media (orientation: portrait) and (max-width: ${breakpoints.smToMd}px) {
    align-items: center;
  }
`;

export const ModalWindow = styled.div`
  width: ${({ width }) => width ?? '34%'};
  background: ${colors.white};
  border-radius: 4px;
  color: ${colors.black};
  font-family: 'Fira Sans', sans-serif;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${breakpoints.lg}px) {
    width: ${({ width }) => width ?? '44%'};
  }

  @media (max-width: ${breakpoints.md}px) {
    width: ${({ width }) => width ?? '74%'};
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: ${({ width }) => width ?? '90%'};
  }

  @media (orientation: landscape) and (max-width: ${breakpoints.smToMd}px) {
    width: 100vw;
    min-height: 100vh;
  }
  @media (orientation: portrait) and (max-width: ${breakpoints.smToMd}px) {
    min-height: 100px;
  }
`;
