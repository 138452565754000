// Libraries
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
// Selectors
import * as settingsSelectors from '@reducers/settings';
import * as authSelectors from '@reducers/auth';
// Actions
import * as settingsActions from '@actions/settings';
// Services
import * as ownerAdminApi from '@services/ownerAdminApi';
// import * as exchangeApi from '@services/exchangeApi';
import * as cabinetApi from '@services/cabinetApi';
// Helpers
import * as functionHelpers from '@helpers/functionHelpers';
import * as objectHelpers from '@helpers/objectHelpers';
import * as api from '@services/ownerAdminApi';
// Constants
import * as constants from '@constants';

function* fetchFeeSettings() {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { commission, salePrice, purchasePrice } = yield all({
    commission: call(ownerAdminApi.getCommission, accessToken),
    salePrice: call(ownerAdminApi.getCoinSalePrice, accessToken),
    purchasePrice: call(ownerAdminApi.getCoinPurchasePrice, accessToken)
  });
  const error = commission.error || salePrice.error || purchasePrice.error;
  if (error) {
    yield put(settingsActions.fetchFeeSettingsFailure(error));
  } else {
    yield put(
      settingsActions.fetchFeeSettingsSuccess({
        feeInEex: commission.result.fixed,
        feeInPercents: commission.result.percentage,
        salePrice: salePrice.result,
        purchasePrice: purchasePrice.result
      })
    );
  }
}

export function* watchFetchSettings() {
  yield takeLatest(settingsActions.FETCH_FEE_SETTINGS, fetchFeeSettings);
}

export function* updateFeeSettings({ payload }) {
  const { feeInEex, feeInPercents, salePrice, purchasePrice } = payload;
  const settings = yield select(settingsSelectors.settingsSelector);
  const shouldUpdateFeeInEex = feeInEex && feeInEex !== settings.feeInEex;
  const shouldUpdateFeeInPercents = feeInPercents && feeInPercents !== settings.feeInPercents;
  const shouldUpdateSalePrice = salePrice && salePrice !== settings.salePrice;
  const shouldUpdatePurchasePrice = purchasePrice && purchasePrice !== settings.purchasePrice;
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const calls = yield all({
    fee:
      !shouldUpdateFeeInEex && !shouldUpdateFeeInPercents
        ? {}
        : call(ownerAdminApi.setCommission, accessToken, {
            ...(shouldUpdateFeeInEex ? { fixed: feeInEex } : null),
            ...(shouldUpdateFeeInPercents ? { percentage: feeInPercents } : null)
          }),
    salePrice: !shouldUpdateSalePrice
      ? {}
      : call(ownerAdminApi.setCoinSalePrice, accessToken, {
          newPrice: salePrice
        }),
    purchasePrice: !shouldUpdatePurchasePrice
      ? {}
      : call(ownerAdminApi.setCoinPurchasePrice, accessToken, {
          newPrice: purchasePrice
        })
  });
  const error = functionHelpers.pipe(
    objectHelpers.filterEntries((_, value) => !!value.error),
    objectHelpers.mapValues(value => value.error)
  )(calls);
  if (!objectHelpers.isEmpty(error)) {
    yield put(settingsActions.updateFeeSettingsFailure(error));
  } else {
    yield put(settingsActions.updateFeeSettingsSuccess(payload));
  }
}

export function* watchUpdateFeeSettings() {
  yield takeLatest(settingsActions.UPDATE_FEE_SETTINGS, updateFeeSettings);
}

export function* updateLimitSettings({ payload }) {
  const { email, maxTransactionAmount } = payload;
  const user = yield call(cabinetApi.getUserByEmail, email);
  if (user.error) {
    yield put(settingsActions.updateLimitSettingsFailure(user.error));
    return;
  }
  const userId = user.result?.data?.[0]?._id;
  if (!userId) {
    yield put(
      settingsActions.updateLimitSettingsFailure({
        email: { message: 'No user with such email' }
      })
    );
    return;
  }
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const setLimitResponse = yield call(ownerAdminApi.setUserLimit, accessToken, {
    userId,
    limit: maxTransactionAmount
  });
  if (setLimitResponse.error) {
    yield put(
      settingsActions.updateLimitSettingsFailure({
        maxTransactionAmount: setLimitResponse.error
      })
    );
    return;
  }
  yield put(settingsActions.updateLimitSettingsSuccess(payload));
}

export function* watchUpdateLimitSettings() {
  yield takeLatest(settingsActions.UPDATE_LIMIT_SETTINGS, updateLimitSettings);
}

export function* fetchUserTransactions({ payload: { page } }) {
  // const skip = page * constants.TRANSACTIONS_PAGE_DEFAULT_LIMIT;
  // const limit = constants.TRANSACTIONS_PAGE_DEFAULT_LIMIT;
  // const sortBy = 'createdAt';
  // const sortOrder = -1;
  // const { result, error } = yield* exchangeApi.getGlobalTransactions({ skip, limit, sortBy, sortOrder });
  // if (error) {
  //   yield put(settingsActions.fetchUserTransactionsFailure(error));
  // } else {
  //   yield put(settingsActions.fetchUserTransactionsSuccess(result));
  // }
}

export function* watchFetchUserTransactions() {
  yield takeLatest(settingsActions.FETCH_USER_TRANSACTIONS, fetchUserTransactions);
}

function* fetchGeneralLimits() {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { error, result } = yield* api.fetchGeneralLimits(accessToken);
  if (error) {
    yield put(settingsActions.fetchGeneralLimitsFailure(error.message));
  } else {
    yield put(
      settingsActions.fetchGeneralLimitsSuccess({
        data: result
      })
    );
  }
}

export function* watchFetchGeneralLimits() {
  yield takeLatest(settingsActions.FETCH_GENERAL_LIMITS, fetchGeneralLimits);
}

function* fetchIndividualLimits({ payload: { limit, skip, search } }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { error, result } = yield* api.fetchIndividualLimits({ accessToken, limit, skip, search });
  if (error) {
    yield put(settingsActions.fetchIndividualLimitsFailure(error.message));
  } else {
    yield put(
      settingsActions.fetchIndividualLimitsSuccess({
        data: result
      })
    );
  }
}

export function* watchFetchIndividualLimits() {
  yield takeLatest(settingsActions.FETCH_INDIVIDUAL_LIMITS, fetchIndividualLimits);
}

function* removeIndividualLimit({ payload: { id, currentPage } }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  yield* api.removeIndividualLimit({ accessToken, id });
  yield put(
    settingsActions.fetchIndividualLimits({
      limit: constants.INDIVIDUAL_LIMITS_PAGE,
      skip: (currentPage - 1) * constants.INDIVIDUAL_LIMITS_PAGE
    })
  );
}

export function* watchRemoveIndividualLimit() {
  yield takeLatest(settingsActions.REMOVE_INDIVIDUAL_LIMIT, removeIndividualLimit);
}

function* setIndividualLimit({ payload, currentPage }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { error } = yield* api.setIndividualLimit({ accessToken, payload });
  if (error) {
    yield put(settingsActions.setIndividualLimitFailure(error.message));
  }
  yield put(
    settingsActions.fetchIndividualLimits({
      limit: constants.INDIVIDUAL_LIMITS_PAGE,
      skip: (currentPage - 1) * constants.INDIVIDUAL_LIMITS_PAGE
    })
  );
}

export function* watchSetIndividualLimit() {
  yield takeLatest(settingsActions.SET_INDIVIDUAL_LIMIT, setIndividualLimit);
}

function* setGeneralLimit({ payload }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { error } = yield* api.setGeneralLimit({ accessToken, payload });
  if (error) {
    yield put(settingsActions.setGeneralLimitSuccess(error.message));
  }
}

export function* watchSetGeneralLimit() {
  yield takeLatest(settingsActions.SET_GENERAL_LIMIT, setGeneralLimit);
}
