import React from 'react';

const CheckMark = ({ fill = '#42BA12' }) => (
  <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6.44669L4.79421 11.2501L14 2.05337L12.6784 0.75L4.79421 8.62499L1.30334 5.13415L0 6.44669Z"
      fill={fill}
    />
  </svg>
);

export default CheckMark;
