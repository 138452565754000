// @ts-check

import { all, call, spawn } from 'redux-saga/effects';
import * as authSagas from './auth';
import * as eventsSagas from './events';
import * as paymentsSagas from './payments';
import * as usersSagas from './users';
import * as financeSagas from './finance';
import * as settingsSagas from './settings';
import * as globalRedirectsSagas from './redirects/globalRedirects';
import * as authRedirectsSagas from './redirects/authRedirects';
import * as profileRedirectsSagas from './redirects/profileRedirects';

export default function* rootSaga() {
  const sagas = [
    authSagas.checkAuthOnStartup,
    authSagas.watchResetPassword,
    authSagas.watchCreateNewPassword,
    authSagas.watchSignIn,
    authSagas.watchPatchPassword,
    authSagas.watchFetchProfileUser,
    authSagas.watchSaveProfileUserData,
    authSagas.watchUploadPhoto,
    authSagas.watchSaveUserPassword,
    eventsSagas.watchFetchEvents,
    paymentsSagas.watchFetchPaymentOrders,
    paymentsSagas.watchFetchPayoutInfo,
    paymentsSagas.watchApproveOrder,
    paymentsSagas.watchRejectOrder,
    paymentsSagas.watchFetchUserTransactions,
    usersSagas.watchFetchUsers,
    usersSagas.watchDeleteUser,
    usersSagas.watchFetchUser,
    usersSagas.watchInviteUser,
    financeSagas.watchFetchBalances,
    financeSagas.watchFetchReport,
    settingsSagas.watchFetchSettings,
    settingsSagas.watchUpdateFeeSettings,
    settingsSagas.watchSetGeneralLimit,
    settingsSagas.watchFetchGeneralLimits,
    settingsSagas.watchFetchIndividualLimits,
    settingsSagas.watchRemoveIndividualLimit,
    settingsSagas.watchSetIndividualLimit,
    settingsSagas.watchUpdateLimitSettings,
    settingsSagas.watchFetchUserTransactions,
    globalRedirectsSagas.watchLocationChanges,
    authRedirectsSagas.watchLocationChanges,
    authRedirectsSagas.watchActions,
    profileRedirectsSagas.watchActions
  ];
  yield all(
    sagas.map(saga =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.log(`Unhandled error in saga '${saga.name}': `, error);
            console.log(`Restarting saga '${saga.name}'...`);
          }
        }
      })
    )
  );
}
