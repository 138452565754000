// Absolute
import React from 'react';

// Custom hooks
import useShallowSelector from '@helpers/hooks/useShallowSelector';

// Components
import Navigation from './Navigation';

// Selectors
import * as routerSelectors from '@reducers/router';

const ConnectedNavigation = () => {
  const location = useShallowSelector(routerSelectors.locationSelector);

  const props = {
    location
  };

  return <Navigation {...props} />;
};

export default ConnectedNavigation;
