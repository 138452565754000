// Libraries
import { takeLatest } from 'redux-saga/effects';
// Actions
import * as usersActions from '@actions/users';
// Helpers
import { redirect } from '@helpers/sagaHelpers';
// Routes
import * as routes from '@routes';

function* handleAction(action) {
  switch (action.type) {
    case usersActions.DELETE_USER_SUCCESS:
      return yield redirect(routes.USERS);
    default:
      break;
  }
}

export function* watchActions() {
  yield takeLatest([usersActions.DELETE_USER_SUCCESS], handleAction);
}
