import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
  margin: 15px 15px 15px 0;
  padding: 20px;
  border: 1px solid #dbdbde;
  border-radius: 8px;
  
  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    margin: 10px 0;
    width: 100%;
  }
`;

export const StyledTitle = styled.div`
  margin-bottom: 3px;
  color: #2c2a32;
  font-size: 12px;
`;

export const StyledContent = styled.div`
  color: #000;
  font-weight: bold;
  font-size: 16px;
`;

export const ErrorContent = styled(StyledContent)`
  color: #ba2713;
`

