// @ts-check

// Libraries
import { call } from 'redux-saga/effects';
import pMinDelay from 'p-min-delay';
// Helpers
import * as objectHelpers from '@helpers/objectHelpers';
import * as stringHelpers from '@helpers/stringHelpers';
// Constants
import * as constants from '@constants';

/**
 *
 * @param {number} [minDelay]
 * @returns {(url: string, options: *) => *}
 */
const fetchWithMinDelay = minDelay => (url, options) => {
  return pMinDelay(fetch(url, options), minDelay ?? constants.FETCH_MIN_DELAY_MS);
};

/**
 * @typedef {Object} ApiResponse
 * @property {*} [error]
 * @property {*} [result]
 */

/**
 * @generator
 * @callback ApiCall
 * @param {string} endpoint
 * @param {*} [options]
 * @yields {ApiResponse}
 */

/**
 *
 * @param {string} baseUrl
 * @param {*} [apiOptions]
 * @returns {ApiCall}
 */
export const apiCaller = (baseUrl, apiOptions) => {
  const defaultOptions = apiOptions?.defaultOptions;
  const defaultResultTransformer = apiOptions?.resultTransformer ?? (result => result);
  const defaultErrorTransformer =
    apiOptions?.errorTransformer ??
    (error => {
      const message = error?.message;
      if (
        message?.toLowerCase()?.includes('failed to fetch') ||
        message?.toLowerCase()?.includes('networkerror when attempting to fetch resource')
      ) {
        return {
          ...error,
          message: 'Try again in a minute'
        };
      } else {
        return {
          ...error,
          message: stringHelpers.capitalize(message)
        };
      }
    });
  return function*(endpoint, options) {
    const resultTransformer = options?.resultTransformer ?? defaultResultTransformer;
    const errorTransformer = options?.errorTransformer ?? defaultErrorTransformer;
    try {
      const response = yield call(
        fetchWithMinDelay(options?.minDelay),
        baseUrl + endpoint,
        objectHelpers.mergeDeepRight(defaultOptions, options)
      );
      const resultBodyType = options?.resultBodyType ?? 'json';
      const errorBodyType = options?.errorBodyType ?? 'json';
      return response.ok
        ? {
            result: resultTransformer(yield parseBody(response, resultBodyType))
          }
        : {
            error: errorTransformer(yield parseBody(response, errorBodyType))
          };
    } catch (error) {
      return {
        error: errorTransformer(error)
      };
    }
  };
};

/**
 * Parse body accordingly to specified type
 * @param {*} response
 * @param {string} bodyType Can be 'json' or 'text'
 * @returns {*}
 */
function parseBody(response, bodyType) {
  // In case more body types needed, check what else is available:
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Response_objects
  switch (bodyType) {
    case 'json':
      return call([response, response.json]);
    case 'text':
      return call([response, response.text]);
    default:
      throw new Error(`Unknown body type: ${bodyType}`);
  }
}

/**
 *
 * @param {(result: *) => *} fn
 * @returns {(response: ApiResponse) => ApiResponse}
 */
export const mapResult = fn => response => {
  return response.error ? response : { result: fn(response.result) };
};

/**
 *
 * @param {(error: *) => *} fn
 * @returns {(response: ApiResponse) => ApiResponse}
 */
export const mapError = fn => response => {
  return response.error ? { error: fn(response.error) } : response;
};
