// Absolute imports
import styled, { css } from 'styled-components/macro';

// Theme
import { breakpoints } from '@theme/breakpoints';
import { mainGradient } from '@theme/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;

export const Root = styled.nav`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 40px 0;
  font-size: 20px;
  opacity: 0;
  z-index: 10;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  background-image: linear-gradient(
    to left,
    ${mainGradient.darkViolet},
    ${mainGradient.lighterViolet},
    ${mainGradient.purpleViolet},
    ${mainGradient.purple}
  );

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      transform: translate(0, 0);
    `};

  @media (min-width: ${breakpoints.lg}px) {
    display: none;
  }

  @media (orientation: landscape) and (max-width: ${breakpoints.smToMd}px) {
    justify-content: flex-start;
  }
`;

export const Item = styled.li`
  list-style: none;
  padding: 10px 0;
  font-family: 'Fira Sans', sans-serif;
`;
