// Absolute imports
import styled, { keyframes, css } from 'styled-components';

// Breakoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

const sizes = {
  sm: {
    width: '9px',
    height: '9px'
  },
  lg: {
    width: '13px',
    height: '13px'
  }
};

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;

  ${size => size === 'sm' && `height: 38px;`}

  @media (max-width: ${breakpoints.sm}px) {
    height: 38px;
  }
`;

export const LoadingWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 32px;
`;

const ldsEllipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const LoadingChild = styled.div`
  position: absolute;
  border-radius: 50%;
  background: ${colors.lightViolet};
  top: 12px;

   ${({ color }) => color && `background: ${colors[color]};`}

  ${({ size }) => size && `width: ${sizes[size].width}; height: ${sizes[size].height};`}

  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 8px;
    animation: ${ldsEllipsis1} 0.6s infinite;
  }

  &:nth-child(2) {
    left: 8px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  &:nth-child(3) {
    left: 30px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  :nth-child(4) {
    left: 52px;
    animation: ${ldsEllipsis3} 0.6s infinite;
  }
`;

const fishMove = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(68px, 0);
  }
`;

const fishMoveRule = css`
  ${fishMove} 2s infinite;
`;

export const Svg = styled.svg`
  animation: ${fishMoveRule};
  position: relative;
  top: -1px;
`;
