/**
 * Check if value is null or undefined
 * @param {*} value
 * @returns {boolean}
 */
export const isNil = value =>
  // https://stackoverflow.com/a/21273362
  value == null;

/**
 * Check if value is numeric string
 * @param {*} value
 * @returns {boolean}
 */
export const isNumericString = value => {
  return !/\s+/.test(value) && !isNaN(Number(value));
};

/**
 * Check if value is non-negative numeric string
 * @param {*} value
 * @returns {boolean}
 */
export const isNonNegativeNumericString = value => {
  return isNumericString(value) && Number(value) >= 0;
};

/**
 * Check if value is integer string
 * @param {*} value
 * @returns {boolean}
 */
export const isIntegerString = value => {
  if (!isNumericString(value)) return false;
  const n = Number(value);
  return Math.floor(n) === n;
};
