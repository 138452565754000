import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import styled from 'styled-components';

import Calendar from 'src/icons/Calendar';
import Cancel from 'src/icons/Cancel';
import ArrowLeft from 'src/icons/ArrowLeft';
import DoubleLeft from 'src/icons/DoubleLeft';
import ArrowRight from 'src/icons/ArrowRight';
import DoubleRight from 'src/icons/DoubleRight';

import Tile from './components/Tile';

const DatePicker = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState('month')
  const [selectedDates, setSelectedDates] = useState([]);

  const onSelectDates = (date) => {
    let dates = [...selectedDates];

    if (dates.length === 2) {
      dates = [date];
    } else {
      dates.push(date);
    }

    setSelectedDates(dates);
  };

  useEffect(() => {
    setSelectedDates(value ? value.map((value) => new Date(parseInt(value))) : []);
  }, [value]);

  const tileContent = view === 'month' ? props => <Tile {...props} selectedDates={selectedDates} onSelectDates={onSelectDates} range={value?.map((value) => new Date(parseInt(value)))} /> : undefined;

  const formatValue = value?.length > 0 ? <span>{format(new Date(parseInt(value[0])), 'dd.MM.yy')} - {format(new Date(parseInt(value[1])), 'dd.MM.yy')}</span> : <span className="choose">Choose</span>

  return (
    <DatePicker.Wrapper onClick={() => setOpen(true)}>
      <DateRangePicker
        isOpen={open}
        closeCalendar={false}
        tileContent={tileContent}
        value={value?.map((value) => new Date(parseInt(value)))}
        view={view}
        rangeDivider=" "
        prevLabel={<ArrowLeft />}
        prev2Label={<DoubleLeft />}
        nextLabel={<ArrowRight />}
        next2Label={<DoubleRight />}
        onCalendarClose={() => setOpen(false)}
        onViewChange={({ view }) => setView(view)}
        format=" "
        dayPlaceholder="Select date"
        monthPlaceholder=""
        yearPlaceholder=""
        clearIcon={Boolean(value) && <Cancel />}
        calendarIcon={<Calendar />}
        openCalendarOnFocus
        onChange={(values) => onChange(values?.map((value) => value.valueOf()))} 
        locale="en-US"
      />
      <DatePicker.Format>
        {formatValue}
      </DatePicker.Format>
    </DatePicker.Wrapper>
  );
};

DatePicker.Wrapper = styled.div`
  position: relative;
  margin-top: 8px;
  color: #12072C;
  font-weight: normal;
  font-size: 12px;

  .react-daterange-picker {
    display: block;
  }

  .react-daterange-picker__wrapper {
    border: 1px solid #E4E3E8;
    border-radius: 4px;
    padding: 8px 10px;
    width: 200px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .choose {
    color: #A8A5B4; 
  }

  .react-daterange-picker__inputGroup {
    min-width: auto;
  }

  .react-daterange-picker__inputGroup__input:invalid {
    background-color: transparent;
  }

  .react-daterange-picker__inputGroup__input {
    color: #12072C !important;
    font-size: 12px !important;
    font-weight: normal;
  }

  .react-daterange-picker__wrapper:hover, .react-daterange-picker__wrapper:active {
    border-color: #441EDA;
  }

  .react-calendar {
    border: 1px solid #E4E3E8;
    box-sizing: border-box;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 12px;
    color: #9D9FA3;

    abbr {
      text-decoration: inherit;
    }
  }

  .react-calendar__tile--now {
    background-color: transparent; 
  }

  .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: transparent; 
  }
  
  .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background-color: transparent;
    border: none;
  }

  .react-calendar__tile--active {
    background-color: transparent; 
    color: inherit;
  }

  .react-calendar__tile {
    height: 50px;
    position: relative;
  }

  body ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  button:focus {
    outline: none;
  }
`;

DatePicker.Format = styled.div`
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  font-size: 14px;
`;

export default DatePicker;