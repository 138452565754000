// Abolute
import React from 'react';
import CreatePassword from './CreatePassword';
import * as actions from '@actions/auth';

// Custom hooks
import { useShallowSelector, useActions } from '@helpers/hooks';

// Selectors
import * as authSelectors from '@reducers/auth';
import * as routerSelectors from '@reducers/router';

export default function ConnectedCreatePasswordAfterRestore() {
  const location = useShallowSelector(routerSelectors.locationSelector);
  const resetPasswordToken = useShallowSelector(authSelectors.resetPasswordTokenSelector);
  const createNewPasswordAfterRestoreError = useShallowSelector(
    authSelectors.createNewPasswordAfterRestoreErrorSelector
  );
  const createPasswordAttempt = useShallowSelector(authSelectors.createNewPasswordAttemptSelector);
  const isNewPasswordCreated = useShallowSelector(authSelectors.isNewPasswordCreatedSelector);

  const { createNewPassword } = useActions(actions);

  const props = {
    submit: createNewPassword,
    location,
    resetPasswordToken,
    createNewPasswordError: createNewPasswordAfterRestoreError,
    createPasswordAttempt,
    isNewPasswordCreated
  };

  return <CreatePassword {...props} />;
}
