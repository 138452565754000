import React, { useCallback } from 'react';

// Styles
import * as StyledUI from '../UI';

// Helpers
import { toCamelCase } from '@helpers';
import { CellTitle } from '../UI';

export default function TableRow({ rowIndex, headers, item, rowProps, setHoveredRowIndex }) {
  const handleTableRowMouseEnter = useCallback(event => {
    setHoveredRowIndex(Number(event.target.getAttribute('data-row-index')));
  }, [setHoveredRowIndex]);

  return (
    <StyledUI.TableRow data-test="table-row" key={rowIndex} onMouseEnter={handleTableRowMouseEnter}>
      {headers.map((header, columnIndex) => {
        return (
          <StyledUI.Cell
            data-test="table-cell"
            isActive={header === 'Name'}
            key={columnIndex}
            data-row-index={rowIndex}
            data-column-index={columnIndex}
            data-title={header}
          >
            <CellTitle>
              {typeof item[toCamelCase(header)] === 'function'
                ? item[toCamelCase(header)](rowProps)
                : item[toCamelCase(header)]}
            </CellTitle>
          </StyledUI.Cell>
        );
      })}
    </StyledUI.TableRow>
  );
}
