import styled from 'styled-components';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

export const Root = styled.div`
  background: ${colors.darkRed};
  border-radius: 4px;
  color: ${colors.white};

  position: absolute;
  z-index: 5;
  top: 20px;
  right: 20px;
  margin: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.sm}px) {
    left: 0;
    right: 0;
    top: 10px;
    font-size: 14px;
    width: 80%;
    padding: 8px;
  }
`;

export const Text = styled.div`
  margin: 0 0 0 5px;
`;
