// Libraries
import format from 'date-fns/format';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// Helpers
import * as stringHelpers from '@helpers/stringHelpers';
import { userOrdersSelector, userOrdersCountSelector, userOrdersLoadingSelector } from '@reducers/payments';
// Styles
import { useActions } from '@helpers/hooks';
import * as paymentsActions from '@actions/payments';
import Table from '@components/SmTable';
import Select from '@components/Select';
import Search from '@components/Search';
import ClipboardCopy from '@components/ClipboardCopy';
import styled from 'styled-components';
import useQueryParams from '@helpers/hooks/useQueryParams';

import { PAIR_OPTIONS, ORDER_STATUS_OPTIONS, TABLE_LIMIT } from '../../constants/index';
import Loading from '../Loading/Loading';
import * as Styled from './styles';
import DatePicker from '@components/DatePicker';

const getTitleByStatus = (status) => {
  if (status) {
    return {
      title: 'Confirmed',
      color: '#42BA12',
    };
  } else if (status === undefined) {
    return {
      title: 'Not found',
      color: '#A8A5B4',
    };
  }

  return { 
    title: 'Unconfirmed',
    color: '#BA2612',
  };
}

export default function PaymentHistory() {
  const loading = useSelector(userOrdersLoadingSelector);
  const items = useSelector(userOrdersSelector);
  const total = useSelector(userOrdersCountSelector);
  const { fetchUserTransactions } = useActions(paymentsActions);

  const [params] = useQueryParams();

  useEffect(() => {
    fetchUserTransactions({ limit: TABLE_LIMIT, skip: (params.page - 1) * TABLE_LIMIT, ...params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params)]);

  const headers = [
    {
      title: 'Date',
      key: 'createdAt',
      sorted: true,
      width: 1,
      filter: (props) => <DatePicker {...props} />,
    },
    {
      title: 'Pair',
      key: 'pair',
      width: 1,
      filter: (props) => <Select {...props} options={PAIR_OPTIONS} />
    },
    {
      title: 'Status',
      key: 'status',
      width: 1,
      filter: (props) => <Select {...props} options={ORDER_STATUS_OPTIONS} />
    },
    {
      title: 'Paid',
      key: 'tokens',
      sorted: true,
      width: 1,
      filter: (props) => <Search clear {...props} />
    },
    {
      title: 'Received',
      key: 'funds',
      sorted: true,
      width: 1,
      filter: (props) => <Search clear {...props} />
    },
    {
      title: 'Email',
      key: 'email',
      width: 1,
      filter: (props) => <Search clear {...props} />
    },
    {
      title: 'Sender\'s wallet',
      key: 'sender',
      width: 1,
      filter: (props) => <Search clear {...props} />
    },
    {
      title: 'Recipient\'s wallet',
      key: 'beneficiary',
      width: 1,
      filter: (props) => <Search clear {...props} />
    },
    {
      title: 'Transaction status',
      key: 'transactionStatus',
      width: 1,
    },
  ];

  const tableItemMapper = item => {
    const [left, right] = item?.pair.split('/');
    const { title, color } = getTitleByStatus(item.transactionStatus);

    return [
      {
        title: format(new Date(item.createdAt), 'dd.MM.yyyy, HH:mm'), 
      },
      {
        title: item.pair, 
      },
      {
        title: stringHelpers.capitalize(item.status), 
      },
      {
        title: (
          <a href={item.paidLink} target="_blank" rel="noopener noreferrer">
            {item.paid} {left}
          </a>
        ), 
      },
      {
        title: (
          <a href={item.receivedLink} target="_blank" rel="noopener noreferrer">
            {item.received} {right}
          </a>
        ), 
      },
      {
        title: (
          <TruncateTextWithCopy>
            {item.email}
          </TruncateTextWithCopy>
        )
      },
      {
        title: (
          <a href={item.senderWalletLink} target="_blank" rel="noopener noreferrer">
            <TruncateTextWithCopy>
              {item.senderWallet}
            </TruncateTextWithCopy>
          </a>
        ), 
      },
      {
        title: (
          <a href={item.recipientWalletLink} target="_blank" rel="noopener noreferrer">
            <TruncateTextWithCopy>
              {item.recipientWallet}
            </TruncateTextWithCopy>
          </a>
        ), 
      },
      {
        title: (<div>
          <PaymentHistory.StatusCircle color={color} /> {title}
        </div>),
      }
    ]
  };

  return (
    <Styled.Wrapper>
      {loading ? (
        <div style={{ height: 540 }}>
          <Loading />
        </div>
      ) : (
        <Table 
          headers={headers} 
          items={items} 
          totalCount={total}
          transformItem={tableItemMapper} 
          pagination={{ totalPages: Math.ceil(total / TABLE_LIMIT) }} 
        />
      )}
    </Styled.Wrapper>
  );
}

const TruncateText = styled.span`
  display: block;
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

const TruncateTextWithCopy = ({ children }) => (
  <TruncateText style={{ paddingRight: 15 }}>
    {children}
    <ClipboardCopy copyText={children} />
  </TruncateText>
);

PaymentHistory.StatusCircle = styled.div`
  display: inline-block;
  position: relative;
  top: -2px;
  background-color: ${props => props.color};
  border-radius: 50%;
  height: 4px;
  width: 4px;
`;
