// Absolute
import React from 'react';

// Custom hooks
import { useShallowSelector, useActions } from '@helpers/hooks';

import UsersProfile from './UsersProfile';
import * as userActions from '@actions/users';
import * as authActions from '@actions/auth';

// Selectors
import * as usersSelectors from '@reducers/users';
import * as authSelectors from '@reducers/auth';

export default function ConnectedUsersProfile(routerProps) {
  const user = useShallowSelector(authSelectors.userSelector);
  const currentUser = useShallowSelector(usersSelectors.currentUserSelector);
  const isUserDeleted = useShallowSelector(usersSelectors.isUserDeletedSelector);
  const deleteUserError = useShallowSelector(usersSelectors.deleteUserErrorSelector);

  const { deleteUser, resetCurrentUser, fetchUser } = useActions(userActions);
  const { signOut } = useActions(authActions);

  const props = {
    routerProps,
    user,
    currentUser,
    deleteUser,
    isUserDeleted,
    deleteUserError,
    resetCurrentUser,
    fetchUser,
    signOut
  };

  return <UsersProfile {...props} />;
}
