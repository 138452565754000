// Absolute
import styled from 'styled-components';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;

  @media (max-width: 767px) {
    thead,
    tbody {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
`;

export const Table = styled.table`
  width: 100%;
  margin: 20px 0;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const TableRow = styled.tr`
  width: 100%;

  &:hover {
    background: ${colors.whiteGray};
  }

  @media (max-width: 767px) {
    display: block;
  }
`;

export const CellTitle = styled.div`
  @media (max-width: 767px) {
    width: 65%;
    min-height: 21px;
    padding-left: 5px;
    margin-left: auto;
    text-overflow: ellipsis;
    overflow: hidden; 
  }
`

export const Cell = styled.td`
  border-top: 2px solid ${colors.theLightestGray};
  border-bottom: 2px solid ${colors.theLightestGray};
  padding: 15px 5px;
  white-space: nowrap;

  ${({ isActive }) => isActive && `cursor:pointer; color: ${colors.violet}`};

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    display: block;
    position: relative;

    &:before {
      content: attr(data-title);
      position: absolute;
      left: 6px;
      width: 35%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }
  }
`;

export const HeaderCell = styled(Cell)`
  font-size: 18px;
  font-weight: bold;

  background: ${colors.white};

  ${({ width }) => width && `width: ${width}`};

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    display: block;
  }
`;

export const Avatar = styled.div`
  width: 44px;
  height: 43px;
  ${({ link }) => link && `background: url(${link})`};

  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  border-radius: 50%;
`;

export const TableButton = styled.button`
  color: ${colors.white};
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  margin: 0;
  background: ${colors.darkRed};
  width: 100px;
  font-size: 14px;
  display: none;
  padding: 8px;
  text-align: center;

  ${({ active }) => active && `display: block`};

  @media (max-width: ${breakpoints.sm}px) {
    display: block;
  }
`;
