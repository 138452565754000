import styled from 'styled-components';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

export const Container = styled.div`
  display: none;

  @media (min-width: ${breakpoints.md}px) {
    display: flex;
    align-items: center;
  }
`;

export const TextDataContainer = styled.div`
  padding: 50px 18px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const Username = styled.div`
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};

  @media (max-width: ${breakpoints.lg}px) {
    max-width: 150px;
    font-size: 12px;
  }
`;

export const Email = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray};

  @media (max-width: ${breakpoints.lg}px) {
    font-size: 12px;
  }
`;

export const SignOutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 14px;

  & > * {
    cursor: pointer;
  }
`;
