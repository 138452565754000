export const PAIR_OPTIONS = [
  { key: 'BTC/EEX', value: 'BTC/EEX', text: 'BTC/EEX' },
  { key: 'EEX/BTC', value: 'EEX/BTC', text: 'EEX/BTC'  },
];

export const ORDER_STATUS_OPTIONS = [
  { key: 'expired', value: 'expired', text: 'Expired'  },
  { key: 'canceled', value: 'canceled', text: 'Canceled'  },
  { key: 'processing', value: 'processing', text: 'Processing'  },
  { key: 'processed', value: 'processed', text: 'Processed'  },
  { key: 'rejected', value: 'rejected', text: 'Rejected'  },
  { key: 'processing-approval', value: 'processing-approval', text: 'Processing Аpproval'  },
];

export const TABLE_LIMIT = 8;