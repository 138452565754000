// Absolute
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  color: ${colors.black};
  user-select: none;
  height: 42px;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

const TabLinkComponent = ({ active, ...props }) => <Link {...props} />;

export const TabLink = styled(TabLinkComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 3px 3px 0 0;
  border: 1px solid transparent;
  border-bottom: 1px solid ${colors.lightGray};
  cursor: pointer;

  color: inherit;
  text-decoration: none;
  &:hover {
    color: ${({ active }) => (active ? colors.violet : 'inherit')};
    text-decoration: none;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${colors.violet};
      border: 1px solid ${colors.lightGray};
      border-bottom: 1px solid transparent;
    `};
`;

export const TabSpacer = styled.div`
  flex-grow: 1;
  border-bottom: 1px solid ${colors.lightGray};
`;
