// Absolute imports
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Styles
import * as Styled from './styles';
import * as StyledUI from '../UI';

// Theme
import * as colors from '@theme/colors';

MobileNavigation.propTypes = {
  active: PropTypes.bool.isRequired,
  handleToggleMenu: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired
};

export default function MobileNavigation({ active, handleToggleMenu, isAuthenticated, signOut }) {
  const styles = {
    textDecoration: 'none',
    color: colors.white,
    cursor: 'pointer'
  };

  const handleLogOut = useCallback(() => {
    handleToggleMenu();
    signOut();
  }, [handleToggleMenu, signOut]);

  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.Root active={active}>
          <Styled.Item>
            <StyledUI.StyledLink data-test="mobile-nav-to-payments-link" onClick={handleToggleMenu} to="/payments">
              Payments
            </StyledUI.StyledLink>
          </Styled.Item>
          <Styled.Item>
            <StyledUI.StyledLink data-test="mobile-nav-to-events-link" onClick={handleToggleMenu} to="/events">
              Events
            </StyledUI.StyledLink>
          </Styled.Item>
          <Styled.Item>
            <StyledUI.StyledLink data-test="mobile-nav-to-users-link" onClick={handleToggleMenu} to="/users">
              Users
            </StyledUI.StyledLink>
          </Styled.Item>
          <Styled.Item>
            <StyledUI.StyledLink data-test="mobile-nav-to-settings-link" onClick={handleToggleMenu} to="/settings">
              Settings
            </StyledUI.StyledLink>
          </Styled.Item>
          <Styled.Item>
            <StyledUI.StyledLink
              data-test="mobile-nav-to-finance-link"
              onClick={handleToggleMenu}
              to="/finance"
              style={styles}
            >
              Finance
            </StyledUI.StyledLink>
          </Styled.Item>

          <StyledUI.Line width="50%" margin="20px 0" />

          {isAuthenticated && (
            <>
              <Styled.Item>
                <StyledUI.StyledLink data-test="mobile-nav-to-profile-link" onClick={handleToggleMenu} to="/profile">
                  Profile
                </StyledUI.StyledLink>
              </Styled.Item>
              <Styled.Item>
                <StyledUI.StyledLink data-test="mobile-nav-signout-btn" onClick={handleLogOut} to="/" style={styles}>
                  Sign Out
                </StyledUI.StyledLink>
              </Styled.Item>
            </>
          )}
        </Styled.Root>
      </Styled.Wrapper>
    </Styled.Container>
  );
}
