// Libraries
import React, { useMemo, useEffect, useCallback } from 'react';
import { useForm } from 'react-form';
import PropTypes from 'prop-types';
import PropTypesAirbnb from 'airbnb-prop-types';
// Components
import CheckedFormInput from '../CheckedFormInput';
// Utils
import * as valueHelpers from '@helpers/valueHelpers';
import * as objectHelpers from '@helpers/objectHelpers';
import * as functionHelpers from '@helpers/functionHelpers';
import * as errorHelpers from '@helpers/errorHelpers';
import * as validationHelpers from '@helpers/validate';
// Constants
import * as constants from '@constants';
// Styles
import * as Styled from './styles';
import * as StyledUI from '../UI';

FeeSettings.propTypes = {
  currentFeeInEex: PropTypes.string.isRequired,
  currentFeeInPercents: PropTypes.string.isRequired,
  currentSalePrice: PropTypes.string.isRequired,
  currentPurchasePrice: PropTypes.string.isRequired,
  backendError: PropTypesAirbnb.or([PropTypes.object, PropTypesAirbnb.explicitNull]).isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};

export default function FeeSettings({
  currentFeeInEex,
  currentFeeInPercents,
  currentSalePrice,
  currentPurchasePrice,
  backendError,
  isSaving,
  onSave
}) {
  const validateFeeInEexField = useMemo(
    () =>
      validationHelpers.createRangeFieldValidator({
        from: constants.MIN_FEE_IN_EEX,
        to: constants.MAX_FEE_IN_EEX
      }),
    []
  );

  const validateFeeInPercentsField = useMemo(
    () =>
      validationHelpers.createRangeFieldValidator({
        from: constants.MIN_FEE_IN_PERCENTS,
        to: constants.MAX_FEE_IN_PERCENTS
      }),
    []
  );

  const defaultValues = useMemo(
    () => ({
      feeInEex: '',
      feeInPercents: '',
      salePrice: '',
      purchasePrice: ''
    }),
    []
  );

  const handleSubmit = useCallback(
    values => {
      const changedValues = objectHelpers.filterEntries((_, value) => value !== '')(values);
      onSave(changedValues);
    },
    [onSave]
  );

  const {
    Form,
    values,
    setValues,
    meta: { canSubmit, isSubmitted },
    getFieldMeta,
    setFieldMeta
  } = useForm({
    defaultValues,
    onSubmit: handleSubmit
  });

  const formErrors = functionHelpers.pipe(
    objectHelpers.mapValues((_, key) => getFieldMeta(key)?.error),
    objectHelpers.filterEntries((_, value) => Boolean(value))
  )(values);

  const errorText = errorHelpers.formatFieldErrors(formErrors);
  const saveButtonText = isSaving ? 'Saving' : 'Save';
  const saveSucceeded = isSubmitted && !isSaving && !backendError;
  const isShowingSuccessText = saveSucceeded && objectHelpers.isEmpty(formErrors);
  const isSaveEnabled = canSubmit && !isSaving && objectHelpers.some((_, value) => value !== '')(values);

  useEffect(() => {
    if (backendError?.fee) {
      const message = backendError.fee.message;
      if (values.feeInEex !== defaultValues.feeInEex) {
        setFieldMeta('feeInEex', meta => ({ ...meta, error: message, isTouched: true }));
      }
      if (values.feeInPercents !== defaultValues.feeInPercents) {
        setFieldMeta('feeInPercents', meta => ({ ...meta, error: message, isTouched: true }));
      }
    }
    if (backendError?.salePrice) {
      const message = backendError.salePrice.message;
      setFieldMeta('salePrice', meta => ({ ...meta, error: message, isTouched: true }));
    }
    if (backendError?.purchasePrice) {
      const message = backendError.purchasePrice.message;
      setFieldMeta('purchasePrice', meta => ({ ...meta, error: message, isTouched: true }));
    }
  }, [backendError, setFieldMeta, values, defaultValues]);

  useEffect(() => {
    if (saveSucceeded) {
      setValues(defaultValues);
    }
  }, [saveSucceeded, setValues, defaultValues]);

  return (
    <Form>
      <Styled.InputGroupsWrapper>
        <Styled.InputGroup>
          <StyledUI.FormLabelWrapper>
            <StyledUI.FormLabel>Current fee amount (EEX)</StyledUI.FormLabel>
            <StyledUI.FormInput
              disabled
              type="text"
              placeholder="Loading..."
              value={currentFeeInEex}
              data-test="current-fee-in-eex-input"
            />
          </StyledUI.FormLabelWrapper>
          <StyledUI.FormLabelWrapper>
            <StyledUI.FormLabel>New fee amount (EEX)</StyledUI.FormLabel>
            <CheckedFormInput
              type="text"
              field="feeInEex"
              check={valueHelpers.isNonNegativeNumericString}
              validate={validateFeeInEexField}
              placeholder="Enter new fee amount"
              data-test="new-fee-in-eex-input"
            />
          </StyledUI.FormLabelWrapper>
        </Styled.InputGroup>
        <Styled.InputGroup>
          <StyledUI.FormLabelWrapper>
            <StyledUI.FormLabel>Current fee %</StyledUI.FormLabel>
            <StyledUI.FormInput
              disabled
              type="text"
              placeholder="Loading..."
              value={currentFeeInPercents}
              data-test="current-fee-in-percents-input"
            />
          </StyledUI.FormLabelWrapper>
          <StyledUI.FormLabelWrapper>
            <StyledUI.FormLabel>New fee %</StyledUI.FormLabel>
            <CheckedFormInput
              type="text"
              field="feeInPercents"
              check={valueHelpers.isNonNegativeNumericString}
              validate={validateFeeInPercentsField}
              placeholder="Enter new fee amount"
              data-test="new-fee-in-percents-input"
            />
          </StyledUI.FormLabelWrapper>
        </Styled.InputGroup>
        <Styled.InputGroup>
          <StyledUI.FormLabelWrapper>
            <StyledUI.FormLabel>User sells 1 EEX for</StyledUI.FormLabel>
            <StyledUI.FormInput
              disabled
              type="text"
              placeholder="Loading..."
              value={currentSalePrice}
              data-test="current-sale-price-input"
            />
          </StyledUI.FormLabelWrapper>
          <StyledUI.FormLabelWrapper>
            <StyledUI.FormLabel>New sell price in USD</StyledUI.FormLabel>
            <CheckedFormInput
              type="text"
              field="salePrice"
              check={valueHelpers.isNonNegativeNumericString}
              placeholder="Enter new price"
              data-test="new-sale-price-input"
            />
          </StyledUI.FormLabelWrapper>
        </Styled.InputGroup>
        <Styled.InputGroup>
          <StyledUI.FormLabelWrapper>
            <StyledUI.FormLabel>User buys 1 EEX for</StyledUI.FormLabel>
            <StyledUI.FormInput
              disabled
              type="text"
              placeholder="Loading..."
              value={currentPurchasePrice}
              data-test="current-buy-price-input"
            />
          </StyledUI.FormLabelWrapper>
          <StyledUI.FormLabelWrapper>
            <StyledUI.FormLabel>New buy price in USD</StyledUI.FormLabel>
            <CheckedFormInput
              type="text"
              field="purchasePrice"
              check={valueHelpers.isNonNegativeNumericString}
              placeholder="Enter new price"
              data-test="new-buy-price-input"
            />
          </StyledUI.FormLabelWrapper>
        </Styled.InputGroup>
      </Styled.InputGroupsWrapper>
      <Styled.Info>All existing admin users will be notified about this change by the email</Styled.Info>
      <StyledUI.Flex margin="40px 0 0 0">
        <StyledUI.SettingsButton disabled={!isSaveEnabled} data-test="fee-settings-save-btn">
          {saveButtonText}
        </StyledUI.SettingsButton>
        <StyledUI.SaveStatus
          success={isShowingSuccessText}
          margin="0 0 0 30px"
          data-test="fee-settings-save-status-msg"
        >
          {isShowingSuccessText ? 'Changes saved successfully' : errorText}
        </StyledUI.SaveStatus>
      </StyledUI.Flex>
    </Form>
  );
}
