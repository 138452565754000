import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'debounce';
import { Search as SemanticUISearch } from 'semantic-ui-react';
import styled from 'styled-components';

const Search = ({ value, onChange, clear = false, placeholder = "Search..." }) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(value);
  }, [value]);

  const onChangeHandler = useCallback(debounce(value => onChange(value), 500), [])

  const onSearch = (_, { value }) => {
    helperChange(value);
  };

  const helperChange = (value) => {
    setSearch(value);
    onChangeHandler(value);
  };

  const iconSearch = clear && value ? { name: 'cancel', link: true, onClick: () => helperChange('') } : false

  return <Search.Wrapper>
    <SemanticUISearch value={search} onSearchChange={onSearch} placeholder={placeholder} icon={iconSearch} />
  </Search.Wrapper>
};

Search.Wrapper = styled.div`
  margin-top: 8px;

  .ui.input {
    width: 100%;
  }

  input {
    border: 1px solid #E4E3E8 !important;
    border-radius: 4px !important;
  }

  input:hover, input:focus {
    border-color: #441EDA !important;
  }
`;

export default Search;