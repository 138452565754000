import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import PropTypesAirbnb from 'airbnb-prop-types';

// Styles
import * as StyledUI from '../UI';

// Constants
import { EVENTS_PAGE_DEFAULT_LIMIT } from '@constants';

// Components
import Table from '../Table/Table';
import Pagination from '../Pagination/Pagination';

// Helpers
import { getDate, getTime } from '@helpers';

// Theme
import * as colors from '@theme/colors';

Events.propTypes = {
  currentEvents: PropTypes.array.isRequired,
  eventsCount: PropTypes.number.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  fetchEventsError: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired
};

export default function Events({ fetchEvents, eventsCount, currentEvents, fetchEventsError }) {
  const [currentPage, setCurrentPage] = useState(1);

  const tableHeaders = ['Action', 'Value', 'Performed by', 'Date', 'Time'];

  const events = useMemo(() => {
    return currentEvents.map(event => {
      return {
        action: event.action,
        value: event.value,
        performedBy: event.performedBy,
        date: getDate(event.createdAt),
        time: getTime(event.createdAt)
      };
    });
  }, [currentEvents]);

  useEffect(() => {
    fetchEvents({ limit: EVENTS_PAGE_DEFAULT_LIMIT, skip: 0 });
  }, [fetchEvents]);

  const setPage = pageNumber => {
    setCurrentPage(pageNumber);

    fetchEvents({ limit: EVENTS_PAGE_DEFAULT_LIMIT, skip: (pageNumber - 1) * EVENTS_PAGE_DEFAULT_LIMIT });
  };

  return (
    <>
      <StyledUI.Flex justify="space-between">
        <StyledUI.Flex direction="column">
          <StyledUI.H2 color={colors.black} margin="5px 0">
            Events
          </StyledUI.H2>
          <StyledUI.Text data-test="events-total" color={colors.darkGray} margin="10px 0" size="s">
            Total: {eventsCount}
          </StyledUI.Text>
        </StyledUI.Flex>
      </StyledUI.Flex>
      {fetchEventsError && <StyledUI.Error>{fetchEventsError}</StyledUI.Error>}
      <Table items={events} headers={tableHeaders} />

      <Pagination
        itemsPerPage={EVENTS_PAGE_DEFAULT_LIMIT}
        totalCount={eventsCount}
        setPage={setPage}
        page={currentPage}
      />
    </>
  );
}
