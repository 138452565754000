// Libraries
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';
// Styles
import * as StyledUI from '../UI';

CheckedFormInput.propTypes = {
  type: PropTypes.string,
  field: PropTypes.string.isRequired,
  check: PropTypes.func,
  validate: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  'data-test': PropTypes.string
};

/**
 *
 * @param {string} field - Name of the form field
 * @param {function} check - Function receiving new value and returning if it's allowed to enter this value in input
 * @param {function} validate - Function receiving value and returning validation errors
 */
export default function CheckedFormInput({
  type,
  field,
  check,
  validate,
  placeholder,
  disabled,
  autoComplete,
  ...restProps
}) {
  const defaultValidator = useMemo(() => () => false, []);
  const validator = useMemo(() => {
    const validator = validate ?? defaultValidator;
    return value => {
      const validationResult = validator(value);
      if (typeof validationResult === 'string' || !validationResult) {
        return validationResult;
      } else {
        throw new Error('Validator should return string with error or falsey value, got: ' + validationResult);
      }
    };
  }, [validate, defaultValidator]);
  const {
    value,
    setValue,
    meta: { error, isTouched },
    setMeta
  } = useField(field, { validate: validator });
  const handleChange = useCallback(
    event => {
      if (!check || check(event.target.value)) {
        setValue(event.target.value);
      }
    },
    [check, setValue]
  );
  const handleBlur = useCallback(
    _ => {
      setMeta({ isTouched: true });
    },
    [setMeta]
  );
  const isFailed = isTouched && Boolean(error);
  return (
    <StyledUI.FormInput
      type={type}
      autoComplete={autoComplete}
      disabled={disabled}
      failed={isFailed}
      placeholder={placeholder}
      title={isFailed ? error : null}
      alt={isFailed ? error : null}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      data-test={restProps['data-test']}
    />
  );
}
