import { takeLatest, put, select, call } from 'redux-saga/effects';
import * as authSelectors from '@reducers/auth';
import * as actions from '@actions/auth';
import * as api from '@services/ownerAdminApi';
import * as constants from '@constants';

function* signIn({ payload }) {
  const { error, result } = yield* api.signIn(payload.email, payload.password);
  if (error) {
    yield put(actions.signInFailure(error));
  } else {
    const isPasswordChanged = !!(result.user && result.user.isPasswordChanged);
    yield put(
      actions.signInSuccess({
        accessToken: result.accessToken,
        password: payload.password,
        isPasswordChanged,
        user: result.user
      })
    );
  }
}

export function* watchSignIn() {
  yield takeLatest(actions.SIGN_IN, signIn);
}

function* patchPassword({ payload }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const tempPassword = yield select(authSelectors.tempPasswordSelector);
  const { error, result } = yield* api.patchPassword(accessToken, tempPassword, payload);
  if (error) {
    yield put(actions.patchPasswordFailure(error));
  } else {
    yield put(actions.patchPasswordSuccess(result[0].isPasswordChanged));
    yield put(actions.signOut());
  }
}

export function* watchPatchPassword() {
  yield takeLatest(actions.PATCH_PASSWORD, patchPassword);
}

function* resetPassword({ payload }) {
  const { error, result } = yield* api.resetPassword(payload);
  if (error) {
    yield put(actions.resetPasswordFailure(error.message));
  } else {
    yield put(actions.resetPasswordSuccess(result));
  }
}

export function* watchResetPassword() {
  yield takeLatest(actions.RESET_PASSWORD, resetPassword);
}

function* createNewPassword({ payload }) {
  const { error, result } = yield* api.createNewPassword(payload);
  if (error) {
    yield put(actions.createNewPasswordFailure(error.message));
  } else {
    yield put(actions.createNewPasswordSuccess(result));
  }
}

export function* watchCreateNewPassword() {
  yield takeLatest(actions.CREATE_NEW_PASSWORD, createNewPassword);
}

function* fetchProfileUser({ payload: { id } }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);

  const { error, result } = yield* api.fetchUser({ accessToken, id });

  if (error) {
    yield put(actions.fetchProfileUserFailure(error.message));
  } else {
    yield put(
      actions.fetchProfileUserSuccess({
        user: result.data[0]
      })
    );
  }
}

export function* watchFetchProfileUser() {
  yield takeLatest(actions.FETCH_PROFILE_USER, fetchProfileUser);
}

function* saveProfileUserData({ payload }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);

  const { error, result } = yield* api.saveProfileUserData({ accessToken, ...payload });

  if (error) {
    yield put(actions.saveProfileUserDataFailure(error.message));
  } else {
    yield put(
      actions.saveProfileUserDataSuccess({
        user: result[0]
      })
    );
  }
}

export function* watchSaveProfileUserData() {
  yield takeLatest(actions.SAVE_PROFILE_USER_DATA, saveProfileUserData);
}

function* uploadPhoto({ payload }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);

  const { error, result } = yield call(api.uploadPhoto, accessToken, payload);

  if (error) {
    yield put(actions.uploadPhotoFailure(error.message));
  } else {
    yield put(
      actions.uploadPhotoSuccess({
        user: result[0]
      })
    );
  }
}

export function* watchUploadPhoto() {
  yield takeLatest(actions.UPLOAD_PHOTO, uploadPhoto);
}

function* saveUserPassword({ payload }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);

  const { error, result } = yield* api.saveUserPassword({ accessToken, ...payload });

  if (error) {
    yield put(actions.saveUserPasswordFailure(error.message));
  } else {
    yield put(
      actions.saveUserPasswordSuccess({
        user: result[0]
      })
    );
  }
}

export function* watchSaveUserPassword() {
  yield takeLatest(actions.SAVE_USER_PASSWORD, saveUserPassword);
}

function* checkAuth() {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  if (accessToken) {
    const { error } = yield* api.checkAuth(accessToken);
    if (error?.name === constants.API_ERRORS.NOT_AUTHENTICATED) {
      yield put(actions.signOut());
    }
  }
}

export function* checkAuthOnStartup() {
  yield* checkAuth();
}
