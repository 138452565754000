// Absolute
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import TableRow from '@components/TableRow/TableRow';

// Styles
import * as StyledUI from '@components/UI';

TableComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string),
};

function TableComponent({ items, headers }) {
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);

  const handleTableBodyMouseLeave = useCallback(() => {
    setHoveredRowIndex(-1);
  }, []);

  return (
    <StyledUI.TableWrapper>
      <StyledUI.Table>
        <thead>
        <StyledUI.TableRow>
          {headers.map((header, index) =>
            header === 'Avatar' ? (
              <StyledUI.HeaderCell data-test="table-header-cell" key={index} width="6%" />
            ) : header === 'Button' ? (
              <StyledUI.HeaderCell data-test="table-header-cell" key={index} width="10%" />
            ) : (
              <StyledUI.HeaderCell data-test="table-header-cell" key={index}>
                {header}
              </StyledUI.HeaderCell>
            )
          )}
        </StyledUI.TableRow>
        </thead>
        <tbody onMouseLeave={handleTableBodyMouseLeave}>
        {items.map((item, rowIndex) => {
          const rowProps = {
            isRowHovered: hoveredRowIndex === rowIndex,
            rowData: item
          };
          return (
            <TableRow
              key={rowIndex}
              rowProps={rowProps}
              item={item}
              headers={headers}
              rowIndex={rowIndex}
              setHoveredRowIndex={setHoveredRowIndex}
            />
          );
        })}
        </tbody>
      </StyledUI.Table>
    </StyledUI.TableWrapper>
  );
}

export default TableComponent;
