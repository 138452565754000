import * as stringHelpers from './stringHelpers';
import * as functionHelpers from './functionHelpers';

export const formatFieldErrors = functionHelpers.pipe(
  Object.entries,
  functionHelpers.map(([fieldName, errorMessage]) =>
    [stringHelpers.camelCaseToSpaces(fieldName), stringHelpers.uncapitalize(errorMessage)].join(' - ')
  ),
  strings => strings.join(', '),
  stringHelpers.capitalize
);
