import styled from 'styled-components';

// Images
import bgImageTopLeft from '../../img/bg-left-top.png';
import bgImageRightBottom from '../../img/bg-right-bottom.png';

// Theme
import { breakpoints } from '@theme/breakpoints';
import * as colors from '@theme/colors';
import { mainGradient } from '@theme/colors';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 200px 0;
  flex-direction: column;
  color: ${colors.white};

  background-image: url(${bgImageTopLeft}), url(${bgImageRightBottom}),
    linear-gradient(
      to left,
      ${mainGradient.darkViolet},
      ${mainGradient.lighterViolet},
      ${mainGradient.purpleViolet},
      ${mainGradient.purple}
    );
  background-position: left top, right bottom, center center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 639px 650px, 560px 663px, 100% 100%;

  color: ${colors.black};
  font-family: 'Roboto', sans-serif;

  @media (max-width: ${breakpoints.md}px) {
    background-size: 539px 450px, 460px 463px, 100% 100%;
    padding: 140px 0;
  }

  @media (max-width: ${breakpoints.sm}px) {
    background-size: 339px 250px, 260px 263px, 100% 100%;
  }
`;
