import styled from 'styled-components';

// Theme
import * as colors from '@theme/colors';

export const List = styled.ul`
  margin: 0;
  padding: 0 0 0 20px;
`;

export const ListItem = styled.li`
  font-size: 14px;
  color: ${colors.gray};
  margin-bottom: 5px;

  ${({ color }) => color && `color: ${colors[color]};`}
`;
