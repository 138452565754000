import { Decimal } from 'decimal.js';

export const EVENTS_PAGE_DEFAULT_LIMIT = 6;
export const USERS_PAGE_DEFAULT_LIMIT = 6;
export const TRANSACTIONS_PAGE_DEFAULT_LIMIT = 6;
export const INDIVIDUAL_LIMITS_PAGE = 6;

export const CURRENCY_DELIMITER = ' ';
export const CURRENCY_DELIMITER_PLACES = 3;

export const MIN_FEE_IN_EEX = '0';
export const MAX_FEE_IN_EEX = '0.5';

export const MIN_FEE_IN_PERCENTS = '0';
export const MAX_FEE_IN_PERCENTS = '10';

export const MIN_EEX_LIMIT_USD = '0';
export const MAX_EEX_LIMIT_USD = '10000000';

export const MIN_8_CHARACTERS = 'Min 8 characters';
export const AT_LEAST_ONE_SPECIAL_CHARACTER = 'At least one special character (e.g. *, _, &)';
export const AT_LEAST_ONE_NUMBER = 'At least one number';
export const EMPTY_SPACES_ARE_NOT_ALLOWED = 'Empty spaces are not allowed';
export const NEW_PASSWORD_IS_REQUIRED = 'New password field is required';
export const CURRENT_PASSWORD_IS_REQUIRED = 'Current password field is required';
export const MIN_PASSWORD_LENGTH = 8;

export const RESTORE_PASSWORD_FORM_STEP_1 = 1;
export const RESTORE_PASSWORD_FORM_STEP_2 = 2;

export const PAGINATION_DOUBLE_STEP_PAGES_AMOUNT = 5;
export const PAGINATION_STEP_PAGES_AMOUNT = 1;
export const PAGINATION_VISIBLE_PAGES = 5;
export const DIFFERENCE_BETWEEN_CENTER_AND_OUTSIDE_PAGES = 2;
export const INITIAL_PAGE_TO_START_SET_CENTER_PAGE = 2;
export const FIRST_PAGE = 1;
export const INITIAL_PAGE_TO_START_SET_CENTER_PAGE_FOR_PREV_DIRECTION = 3;
export const INITIAL_CENTER_PAGE = 3;

export const FIRST_STEP = 1;
export const SECOND_STEP = 2;

export const CURRENCIES = {
  EEX: 'EEX',
  BTC: 'BTC',
  ETH: 'ETH'
};

export const SATOSHI_TO_BTC_RATE_DECIMAL = new Decimal('0.00000001');

export const SUCCESS_MESSAGE_DELAY_MS = 2000;

export const FETCH_MIN_DELAY_MS = 0;

export const REPORT_STATUSES = {
  IDLE: 'IDLE',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

export const API_ERRORS = {
  NOT_AUTHENTICATED: 'NotAuthenticated'
};
