import React from 'react';
import UserNavMenu from './UserNavMenu';
import * as actions from '@actions/auth';

// Custom hooks
import { useShallowSelector, useActions } from '@helpers/hooks';

// Selectors
import * as authSelectors from '@reducers/auth';

export default function ConnectedUserNavMenu() {
  const user = useShallowSelector(authSelectors.userSelector);

  const { signOut } = useActions(actions);

  const props = {
    user,
    signOut
  };

  return <UserNavMenu {...props} />;
}
