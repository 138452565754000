import styled from 'styled-components';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Components
import { Label as UILabel, Button as UIButton } from '../UI';

export const Label = styled(UILabel)`
  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Button = styled(UIButton)`
  position: relative;
  @media (max-width: ${breakpoints.lg}px) {
    width: 34%;
  }
  @media (max-width: ${breakpoints.md}px) {
    width: 36%;
  }
`;
