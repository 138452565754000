// Absolute
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Logo from '../../Logo';

// Styles
import { Container } from './styles';
import { LogoAbsolute, LogoImg, LogoDescription } from '../../UI/Logo';

UnauthenticatedAppLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default function UnauthenticatedAppLayout({ children }) {
  return (
    <>
      <Container>
        <LogoAbsolute>
          <LogoImg>
            <Logo />
          </LogoImg>
          <LogoDescription>
            Ethereum <br />
            Express <br />
            Coin
          </LogoDescription>
        </LogoAbsolute>

        {children}
      </Container>
    </>
  );
}
