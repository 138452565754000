import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const InputGroupsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 40px;

  @media (min-width: ${breakpoints.md}px) {
    & > *:not(:first-child) {
      margin-left: 48px;
    }
  }

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;

    & > *:not(:first-child) {
      margin-top: 16px;
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > *:not(:first-child) {
    margin-top: 16px;
  }
`;

export const Info = styled.div`
  margin-top: 30px;
`;
