import styled from 'styled-components';

import * as StyledUI from '../UI';

export const StyledButton = styled(StyledUI.Button)`
  width: auto;
  padding: 0px 30px;
  background: #441eda;
  color: white;
  text-transform: none;
`;