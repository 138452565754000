// Absolute imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PropTypesAirbnb from 'airbnb-prop-types';

// Components
import Eye from '../Eye';
import Loading from '../Loading/Loading';

// Styles
import { List, ListItem } from './styles';
import { Label, Input, IconWrapper, Error, FormButton as Button } from '../UI';

// Utils
import { validatePassword } from '@helpers/validate';

// Constants
import {
  MIN_8_CHARACTERS,
  AT_LEAST_ONE_SPECIAL_CHARACTER,
  AT_LEAST_ONE_NUMBER,
  EMPTY_SPACES_ARE_NOT_ALLOWED
} from '@constants';

// Theme
import * as colors from '@theme/colors';

PasswordValidation.propTypes = {
  submit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired
};

export default function PasswordValidation({ submit, isLoading, error }) {
  const [{ password, passwordError, isPasswordHidden, validated, isBtnDisabled }, setState] = useState({
    password: '',
    passwordError: '',
    isPasswordHidden: true,
    validated: false,
    isBtnDisabled: true
  });

  const [validationErrors, setValidationErrors] = useState({
    [MIN_8_CHARACTERS]: true,
    [AT_LEAST_ONE_NUMBER]: true,
    [AT_LEAST_ONE_SPECIAL_CHARACTER]: true,
    [EMPTY_SPACES_ARE_NOT_ALLOWED]: true
  });

  const validatePass = password => {
    const passwordErrors = validatePassword(password);
    setValidationErrors(passwordErrors);

    return passwordErrors;
  };

  const handleChange = event => {
    const { value } = event.target;

    const passwordErrors = validatePass(value);

    if (passwordErrors[EMPTY_SPACES_ARE_NOT_ALLOWED]) {
      return;
    }

    if (Object.keys(passwordErrors).length) {
      setState(values => ({
        ...values,
        isBtnDisabled: true,
        validated: true,
        password: value
      }));
      return;
    }

    setState(values => ({
      ...values,
      isBtnDisabled: false,
      validated: true,
      password: value
    }));
  };

  const togglePasswordVisibility = () => {
    setState(values => ({
      ...values,
      isPasswordHidden: !isPasswordHidden
    }));
  };

  const handleSubmit = event => {
    submit(event, password);

    setState(values => ({
      ...values,
      password: ''
    }));
  };

  return (
    <>
      <Label>
        Password <br />
        <Input
          value={password}
          onChange={handleChange}
          type={isPasswordHidden ? 'password' : 'text'}
          name="password"
          placeholder="Enter password"
          error={passwordError || error}
          data-test="password"
        />
        <IconWrapper data-test="password-eye" onClick={togglePasswordVisibility}>
          <Eye color={isPasswordHidden ? colors.gray : colors.violet} />
        </IconWrapper>
      </Label>

      {error && <Error data-test="password-error">{error}</Error>}

      <List>
        <ListItem
          color={!validated ? 'light' : validationErrors[MIN_8_CHARACTERS] ? 'error' : 'success'}
          data-test="min-8-characters"
        >
          {MIN_8_CHARACTERS}
        </ListItem>
        <ListItem
          color={!validated ? 'light' : validationErrors[AT_LEAST_ONE_NUMBER] ? 'error' : 'success'}
          data-test="one-number"
        >
          {AT_LEAST_ONE_NUMBER}
        </ListItem>
        <ListItem
          color={!validated ? 'light' : validationErrors[AT_LEAST_ONE_SPECIAL_CHARACTER] ? 'error' : 'success'}
          data-test="one-special-character"
        >
          {AT_LEAST_ONE_SPECIAL_CHARACTER}
        </ListItem>
      </List>
      <Button
        disabled={isBtnDisabled || isLoading}
        background={(isBtnDisabled || isLoading) && colors.theLightestViolet}
        onClick={handleSubmit}
        data-test="proceed"
      >
        {isLoading ? <Loading /> : <span>Proceed</span>}
      </Button>
    </>
  );
}
