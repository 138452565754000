/**
 * Absolute imports
 */
import styled from "styled-components/macro";

/**
 * Theme
 */
import { breakpoints } from "@theme/breakpoints";

export const Row = styled.div`
  display: flex;

  ${({ justify }) => justify && `justify-content: ${justify};`}

  ${({ align }) => align && `align-items: ${align};`}

  ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}

  ${({ padding }) => padding && `padding: ${padding};`}


  @media (max-width: ${breakpoints.md}px) {
    padding: 0;
    flex-wrap: wrap;
  }
`;
