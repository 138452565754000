import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PropTypesAirbnb from 'airbnb-prop-types';

// Styles
import { FormWrapper, H2 } from '../UI/';

// Components
import PasswordValidation from '../PasswordValidation';

// Theme
import * as colors from '@theme/colors';

CreatePassword.propTypes = {
  submit: PropTypes.func.isRequired,
  createNewPasswordError: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired,
  createPasswordAttempt: PropTypes.number.isRequired,
  resetPasswordToken: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired,
  isNewPasswordCreated: PropTypes.bool.isRequired
};

export default function CreatePassword({
  submit,
  createNewPasswordError,
  createPasswordAttempt,
  resetPasswordToken,
  isNewPasswordCreated
}) {
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (createNewPasswordError) {
      setisLoading(false);
      setError(createNewPasswordError);
    }
  }, [createNewPasswordError, createPasswordAttempt]);

  useEffect(() => {
    if (isNewPasswordCreated) {
      setisLoading(false);
    }
  }, [isNewPasswordCreated]);

  const handleSubmit = (event, password) => {
    event.preventDefault();

    setisLoading(true);
    if (resetPasswordToken) {
      submit({
        token: resetPasswordToken,
        password
      });
    } else {
      submit(password);
    }
  };

  return (
    <>
      <FormWrapper>
        <form>
          <H2 color={colors.black} size="largest">
            Create new password
          </H2>
          <PasswordValidation submit={handleSubmit} isLoading={isLoading} error={error} />
        </form>
      </FormWrapper>
      )}
    </>
  );
}
