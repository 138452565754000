import styled from 'styled-components';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

const sizes = {
  sm: { width: '49px', fontSize: '14px', lineHeight: '16px' }
};

export const LogoAbsolute = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 60px;
  display: flex;
  justify-content: center;
  width: 40%;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ borderRight }) => borderRight && `border-right: ${borderRight}`};
`;

export const LogoImg = styled.div`
  width: 60px;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 5px;
  margin: 5px 15px 5px 0;

  ${({ size }) => size && `width: ${sizes[size].width}`};

  @media (max-width: ${breakpoints.sm}px) {
    width: 54px;
    ${({ size }) => size && `width: ${sizes[size].width}`};
  }
`;

export const LogoDescription = styled.p`
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  color: ${colors.white};
  text-transform: uppercase;
  font-size: 16px;
  margin: 0;
  line-height: 22px;

  ${({ size }) => size && `font-size: ${sizes[size].fontSize}; line-height: ${sizes[size].lineHeight}`};
`;
