// @ts-check

import { takeLatest, put, select } from 'redux-saga/effects';
import * as actions from '@actions/events';
import * as authSelectors from '@reducers/auth';
import * as api from '@services/ownerAdminApi';

function* fetchEvents({ payload: { limit, skip } }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const sortBy = 'createdAt';
  const sortOrder = -1;
  const { error, result } = yield* api.fetchEvents({ accessToken, limit, skip, sortBy, sortOrder });
  if (error) {
    yield put(actions.fetchEventsFailure(error.message));
  } else {
    yield put(
      actions.fetchEventsSuccess({
        events: result.data,
        eventsCount: result.total
      })
    );
  }
}

export function* watchFetchEvents() {
  yield takeLatest(actions.FETCH_EVENTS, fetchEvents);
}
