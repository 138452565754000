// Absolute imports
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { Root, Ingredient } from './styles';

Hamburger.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Hamburger({ active, onClick }) {
  const handleClick = () => onClick && onClick(!active);

  return (
    <Root active={active} onClick={handleClick}>
      <Ingredient active={active} />
      <Ingredient active={active} />
      <Ingredient active={active} />
    </Root>
  );
}
