// Libraries
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Selectors
import * as routerSelectors from '@reducers/router';
// Constants
import * as routes from '@routes';
// Components
import FeeSettings from '../FeeSettings';
import LimitSettings from '../LimitSettings';
import TransactionHistory from '../TransactionHistory';
// Styles
import * as StyledUI from '../UI';

// Theme
import * as colors from '@theme/colors';

export default function Settings() {
  const currentRoute = useSelector(routerSelectors.pathnameSelector);
  return (
    <>
      <StyledUI.H2 color={colors.black} margin="5px 0 25px 0">
        Coin Settings
      </StyledUI.H2>
      <StyledUI.Tabs>
        <StyledUI.TabLink
          active={currentRoute === routes.FEE_SETTINGS}
          to={routes.FEE_SETTINGS}
          data-test="fee-settings-tab-link"
        >
          Fee Settings
        </StyledUI.TabLink>
        <StyledUI.TabLink
          active={currentRoute === routes.LIMIT_SETTINGS}
          to={routes.LIMIT_SETTINGS}
          data-test="limit-settings-tab-link"
        >
          Limits
        </StyledUI.TabLink>
        <StyledUI.TabLink
          active={currentRoute === routes.TRANSACTION_HISTORY}
          to={routes.TRANSACTION_HISTORY}
          data-test="transaction-history-tab-link"
        >
          Transaction History
        </StyledUI.TabLink>
        <StyledUI.TabSpacer />
      </StyledUI.Tabs>
      <Switch>
        <Redirect exact from={routes.SETTINGS} to={routes.FEE_SETTINGS} />
        <Route exact path={routes.FEE_SETTINGS} component={FeeSettings} />
        <Route exact path={routes.LIMIT_SETTINGS} component={LimitSettings} />
        <Route exact path={routes.TRANSACTION_HISTORY} component={TransactionHistory} />
      </Switch>
    </>
  );
}
