// @ts-check

import { createAction } from 'redux-actions';

export const FETCH_BALANCES = 'FETCH_BALANCES';
export const FETCH_BALANCES_SUCCESS = 'FETCH_BALANCES_SUCCESS';
export const FETCH_BALANCES_FAILURE = 'FETCH_BALANCES_FAILURE';

export const fetchBalances = createAction(FETCH_BALANCES);
export const fetchBalancesSuccess = createAction(FETCH_BALANCES_SUCCESS);
export const fetchBalancesFailure = createAction(FETCH_BALANCES_FAILURE);

export const FETCH_REPORT = 'FETCH_REPORT';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';

export const fetchReport = createAction(FETCH_REPORT);
export const fetchReportSuccess = createAction(FETCH_REPORT_SUCCESS);
export const fetchReportFailure = createAction(FETCH_REPORT_FAILURE);

export const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA';

export const clearReportData = createAction(CLEAR_REPORT_DATA);
