export const getOrdersUrlByParams = (pathname, params) => {
  let url = `${pathname}?$limit=${params.limit}&$skip=${params.skip}`;

  if (params.sortOrder && params.sortField) {
    url += `&$sort[${params.sortField}]=${params.sortOrder}`;
  }

  if (params.status) {
    if(Array.isArray(params.status)) {
      params.status.forEach((status) => {
        url += `&status=${status}`;
      });
    } else {
      url += `&status=${params.status}`;
    }
  }

  if (params.pair) {
    url += `&pair=${params.pair}`;
  }

  if (params.createdAt) {
    const [start, end] = params.createdAt;
    url += `&createdAt[$gte]=${parseInt(start)}&createdAt[$lte]=${parseInt(end)}`;
  }

  if (params.userId) {
    url += `&userId=${params.userId}`;
  }

  if (params.email) {
    url += `&email=${params.email}`;
  }

  if (params.tokens) {
    url += `&tokens=${params.tokens}`;
  }

  if (params.funds) {
    url += `&funds=${params.funds}&status=processed&status=rejected`;
  }

  if (params.tokensExpected) {
    url += `&tokensExpected=${params.tokensExpected}`;
  }

  if (params.fundsExpected) {
    url += `&fundsExpected=${params.fundsExpected}`;
  }

  if (params.sender) {
    url += `&sender=${params.sender}`;
  }

  if (params.beneficiary) {
    url += `&beneficiary=${params.beneficiary}`;
  }

  return url;
}