// Absolute imports
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { Root, ButtonsWrapper } from './styles';
import { NavLink } from '@components/UI';

// Components
import UserNavMenu from '@components/UserNavMenu/ConnectedUserNavMenu';

// Routes
import * as routes from '@routes';

Navigation.propTypes = {
  location: PropTypes.object.isRequired
};

export default function Navigation({ location }) {
  return (
    <Root>
      <ButtonsWrapper>
      <NavLink
          to={routes.PAYMENTS}
          isActive={routes.match(location.pathname, routes.PAYMENTS)}
          data-test="header-to-payments-link"
        >
          Payments
        </NavLink>
        <NavLink
          to={routes.EVENTS}
          isActive={routes.match(location.pathname, routes.EVENTS)}
          data-test="header-to-events-link"
        >
          Events
        </NavLink>
        <NavLink
          to={routes.USERS}
          isActive={routes.match(location.pathname, routes.USERS)}
          data-test="header-to-users-link"
        >
          Users
        </NavLink>
        <NavLink
          to={routes.SETTINGS}
          isActive={routes.match(location.pathname, routes.SETTINGS)}
          data-test="header-to-settings-link"
        >
          Settings
        </NavLink>
        <NavLink
          to={routes.FINANCE}
          isActive={routes.match(location.pathname, routes.FINANCE)}
          data-test="header-to-finance-link"
        >
          Finance
        </NavLink>
      </ButtonsWrapper>
      <UserNavMenu />
    </Root>
  );
}
