import { combineReducers } from 'redux';
import routerReducer from './router';
import authReducer from './auth';
import eventsReducer from './events';
import paymentsReducer from './payments';
import usersReducer from './users';
import financeReducer from './finance';
import settingsReducer from './settings';

export const createRootReducer = history =>
  combineReducers({
    router: routerReducer(history),
    auth: authReducer,
    events: eventsReducer,
    users: usersReducer,
    finance: financeReducer,
    settings: settingsReducer,
    payment: paymentsReducer
  });
