// Absolute
import App from './App';
import React from 'react';

// Selectors
import * as authSelectors from '@reducers/auth';

// Custom hooks
import { useShallowSelector } from '@helpers/hooks';

export default function ConnectedApp() {
  const isAuthenticated = useShallowSelector(authSelectors.isAuthenticatedSelector);
  const shouldCreatePassword = useShallowSelector(authSelectors.shouldCreatePasswordSelector);

  const props = {
    isAuthenticated,
    shouldCreatePassword
  };

  return <App {...props} />;
}
