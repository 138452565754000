import styled from 'styled-components';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const PageContainer = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 40px;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 40px 20px;
  }
`;
