import styled from 'styled-components';

// Theme
import * as colors from '@theme/colors';

export const Line = styled.div`
  background: rgba(255, 255, 255, 0.4);
  height: 1px;
  flex-shrink: 0;

  ${({ width }) => width && `width: ${width}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ color }) => color && `background: ${colors[color]}`};
`;
