// @ts-check

// Libraries
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import { Decimal } from 'decimal.js';
// Selectors
import * as authSelectors from '@reducers/auth';
import * as financeSelectors from '@reducers/finance';
// Actions
import * as financeActions from '@actions/finance';
// Services
import * as blockchainInfoApi from '@services/blockchainInfoApi';
import * as ethplorerApi from '@services/ethplorerApi';
import * as explorerApi from '@services/explorerApi';
import * as accountingApi from '@services/accountingApi';
// Helpers
import * as objectHelpers from '@helpers/objectHelpers';
import * as arrayHelpers from '@helpers/arrayHelpers';
import { mapResult } from '@services/api';
// Constants
import * as constants from '@constants';

function* getWalletBalance({ currency, address }) {
  switch (currency) {
    case constants.CURRENCIES.EEX:
      const eexResponse = yield call(explorerApi.getWalletInfo, address, { limit: 1 });
      return mapResult(result => result.balance)(eexResponse);
    case constants.CURRENCIES.BTC:
      const btcResponse = yield call(blockchainInfoApi.getBtcBalance, address);
      return mapResult(result => new Decimal(result).mul(constants.SATOSHI_TO_BTC_RATE_DECIMAL))(btcResponse);
    case constants.CURRENCIES.ETH:
      const ethResponse = yield call(ethplorerApi.getAddressInfo, address);
      return mapResult(result => result.ETH.balance)(ethResponse);
    default:
      throw new Error(`sagas/finance/fetchBalances: don't know how to handle ${currency} currency`);
  }
}

function* fetchBalances() {
  const wallets = yield select(financeSelectors.walletsSelector);
  const calls = yield all(wallets.map(wallet => call(getWalletBalance, wallet)));
  const errors = calls.map(call => call.error).filter(value => Boolean(value));
  if (errors.length > 0) {
    const error = { message: errors.map(error => error.message).join(', ') };
    yield put(financeActions.fetchBalancesFailure(error));
  } else {
    const addresses = wallets.map(wallet => wallet.address);
    const balances = calls.map(call => call.result);
    const result = objectHelpers.fromEntries(arrayHelpers.zip(addresses, balances));
    yield put(financeActions.fetchBalancesSuccess(result));
  }
}

export function* watchFetchBalances() {
  yield takeLatest(financeActions.FETCH_BALANCES, fetchBalances);
}

function* fetchReport() {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { error, result } = yield call(accountingApi.fetchEvents, { accessToken });
  if (error) {
    yield put(financeActions.fetchReportFailure(error));
  } else {
    yield put(financeActions.fetchReportSuccess(result));
  }
}

export function* watchFetchReport() {
  yield takeLatest(financeActions.FETCH_REPORT, fetchReport);
}
