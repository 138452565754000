import { createAction } from 'redux-actions';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const RESET_IS_USER_DELETED = 'RESET_IS_USER_DELETED';
export const RESET_DELETE_USER_ERROR = 'RESET_DELETE_USER_ERROR';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
export const INVITE_USER = 'INVITE_USER';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
export const RESET_IS_USER_INVITED = 'RESET_IS_USER_INVITED';
export const RESET_INVITE_USER_ERROR = 'RESET_INVITE_USER_ERROR';

export const fetchUsers = createAction(FETCH_USERS);

export const fetchUsersSuccess = createAction(FETCH_USERS_SUCCESS);

export const fetchUsersFailure = createAction(FETCH_USERS_FAILURE);

export const deleteUser = createAction(DELETE_USER);

export const deleteUserSuccess = createAction(DELETE_USER_SUCCESS);

export const deleteUserFailure = createAction(DELETE_USER_FAILURE);

export const resetIsUserDeletedVariable = createAction(RESET_IS_USER_DELETED);

export const resetDeleteUserErrorVariable = createAction(RESET_DELETE_USER_ERROR);

export const fetchUser = createAction(FETCH_USER);

export const fetchUserSuccess = createAction(FETCH_USER_SUCCESS);

export const fetchUserFailure = createAction(FETCH_USER_FAILURE);

export const resetCurrentUser = createAction(RESET_CURRENT_USER);

export const inviteUser = createAction(INVITE_USER);

export const inviteUserSuccess = createAction(INVITE_USER_SUCCESS);

export const inviteUserFailure = createAction(INVITE_USER_FAILURE);

export const resetIsUserInvitedVariable = createAction(RESET_IS_USER_INVITED);

export const resetInviteUserErrorVariable = createAction(RESET_INVITE_USER_ERROR);
