// Libraries
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
// Helpers
import * as objectHelpers from '@helpers/objectHelpers';
// Actions
import * as actions from '@actions/finance';
// Constants
import * as constants from '@constants';

const defaultState = {
  wallets: [
    {
      name: 'Exchange Wallet (EEX)',
      currency: constants.CURRENCIES.EEX,
      balance: '0',
      address: '0x464171bd6579f2cf822e2310bc03c1d9cb30bd68'
    },
    {
      name: 'Buy BTC wallet',
      currency: constants.CURRENCIES.BTC,
      balance: '0',
      address: '1D2PQCrC131KAtzxH31wZq8KNZzwk5UBmt'
    },
    {
      name: 'Sell BTC wallet',
      currency: constants.CURRENCIES.BTC,
      balance: '0',
      address: '1Bc41qA9kTcsodBfA49ktbbZukx6R4PiNN'
    },
    {
      name: 'Buy ETH wallet',
      currency: constants.CURRENCIES.ETH,
      balance: '0',
      address: '0x9595a27ef49378b886732ae5bdd806e0e83d3d6a'
    }
  ],
  fetchBalancesPending: false,
  fetchBalancesError: null,
  reportData: null,
  fetchReportPending: false,
  fetchReportError: null
};

export default handleActions(
  {
    [actions.fetchBalances]: (state) =>
      objectHelpers.mergeDeepRight(state, {
        fetchBalancesPending: true,
        fetchBalancesError: null
      }),
    [actions.fetchBalancesSuccess]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        wallets: state.wallets.map(wallet =>
          !payload[wallet.address]
            ? wallet
            : {
                ...wallet,
                balance: payload[wallet.address]
              }
        ),
        fetchBalancesPending: false,
        fetchBalancesError: null
      }),
    [actions.fetchBalancesFailure]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        fetchBalancesPending: false,
        fetchBalancesError: payload
      }),
    [actions.fetchReport]: (state) =>
      objectHelpers.mergeDeepRight(state, {
        fetchReportPending: true,
        fetchReportError: null
      }),
    [actions.fetchReportSuccess]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        fetchReportPending: false,
        fetchReportError: null,
        reportData: payload
      }),
    [actions.fetchReportFailure]: (state, { payload }) =>
      objectHelpers.mergeDeepRight(state, {
        fetchReportPending: false,
        fetchReportError: payload
      }),
    [actions.clearReportData]: (state) =>
      objectHelpers.mergeDeepRight(state, {
        reportData: null,
        fetchReportPending: false,
        fetchReportError: null
      })
  },
  defaultState
);

export const financeSelector = state => state.finance;

export const walletsSelector = createSelector(financeSelector, finance => finance.wallets);
export const fetchBalancesPendingSelector = createSelector(financeSelector, finance => finance.fetchBalancesPending);
export const fetchBalancesErrorSelector = createSelector(financeSelector, finance => finance.fetchBalancesError);

export const reportDataSelector = createSelector(financeSelector, finance => finance.reportData);
export const fetchReportPendingSelector = createSelector(financeSelector, finance => finance.fetchReportPending);
export const fetchReportErrorSelector = createSelector(financeSelector, finance => finance.fetchReportError);
