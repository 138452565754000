import { createSelector } from 'reselect';
import { handleActions } from 'redux-actions';

export const authSelector = state => state.auth;

export const isAuthenticatedSelector = createSelector(authSelector, auth => Boolean(auth.accessToken));

export const accessTokenSelector = createSelector(authSelector, auth => auth.accessToken);

export const userSelector = createSelector(authSelector, auth => auth.user);

export const signInErrorSelector = createSelector(authSelector, auth => auth.signInError);

export const shouldCreatePasswordSelector = createSelector(authSelector, auth => !auth.isPasswordChanged);

export const isPasswordCreatedSelector = createSelector(authSelector, auth => auth.isPasswordCreated);

export const createNewPasswordErrorSelector = createSelector(authSelector, auth => auth.createNewPasswordError);

export const signInAttemptSelector = createSelector(authSelector, auth => auth.signInAttempt);

export const createPasswordAttemptSelector = createSelector(authSelector, auth => auth.createPasswordAttempt);

export const tempPasswordSelector = createSelector(authSelector, auth => auth.tempPassword);

export const resetPasswordErrorSelector = createSelector(authSelector, auth => auth.resetPasswordError);

export const isPasswordResetSelector = createSelector(authSelector, auth => auth.isPasswordReset);

export const resetPasswordAttemptSelector = createSelector(authSelector, auth => auth.resetPasswordAttempt);

export const resetPasswordTokenSelector = createSelector(authSelector, auth => auth.resetPasswordToken);

export const isNewPasswordCreatedSelector = createSelector(authSelector, auth => auth.isNewPasswordCreated);

export const createNewPasswordAfterRestoreErrorSelector = createSelector(
  authSelector,
  auth => auth.createNewPasswordAfterRestoreError
);

export const createNewPasswordAttemptSelector = createSelector(authSelector, auth => auth.createNewPasswordAttempt);

export const saveUserDataErrorSelector = createSelector(authSelector, auth => auth.saveUserDataError);

export const saveProfileUserDataErrorSelector = createSelector(authSelector, auth => auth.saveProfileUserDataError);

export const isProfileUserDataSavedSelector = createSelector(authSelector, auth => auth.isProfileUserDataSaved);

export const attemptForSaveProfileDataSelector = createSelector(authSelector, auth => auth.attemptForSaveProfileData);

export const isUploadingPhotoSelector = createSelector(authSelector, auth => auth.isUploadingPhoto);

export const isPhotoLoadedSelector = createSelector(authSelector, auth => auth.isPhotoLoaded);

export const uploadPhotoErrorSelector = createSelector(authSelector, auth => auth.uploadPhotoError);

export const fetchProfileUserErrorSelector = createSelector(authSelector, auth => auth.fetchProfileUserError);

export const isPasswordSavedSelector = createSelector(authSelector, auth => auth.isPasswordSaved);

export const savePasswordErrorSelector = createSelector(authSelector, auth => auth.savePasswordError);

export const attemptForSavePasswordSelector = createSelector(authSelector, auth => auth.attemptForSavePassword);

const defaultState = {
  accessToken: null,
  tempPassword: '',
  isPasswordChanged: false,
  user: {},
  signInError: null,
  createNewPasswordError: null,
  signInAttempt: 0,
  createPasswordAttempt: 0,
  resetPasswordError: null,
  isPasswordReset: null,
  isPasswordCreated: false,
  resetPasswordAttempt: 0,
  resetPasswordToken: null,
  createNewPasswordAfterRestoreError: null,
  createNewPasswordAttempt: 0,
  isNewPasswordCreated: false,
  saveProfileUserDataError: null,
  isProfileUserDataSaved: false,
  attemptForSaveProfileData: 0,
  isPhotoLoaded: false,
  isUploadingPhoto: false,
  uploadPhotoError: null,
  fetchProfileUserError: null,
  isPasswordSaved: false,
  savePasswordError: null,
  attemptForSavePassword: 0
};

export default handleActions(
  {
    SIGN_IN_SUCCESS: (state, { payload }) => ({
      ...state,
      accessToken: payload.accessToken,
      tempPassword: payload.isPasswordChanged ? '' : payload.password,
      isPasswordChanged: payload.isPasswordChanged,
      user: payload.user,
      signInError: null,
      isNewPasswordCreated: true
    }),
    SIGN_IN_FAILURE: (state, { payload }) => ({
      ...state,
      signInError: payload.message,
      signInAttempt: state.signInAttempt + 1
    }),
    PATCH_PASSWORD_SUCCESS: (state, { payload }) => ({
      ...state,
      tempPassword: '',
      createNewPasswordError: null,
      isPasswordChanged: payload,
      isPasswordCreated: true
    }),
    PATCH_PASSWORD_FAILURE: (state, { payload }) => ({
      ...state,
      createNewPasswordError: payload.message,
      createPasswordAttempt: state.createPasswordAttempt + 1,
      isPasswordCreated: false
    }),
    RESET_PASSWORD_FAILURE: (state, { payload }) => ({
      ...state,
      resetPasswordError: payload,
      isPasswordReset: false,
      resetPasswordAttempt: state.resetPasswordAttempt + 1
    }),
    RESET_PASSWORD_SUCCESS: (state, { payload }) => ({
      ...state,
      resetPasswordError: null,
      isPasswordReset: true
    }),
    RESET_IS_PASSWORD_RESET_VARIABLE: (state, { payload }) => ({
      ...state,
      isPasswordReset: null
    }),
    SIGN_OUT: (state, { payload }) => ({
      ...state,
      accessToken: null,
      tempPassword: '',
      isPasswordChanged: false
    }),
    SET_RESET_PASSWORD_TOKEN: (state, { payload }) => ({
      ...state,
      resetPasswordToken: payload
    }),
    CREATE_NEW_PASSWORD_FAILURE: (state, { payload }) => ({
      ...state,
      createNewPasswordAfterRestoreError: payload,
      createNewPasswordAttempt: state.createNewPasswordAttempt + 1
    }),
    CREATE_NEW_PASSWORD_SUCCESS: (state, { payload }) => ({
      ...state,
      createNewPasswordAfterRestoreError: null,
      isNewPasswordCreated: true,
      resetPasswordToken: null
    }),
    SAVE_PROFILE_USER_DATA_SUCCESS: (state, { payload }) => ({
      ...state,
      user: payload.user,
      saveProfileUserDataError: null,
      isProfileUserDataSaved: true,
      attemptForSaveProfileData: state.attemptForSaveProfileData + 1
    }),
    SAVE_PROFILE_USER_DATA_FAILURE: (state, { payload }) => ({
      ...state,
      saveUserDataError: payload,
      attemptForSaveProfileData: state.attemptForSaveProfileData + 1
    }),
    UPLOAD_PHOTO: (state, action) => ({
      ...state,
      isPhotoLoaded: false,
      isUploadingPhoto: true,
      uploadPhotoError: null
    }),
    UPLOAD_PHOTO_SUCCESS: (state, action) => ({
      ...state,
      isPhotoLoaded: true,
      isUploadingPhoto: false,
      uploadPhotoError: null
    }),
    UPLOAD_PHOTO_FAILURE: (state, { payload }) => ({
      ...state,
      isPhotoLoaded: false,
      isUploadingPhoto: false,
      uploadPhotoError: payload
    }),
    FETCH_PROFILE_USER_SUCCESS: (state, { payload: { user } }) => {
      return {
        ...state,
        user,
        fetchProfileUserError: null
      };
    },
    FETCH_PROFILE_USER_FAILURE: (state, { payload }) => ({
      ...state,
      fetchProfileUserError: payload
    }),
    RESET_IS_PHOTO_LOADED: (state, action) => ({
      ...state,
      isPhotoLoaded: false
    }),

    SAVE_USER_PASSWORD_SUCCESS: (state, action) => ({
      ...state,
      isPasswordSaved: true,
      savePasswordError: null,
      attemptForSavePassword: state.attemptForSavePassword + 1
    }),
    SAVE_USER_PASSWORD_FAILURE: (state, { payload }) => ({
      ...state,
      isPasswordSaved: false,
      savePasswordError: payload,
      attemptForSavePassword: state.attemptForSavePassword + 1
    }),
    RESET_IS_PASSWORD_SAVED: (state, action) => ({
      ...state,
      isPasswordSaved: false
    }),
    RESET_IS_PROFILE_USER_DATA_SAVED: (state, action) => ({
      ...state,
      isProfileUserDataSaved: false
    }),
    RESET_SIGN_IN_ERROR: (state, action) => ({
      ...state,
      signInError: null
    })
  },
  defaultState
);
