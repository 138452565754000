// @ts-check

import { apiCaller } from './api';

const apiCall = apiCaller(process.env.REACT_APP_ACCOUNTING_API_URL, {
  transformError: error => ({
    message: error.messages[0]
  })
});

export const fetchEvents = ({ accessToken }) =>
  apiCall(`/report`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    resultBodyType: 'text',
    minDelay: 2000
  });
