// @ts-check

export const INDEX = '/';
export const SIGN_IN = '/signin';
export const CREATE_PASSWORD = '/createpass';
export const CREATE_PASSWORD_AFTER_RESTORE = '/createnewpass';
export const SETTINGS = '/settings';
export const FEE_SETTINGS = '/settings/fee';
export const LIMIT_SETTINGS = '/settings/limits';
export const TRANSACTION_HISTORY = '/settings/history';
export const PAYMENTS = '/payments';
export const PAYMENTS_ORDER = '/payments/orders';
export const PAYMENTS_HISTORY = '/payments/history';
export const EVENTS = '/events';
export const USERS = '/users';
export const RESTORE = '/restore';
export const RESET = '/reset';
export const MY_PROFILE = '/profile';
export const USER_PROFILE = '/usersprofile';
export const FINANCE = '/finance';
export const NOT_FOUND = '/notfound';

/**
 *
 * @param {string} concreteRoute
 * @param {string} patternRoute
 * @returns {boolean}
 */
export const match = (concreteRoute, patternRoute) =>
  concreteRoute === patternRoute || concreteRoute.startsWith(`${patternRoute}/`);

/**
 *
 * @param {string} route
 * @returns {boolean}
 */
export const isExistingRoute = route =>
  match(route, INDEX) ||
  match(route, SIGN_IN) ||
  match(route, CREATE_PASSWORD) ||
  match(route, CREATE_PASSWORD_AFTER_RESTORE) ||
  match(route, SETTINGS) ||
  match(route, FEE_SETTINGS) ||
  match(route, LIMIT_SETTINGS) ||
  match(route, TRANSACTION_HISTORY) ||
  match(route, EVENTS) ||
  match(route, PAYMENTS) ||
  match(route, PAYMENTS_ORDER) ||
  match(route, PAYMENTS_HISTORY) ||
  match(route, USERS) ||
  match(route, RESTORE) ||
  match(route, RESET) ||
  match(route, MY_PROFILE) ||
  match(route, USER_PROFILE) ||
  match(route, FINANCE) ||
  match(route, NOT_FOUND);

/**
 *
 * @param {string} route
 */
export const isAuthRoute = route =>
  match(route, SIGN_IN) ||
  match(route, RESTORE) ||
  match(route, CREATE_PASSWORD) ||
  match(route, CREATE_PASSWORD_AFTER_RESTORE);
