import styled from 'styled-components';

const sizes = {
  sm: 'padding: 10px;',
  lg: 'width: 100px; height: 100px;'
};

export const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${({ size }) => size && `${sizes[size]};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}

`;
