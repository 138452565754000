// Absolute
import React from 'react';
import * as actions from '@actions/auth';

import SignIn from './SignIn';

// Custom hooks
import { useActions, useShallowSelector } from '@helpers/hooks';

// Selectors
import * as authSelectors from '@reducers/auth';

export default function ConnectedSignIn({ location }) {
  const signInError = useShallowSelector(authSelectors.signInErrorSelector);
  const signInAttempt = useShallowSelector(authSelectors.signInAttemptSelector);

  const { signIn, resetSignInError } = useActions(actions);

  const props = {
    signIn,
    location,
    signInError,
    signInAttempt,
    resetSignInError
  };

  return <SignIn {...props} />;
}
