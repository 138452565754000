export const camelCaseToSpaces = string =>
  string
    // insert a space between lower & upper
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    // space before last upper in a sequence followed by lower
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    // to lowercase
    .toLowerCase();

export const capitalize = string => (string === '' ? string : string[0].toUpperCase() + string.slice(1));

export const uncapitalize = string => (string === '' ? string : string[0].toLowerCase() + string.slice(1));

export const isNotEmpty = string => string.length > 0;

export const splitAt = index => string =>
  index >= string.length ? [string] : [string.slice(0, index), string.slice(index)];

export const formatWithDigitDelimiter = delimiter => places => value => {
  const reversedChars = value
    .toString()
    .split('')
    .reverse();
  const result = reversedChars.slice(0, places);
  for (let i = places; i < reversedChars.length; i += places) {
    result.push(delimiter);
    result.push(...reversedChars.slice(i, i + places));
  }
  return result.reverse().join('');
};
