import React from 'react';
import MobileNavigation from './MobileNavigation';
import * as actions from '@actions/auth';

// Custom hooks
import { useShallowSelector, useActions } from '@helpers/hooks';
import * as authSelectors from '@reducers/auth';

export default function ConnectedMobileNavigation({ active, handleToggleMenu }) {
  const isAuthenticated = useShallowSelector(authSelectors.isAuthenticatedSelector);
  const { signOut } = useActions(actions);

  const props = {
    signOut,
    active,
    handleToggleMenu,
    isAuthenticated
  };

  return <MobileNavigation {...props} />;
}
