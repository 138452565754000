import { takeLatest, put, select } from 'redux-saga/effects';
import * as authSelectors from '@reducers/auth';
import * as actions from '@actions/users';
import * as api from '@services/ownerAdminApi';

function* fetchUsers({ payload: { limit, skip } }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const sortBy = 'createdAt';
  const sortOrder = -1;
  const { error, result } = yield* api.fetchUsers({ accessToken, limit, skip, sortBy, sortOrder });
  if (error) {
    yield put(actions.fetchUsersFailure(error.message));
  } else {
    yield put(
      actions.fetchUsersSuccess({
        users: result.data,
        usersCount: result.total
      })
    );
  }
}

export function* watchFetchUsers() {
  yield takeLatest(actions.FETCH_USERS, fetchUsers);
}

function* deleteUser({ payload: { email } }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);

  const { error } = yield* api.deleteUser({ accessToken, email });
  if (error) {
    yield put(actions.deleteUserFailure(error.message));
  } else {
    yield put(actions.deleteUserSuccess());
  }
}

export function* watchDeleteUser() {
  yield takeLatest(actions.DELETE_USER, deleteUser);
}

function* fetchUser({ payload: { id } }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);

  const { error, result } = yield* api.fetchUser({ accessToken, id });
  if (error) {
    yield put(actions.fetchUserFailure(error.message));
  } else {
    yield put(
      actions.fetchUserSuccess({
        user: result.data[0]
      })
    );
  }
}

export function* watchFetchUser() {
  yield takeLatest(actions.FETCH_USER, fetchUser);
}

function* inviteUser({ payload: { email, invitationMessage } }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);

  const { error, result } = yield* api.inviteUser({ accessToken, email, invitationMessage });

  if (error) {
    yield put(actions.inviteUserFailure(error.message));
  } else {
    yield put(actions.inviteUserSuccess());
  }
}

export function* watchInviteUser() {
  yield takeLatest(actions.INVITE_USER, inviteUser);
}
