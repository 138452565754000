import styled from 'styled-components';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 0;
  }
`;

export const Form = styled.form`
  width: 44%;
  border: 1px solid ${colors.theLightestGray};
  padding: 20px;

  @media (max-width: ${breakpoints.lg}px) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 80%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    padding: 10px;
    border: none;
    padding: 0;
  }
`;
