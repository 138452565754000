import { createAction } from 'redux-actions';

export const FETCH_FEE_SETTINGS = 'FETCH_FEE_SETTINGS';
export const FETCH_FEE_SETTINGS_SUCCESS = 'FETCH_FEE_SETTINGS_SUCCESS';
export const FETCH_FEE_SETTINGS_FAILURE = 'FETCH_FEE_SETTINGS_FAILURE';

export const fetchFeeSettings = createAction(FETCH_FEE_SETTINGS);
export const fetchFeeSettingsSuccess = createAction(FETCH_FEE_SETTINGS_SUCCESS);
export const fetchFeeSettingsFailure = createAction(FETCH_FEE_SETTINGS_FAILURE);

export const UPDATE_FEE_SETTINGS = 'UPDATE_FEE_SETTINGS';
export const UPDATE_FEE_SETTINGS_SUCCESS = 'UPDATE_FEE_SETTINGS_SUCCESS';
export const UPDATE_FEE_SETTINGS_FAILURE = 'UPDATE_FEE_SETTINGS_FAILURE';

export const updateFeeSettings = createAction(UPDATE_FEE_SETTINGS);
export const updateFeeSettingsSuccess = createAction(UPDATE_FEE_SETTINGS_SUCCESS);
export const updateFeeSettingsFailure = createAction(UPDATE_FEE_SETTINGS_FAILURE);

export const UPDATE_LIMIT_SETTINGS = 'UPDATE_LIMIT_SETTINGS';
export const UPDATE_LIMIT_SETTINGS_SUCCESS = 'UPDATE_LIMIT_SETTINGS_SUCCESS';
export const UPDATE_LIMIT_SETTINGS_FAILURE = 'UPDATE_LIMIT_SETTINGS_FAILURE';

export const updateLimitSettings = createAction(UPDATE_LIMIT_SETTINGS);
export const updateLimitSettingsSuccess = createAction(UPDATE_LIMIT_SETTINGS_SUCCESS);
export const updateLimitSettingsFailure = createAction(UPDATE_LIMIT_SETTINGS_FAILURE);

export const FETCH_USER_TRANSACTIONS = 'FETCH_USER_TRANSACTIONS';
export const FETCH_USER_TRANSACTIONS_SUCCESS = 'FETCH_USER_TRANSACTIONS_SUCCESS';
export const FETCH_USER_TRANSACTIONS_FAILURE = 'FETCH_USER_TRANSACTIONS_FAILURE';

export const fetchUserTransactions = createAction(FETCH_USER_TRANSACTIONS);
export const fetchUserTransactionsSuccess = createAction(FETCH_USER_TRANSACTIONS_SUCCESS);
export const fetchUserTransactionsFailure = createAction(FETCH_USER_TRANSACTIONS_FAILURE);

export const FETCH_GENERAL_LIMITS = 'FETCH_GENERAL_LIMITS';
export const FETCH_GENERAL_LIMITS_SUCCESS = 'FETCH_GENERAL_LIMITS_SUCCESS';
export const FETCH_GENERAL_LIMITS_FAILURE = 'FETCH_GENERAL_LIMITS_FAILURE';

export const fetchGeneralLimits = createAction(FETCH_GENERAL_LIMITS);
export const fetchGeneralLimitsSuccess = createAction(FETCH_GENERAL_LIMITS_SUCCESS);
export const fetchGeneralLimitsFailure = createAction(FETCH_GENERAL_LIMITS_FAILURE);

export const FETCH_INDIVIDUAL_LIMITS = 'FETCH_INDIVIDUAL_LIMITS';
export const FETCH_INDIVIDUAL_LIMITS_SUCCESS = 'FETCH_INDIVIDUAL_LIMITS_SUCCESS';
export const FETCH_INDIVIDUAL_LIMITS_FAILURE = 'FETCH_INDIVIDUAL_LIMITS_FAILURE';

export const fetchIndividualLimits = createAction(FETCH_INDIVIDUAL_LIMITS);
export const fetchIndividualLimitsSuccess = createAction(FETCH_INDIVIDUAL_LIMITS_SUCCESS);
export const fetchIndividualLimitsFailure = createAction(FETCH_INDIVIDUAL_LIMITS_FAILURE);

export const REMOVE_INDIVIDUAL_LIMIT = 'REMOVE_INDIVIDUAL_LIMIT';
export const REMOVE_INDIVIDUAL_LIMIT_SUCCESS = 'REMOVE_INDIVIDUAL_LIMIT_SUCCESS';
export const REMOVE_INDIVIDUAL_LIMIT_FAILURE = 'REMOVE_INDIVIDUAL_LIMIT_FAILURE';

export const removeIndividualLimit = createAction(REMOVE_INDIVIDUAL_LIMIT);
export const removeIndividualLimitSuccess = createAction(REMOVE_INDIVIDUAL_LIMIT_SUCCESS);
export const removeIndividualLimitFailure = createAction(REMOVE_INDIVIDUAL_LIMIT_FAILURE);

export const SET_INDIVIDUAL_LIMIT = 'SET_INDIVIDUAL_LIMIT';
export const SET_INDIVIDUAL_LIMIT_SUCCESS = 'SET_INDIVIDUAL_LIMIT_SUCCESS';
export const SET_INDIVIDUAL_LIMIT_FAILURE = 'SET_INDIVIDUAL_LIMIT_FAILURE';

export const setIndividualLimit = createAction(SET_INDIVIDUAL_LIMIT);
export const setIndividualLimitSuccess = createAction(SET_INDIVIDUAL_LIMIT_SUCCESS);
export const setIndividualLimitFailure = createAction(SET_INDIVIDUAL_LIMIT_FAILURE);

export const SET_GENERAL_LIMIT = 'SET_GENERAL_LIMIT';
export const SET_GENERAL_LIMIT_SUCCESS = 'SET_GENERAL_LIMIT_SUCCESS';
export const SET_GENERAL_LIMIT_FAILURE = 'SET_GENERAL_LIMIT_FAILURE';

export const setGeneralLimit = createAction(SET_GENERAL_LIMIT);
export const setGeneralLimitSuccess = createAction(SET_GENERAL_LIMIT_SUCCESS);
export const setGeneralLimitFailure = createAction(SET_GENERAL_LIMIT_FAILURE);
