import * as actions from '@actions/payments';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const defaultState = {
  orders: [],
  ordersCount: 0,
  ordersLoading: false,
  approvingOrder: null,
  userOrdersLoading: false,
  userOrders: [],
  userOrdersCount: 0,
  fetchUserOrdersError: null,
  fetchOrdersError: null,
  payoutInfo: {
    loading: null,
    data: null,
    fetchPayoutInfoError: null,
    fetchPayoutInfoWalletError: null,
  },
};

export const paymentSelector = state => state.payment;

export const ordersSelector = createSelector(paymentSelector, state => state.orders);

export const ordersCountSelector = createSelector(paymentSelector, state => state.ordersCount || 0);

export const approvingOrderSelector = createSelector(paymentSelector, state => state.approvingOrder);

export const userOrdersLoadingSelector = createSelector(paymentSelector, state => state.userOrdersLoading);

export const ordersLoadingSelector = createSelector(paymentSelector, state => state.ordersLoading);

export const userOrdersSelector = createSelector(paymentSelector, state => state.userOrders);

export const userOrdersCountSelector = createSelector(paymentSelector, state => state.userOrdersCount || 0);

export const fetchOrdersErrorSelector = createSelector(paymentSelector, state => state.fetchOrdersError);

export const payoutInfoSelector = createSelector(paymentSelector, state => state.payoutInfo);

export const payoutInfoDataSelector = createSelector(payoutInfoSelector, payoutInfo => payoutInfo.data)

export const payoutInfoLoadingSelector = createSelector(payoutInfoSelector, payoutInfo => payoutInfo.loading)

export const payoutInfoErrorSelector = createSelector(payoutInfoSelector, payoutInfo => payoutInfo.fetchPayoutInfoError)

export const payoutInfoWalletErrorSelector = createSelector(payoutInfoSelector, state => state.fetchPayoutInfoWalletError);

export default handleActions(
  {
    [actions.fetchUserTransactions]: state => ({
      ...state,
      userOrdersLoading: true
    }),
    [actions.fetchPaymentOrders]: state => ({
      ...state,
      ordersLoading: true,
    }),
    [actions.fetchPaymentOrdersSuccess]: (state, { payload: { orders, ordersCount } }) => ({
      ...state,
      ordersLoading: false,
      orders,
      ordersCount
    }),
    [actions.approveOrder]: (state, { payload: { transactionId } }) => ({
      ...state,
      approvingOrder: transactionId
    }),
    [actions.approveOrderFailure]: (state) => ({
      ...state,
      approvingOrder: null
    }),
    [actions.approveOrderSuccess]: (state) => ({
      ...state,
      approvingOrder: null
    }),
    [actions.fetchPaymentOrdersFailure]: (state, { payload: { error } }) => ({
      ...state,
      ordersLoading: false,
      fetchOrdersError: error
    }),
    [actions.fetchUserTransactionsSuccess]: (state, { payload: { total, data } }) => ({
      ...state,
      userOrders: data,
      userOrdersLoading: false,
      userOrdersCount: total
    }),
    [actions.fetchUserTransactionsFailure]: (state, { payload: { error } }) => ({
      ...state,
      userOrdersLoading: false,
      fetchUserOrdersError: error
    }),
    [actions.fetchPayoutInfo]: (state) => ({
      ...state,
      payoutInfo: {
        ...state.payoutInfo,
        ...defaultState.payoutInfo,
        loading: true,
      }
    }),
    [actions.fetchPayoutInfoSuccess]: (state, { payload }) => ({
      ...state,
      payoutInfo: {
        ...state.payoutInfo,
        loading: false,
        data: payload,
      }
    }),
    [actions.fetchPayoutInfoFailure]: (state, { payload }) => ({
      ...state,
      payoutInfo: {
        ...state.payoutInfo,
        fetchPayoutInfoError: payload,
      },
    }),
    [actions.fetchPayoutInfoWalletFailure]: (state, { payload }) => ({
      ...state,
      payoutInfo: {
        ...state.payoutInfo,
        fetchPayoutInfoWalletError: payload,
      },
    })
  },
  defaultState
);
