import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const defaultState = {
  currentUsers: [],
  usersCount: 0,
  fetchUsersError: null,
  isUserDeleted: false,
  attemptForDeleteUser: 0,
  deleteUserError: null,
  currentUser: null,
  fetchUserError: null,
  isUserInvited: null,
  inviteUserError: null
};

export const usersSelector = state => state.users;

export const currentUsersSelector = createSelector(usersSelector, users => users.currentUsers);

export const usersCountSelector = createSelector(usersSelector, users => users.usersCount);

export const fetchUsersErrorSelector = createSelector(usersSelector, users => users.fetchUsersError);

export const isUserDeletedSelector = createSelector(usersSelector, users => users.isUserDeleted);

export const attemptForDeleteUserSelector = createSelector(usersSelector, users => users.attemptForDeleteUser);

export const deleteUserErrorSelector = createSelector(usersSelector, users => users.deleteUserError);

export const currentUserSelector = createSelector(usersSelector, users => users.currentUser);

export const fetchUserErrorSelector = createSelector(usersSelector, users => users.fetchUserError);

export const isUserInvitedSelector = createSelector(usersSelector, users => users.isUserInvited);

export const inviteUserErrorSelector = createSelector(usersSelector, users => users.inviteUserError);

export default handleActions(
  {
    FETCH_USERS_SUCCESS: (state, { payload: { users, usersCount } }) => {
      return {
        ...state,
        currentUsers: users,
        usersCount,
        fetchUsersError: null
      };
    },
    FETCH_USERS_FAILURE: (state, { payload: { error } }) => ({
      ...state,
      fetchUsersError: error
    }),
    DELETE_USER_SUCCESS: (state, action) => ({
      ...state,
      isUserDeleted: true,
      attemptForDeleteUser: state.attemptForDeleteUser + 1,
      deleteUserError: null
    }),
    DELETE_USER_FAILURE: (state, { payload }) => ({
      ...state,
      isUserDeleted: false,
      deleteUserError: payload
    }),
    RESET_IS_USER_DELETED: (state, action) => ({
      ...state,
      isUserDeleted: false
    }),
    RESET_DELETE_USER_ERROR: (state, action) => ({
      ...state,
      deleteUserError: null
    }),
    FETCH_USER_SUCCESS: (state, { payload: { user } }) => {
      return {
        ...state,
        currentUser: user,
        fetchUserError: null
      };
    },
    FETCH_USER_FAILURE: (state, { payload }) => ({
      ...state,
      fetchUserError: payload
    }),
    RESET_CURRENT_USER: (state, action) => ({
      ...state,
      currentUser: null
    }),
    INVITE_USER_SUCCESS: (state, action) => ({
      ...state,
      isUserInvited: true,
      inviteUserError: null
    }),
    INVITE_USER_FAILURE: (state, { payload }) => ({
      ...state,
      isUserInvited: false,
      inviteUserError: payload
    }),
    RESET_INVITE_USER_ERROR: (state, action) => ({
      ...state,
      inviteUserError: null
    }),
    RESET_IS_USER_INVITED: (state, action) => ({
      ...state,
      isUserInvited: null
    })
  },
  defaultState
);
