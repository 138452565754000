import React from 'react';

// Theme
import * as colors from '@theme/colors';

export default function SignOut(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={colors.white}
        d="M10 4H5C4.44771 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H10C10.5523 20 11 19.5523 11 19C11 18.4477 11.4477 18 12 18C12.5523 18 13 18.4477 13 19C13 20.6569 11.6569 22 10 22H5C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2H10C11.6569 2 13 3.34315 13 5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5C11 4.44772 10.5523 4 10 4ZM17.2 8.6C16.8686 8.15817 16.9582 7.53137 17.4 7.2C17.8418 6.86863 18.4686 6.95817 18.8 7.4L21.8 11.4C22.0667 11.7556 22.0667 12.2444 21.8 12.6L18.8 16.6C18.4686 17.0418 17.8418 17.1314 17.4 16.8C16.9582 16.4686 16.8686 15.8418 17.2 15.4L19 13H11C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11H19L17.2 8.6Z"
      />
    </svg>
  );
}
