import useQueryParams from '@helpers/hooks/useQueryParams';
import React from 'react';
import { Table as SemanticUITable, Pagination } from 'semantic-ui-react';
import LeftStroke from 'src/icons/LeftStroke';
import RightStroke from 'src/icons/RightStroke';
import SortArrows from 'src/icons/SortArrows';
import styled from 'styled-components';
import { TABLE_LIMIT } from '../../constants/index';

const SORT_STEP_MAP = {
  '0': '1',
  '1': '-1',
  '-1': '0'
};

const Table = ({ headers, items, totalCount, transformItem, pagination }) => {
  const [{ sortField, sortOrder, page, ...params }, onChangeParams ] = useQueryParams();

  const onSortChange = (key) => {
    onChangeParams({ sortField: key, sortOrder: sortField !== key ? '1' : SORT_STEP_MAP[sortOrder] });
  }

  return (
    <Table.Wrapper>
      <SemanticUITable>
        <SemanticUITable.Header>
          <SemanticUITable.Row>
            {headers.map(({ title, sorted, key, filter: FilterComponent, width }) => <SemanticUITable.HeaderCell key={key} width={width}>
              <>
                <div className={!FilterComponent ? 'without-filter' : ''}>{title} {sorted && <span onClick={() => onSortChange(key)}><SortArrows sort={sortField === key && sortOrder} /></span>}</div>
                {FilterComponent && <div><FilterComponent value={params[key]} onChange={(value) => onChangeParams({ [key]: value })} /></div>}
              </>
            </SemanticUITable.HeaderCell>)}
          </SemanticUITable.Row>
        </SemanticUITable.Header>
        <SemanticUITable.Body>
          {items.map((item) => <SemanticUITable.Row key={item?._id}>
            {transformItem(item).map(({ title, width }, index) => <SemanticUITable.Cell width={width} key={index} data-title={headers[index].title}><div className="title">{title}</div></SemanticUITable.Cell>)}
          </SemanticUITable.Row>)}
          {!items.length && <Table.NoResults className="no-result">
            <td>
              No results
            </td>
          </Table.NoResults>}
        </SemanticUITable.Body>
        {Boolean(items.length) && pagination && <SemanticUITable.Footer>
          <SemanticUITable.Row>
            <SemanticUITable.HeaderCell colSpan='4'>
              <Pagination
                {...pagination}
                activePage={parseInt(page)}
                onPageChange={(_, { activePage }) => onChangeParams({ page: activePage })}
                firstItem={null}
                nextItem={{
                  content: '',
                  icon: (
                    <RightStroke />
                  )
                }}
                lastItem={null}
                prevItem={{
                  content: '',
                  icon: (
                    <LeftStroke />
                  )
                }}
                pointing
                secondary
              />
            </SemanticUITable.HeaderCell>
            <SemanticUITable.HeaderCell textAlign="right" colSpan='8'>
              <Table.ResultsWrapper>
                <span>
                  Results: <b>{(page * TABLE_LIMIT) - TABLE_LIMIT + items.length} / {totalCount}</b>
                </span>
              </Table.ResultsWrapper>
            </SemanticUITable.HeaderCell>
          </SemanticUITable.Row>
        </SemanticUITable.Footer>}
      </SemanticUITable>
    </Table.Wrapper>
  )
};

Table.Wrapper = styled.div`
  overflow-x: auto;
  overflow-y: unset;
  
  th {
    background-color: #fff !important;
  }

  thead {
    position: relative;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03) !important;
  }

  .table thead th {
    vertical-align: baseline !important;
    color: #898989 !important;
    border-top: none !important;
    border-bottom: none !important;
  }

  tbody > tr > td {
    border-top: none !important;
    border-right: 1px solid #DBDBDB !important;
    color: #2C2A32 !important;
    white-space: nowrap;

    &:last-child {
      border-right: none !important;
    }
  }

  tbody > tr:nth-child(odd):is(:not(.no-result)) {
    background-color: #F8F6FF;
  }
  
  thead > tr th div.without-filter {
    @media (max-width: 767px) {
      display: none;
    }
  }
  
  tbody tr:hover button.copy {
    opacity: 1;
  }

  .ui.table td {
    padding: 14px 12px !important;
  }

  .ui.table thead th {
    padding: 14px 12px !important;
  }

  .table thead td, .table tbody th {
    border-top: none !important;
  }

  tfoot {
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.03) !important;

    th {
      padding: 0 !important;
      border-top: none !important;
    }

    .ui.secondary.pointing.menu {
      margin-left: 25px;
      border-bottom: none;
    }

    .item {
      min-width: 0 !important;
      padding: 12px 10px !important;
      color: #441EDA !important;
    }

    .active.item, .active.item:active, .item:active {
      color: #A8A5B4 !important;
      border-color: transparent !important;
    }

    .icon.item {
      height: 40px;
    }
  }

  a {
    color: #441EDA;
  }

  @media (max-width: 767px) {

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    td {
      position: relative;
      
      div.title {
        width: 65%;
        padding: 0 5px;
        margin-left: auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: break-spaces;
        
        span {
          width: 100%;
          min-height: 21px;
        }
      }
    }

    td:before {
      content: attr(data-title);
      position: absolute;
      top: 6px;
      left: 6px;
      width: 35%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }
  }
`;

Table.ResultsWrapper = styled.div`
  margin-right: 32px;
  font-size: 12px;
    
  span {
    color: #A8A5B4;
  }

  b {
    color: #000;
    font-weight: bold;
  }
`;

Table.NoResults = styled.tr`
  height: 300px;
  font-size: 25px;
  position: relative;
  
  & > td {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
  }
`;

export default Table;