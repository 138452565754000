// Absolute
import React from 'react';
import PropTypes from 'prop-types';

// Components
import NavBar from '../../NavBar/NavBar';

// Styles
import { Container, PageContainer } from './styles';

AuthenticatedAppLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default function AuthenticatedAppLayout({ children }) {
  return (
    <Container>
      <NavBar />
      <PageContainer>{children}</PageContainer>
    </Container>
  );
}
