import React from 'react';

const ClipboardCopyIcon = ({ fill }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.75 1.49956C3.75 1.08535 4.08579 0.749561 4.5 0.749561H12.5C12.9142 0.749561 13.25 1.08535 13.25 1.49956V9.49956C13.25 9.91378 12.9142 10.2496 12.5 10.2496H9.5C9.08579 10.2496 8.75 9.91378 8.75 9.49956C8.75 9.08535 9.08579 8.74956 9.5 8.74956H11.75V2.24956H5.25V4.49956C5.25 4.91378 4.91421 5.24956 4.5 5.24956C4.08579 5.24956 3.75 4.91378 3.75 4.49956V1.49956Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.749939 4.49963C0.749939 4.08542 1.08573 3.74963 1.49994 3.74963H9.49994C9.91415 3.74963 10.2499 4.08542 10.2499 4.49963V12.4996C10.2499 12.9138 9.91415 13.2496 9.49994 13.2496H1.49994C1.08573 13.2496 0.749939 12.9138 0.749939 12.4996V4.49963ZM2.24994 5.24963V11.7496H8.74994V5.24963H2.24994Z" fill={fill} />
  </svg>

);

export default ClipboardCopyIcon;