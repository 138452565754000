// Libraries
import { takeLatest, delay } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
// Helpers
import { redirect } from '@helpers/sagaHelpers';
// Routes
import * as routes from '@routes';

function* handleLocationChange(action) {
  if (!action.payload.isFirstRendering) {
    // Workaround for the following bug: when route is changed by Link,
    // redirect works on redux level, but doesn't render proper component
    yield delay(0);
  }

  const route = action.payload.location.pathname;

  switch (true) {
    case !routes.isExistingRoute(route):
      return yield redirect(routes.NOT_FOUND);

    default:
      break;
  }
}

export function* watchLocationChanges() {
  yield takeLatest(LOCATION_CHANGE, handleLocationChange);
}
