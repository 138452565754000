import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Theme
import { breakpoints } from '@theme/breakpoints';
import * as colors from '@theme/colors';

// Components
import { Flex } from './Flex';

export const FormWrapper = styled(Flex)`
  background: ${colors.white};
  width: 32%;
  padding: 30px;
  border-radius: 5px;
  flex-direction: column;

  @media (max-width: ${breakpoints.lg}px) {
    width: 44%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 60%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    padding: 20px;
  }
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ align }) => align && `align-items: ${align}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ direction }) => direction && `flex-direction: ${direction}`};

  ${({ width }) => width && `width: ${width}`};

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const Input = styled.input`
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid ${colors.lightGray};
  padding: 0 10px;
  height: 50px;
  color: ${colors.black};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ error }) => error && `border: 2px solid ${colors.red}`}
  ${({ withIcon }) => withIcon && `padding-right: 30px;`}
  ${({ width }) => width && `width: ${width}`}
  ${({ height }) => height && `height: ${height}`}
  ${({ color }) => color && `color: ${color}`}
  ${({ disable }) => disable && `background: ${colors.whiteGray};`}

  @media (max-width: ${breakpoints.sm}px) {
    height: 40px;

    &::placeholder {
      font-size: 12px;
    }
  }
`;

export const FormButton = styled.button`
  width: 100%;
  height: 50px;
  color: ${colors.white};
  cursor: pointer;
  background: ${colors.violet};
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
  font-size: 16px;
  position: relative;

  ${({ background }) => background && `background: ${background};`}

  @media (max-width: ${breakpoints.sm}px) {
    height: 40px;
  }
`;

export const Error = styled.div`
  color: ${colors.red};
  font-weight: 500;
  margin: 5px 0;
  font-size: 14px;

  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ isAbsolute }) =>
    isAbsolute &&
    `position: absolute;
  bottom: -18px;`}

${({ bottom }) => bottom && `bottom: ${bottom};`}
${({ fontSize }) => fontSize && `font-size: ${fontSize};`}


`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0;

  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: ${colors.middleGray};
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 4px;
    background: ${colors.white};
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: ${colors.violet};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.white} !important;
  text-decoration: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 50px;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${breakpoints.sm}px) {
    line-height: 40px;
  }
`;

export const SaveStatus = styled.span`
  display: flex;
  align-items: center;
  ${({ margin }) => margin && `margin: ${margin}`};
  max-width: 500px;
  word-wrap: break-word;
  color: ${({ success }) => (success ? colors.success : colors.red)};
  font-family: Roboto;
  vertical-align: middle;
`;

export const FormLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormLabel = styled.span`
  color: ${colors.black};
`;

export const FormInput = styled.input`
  background: ${({ disabled }) => (disabled ? colors.whiteGray : colors.white)};
  color: ${({ disabled, failed }) => (failed ? colors.red : disabled ? colors.darkGray : colors.black)};
  border: 1px solid ${colors.lightGray};
  ${({ failed }) =>
    failed &&
    css`
      border: 1px solid ${colors.red};
      outline-color: ${colors.red};
    `};
  box-sizing: border-box;
  border-radius: 4px;
  height: 38px;
  padding-left: 12px;
  margin-top: 8px;
`;

export const SuccessMessage = styled.span`
  color: ${colors.success};
  font-weight: bold;

  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const TextArea = styled.textarea`
  resize: none;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid ${colors.lightGray};
  padding: 10px;
  height: 100px;
  color: ${colors.black};
  font-size: 16px;

  ${({ error }) => error && `border: 2px solid ${colors.red}`}
  ${({ width }) => width && `width: ${width}`}
  ${({ height }) => height && `height: ${height}`}
  ${({ color }) => color && `color: ${color}`}
`;
