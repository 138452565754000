// Absolute imports
import styled from 'styled-components/macro';

// Theme
import { breakpoints } from '@theme/breakpoints';

export const Root = styled.nav`
  padding: 0;
  display: none;
  margin-left: 20px;

  @media (min-width: ${breakpoints.md}px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 440px;
  display: flex;
`;
