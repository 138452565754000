import styled from "styled-components";

// Theme
import { breakpoints } from "@theme/breakpoints";

export const IconWrapper = styled.span`
  position: absolute;
  top: 45px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media (max-width: ${breakpoints.sm}px) {
    top: 40px;
    width: 20px;
    height: 20px;
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: 10px;
  top: 20px;
  cursor: pointer;
  width: 20px;
  height: 20px;

  ${({ active }) => active && `border: 2px solid rgba(17,196,133,.7);`}
  
  @media (max-width: ${breakpoints.sm}px) {
    right: 5px;
  }
`;
