import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';

const INITIAL_PARAMS = {
  sortField: 'createdAt',
  sortOrder: 1,
  page: 1,
}

const useQueryParams = (initialParams = INITIAL_PARAMS) => {
  const { replace } = useHistory();
  const { search, pathname } = useLocation();

  const params = qs.parse(search);

  const onChangeParams = (paramsToInclude) => {
    const newParams = {
      ...params,
      ...{ page: 1, ...paramsToInclude },
    };
    const filteredParams = Object.keys(newParams).reduce((result, key) => Boolean(newParams[key]) ? ({ ...result, [key]: newParams[key] }) : result, {});
   
    if (JSON.stringify(filteredParams) !== JSON.stringify(params)) {
      replace(`${pathname}?${qs.stringify(filteredParams)}`)
    }
  };

  useEffect(() => {
    onChangeParams({ ...initialParams, ...params });
  }, []);

  return [params, onChangeParams];
};

export default useQueryParams;