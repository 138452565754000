import { connectRouter } from 'connected-react-router';
import { createSelector } from 'reselect';

export const routerSelector = state => state.router;

export const locationSelector = createSelector(routerSelector, router => router.location);

export const pathnameSelector = createSelector(locationSelector, location => location.pathname);

const createRouterReducer = history => {
  return connectRouter(history);
};

export default createRouterReducer;
