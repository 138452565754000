export const darkRed = '#BA2612';
export const red = '#ed2222';
export const violet = '#441eda';
export const lightViolet = '#A987E5';
export const theLightestViolet = '#cabdff';
export const success = '#63a260';
// 50 shades of gray
export const darkGray = '#9a9a9a';
export const gray = '#A8A5B4';
export const middleGray = '#bebebe';
export const lightGray = '#E4E3E8';
export const theLightestGray = '#f4f3f5';
export const whiteGray = '#FAFAFA';
//
export const black = '#2c2a32';
export const white = '#fff';

export const mainGradient = {
  darkViolet: '#261066',
  lighterViolet: '#3a1269',
  purpleViolet: '#471368',
  purple: '#59176c'
};
