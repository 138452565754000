import format from 'date-fns/format';
import isWithinInterval from 'date-fns/isWithinInterval';
import isEqual from 'date-fns/isEqual';
import endOfDay from 'date-fns/endOfDay'
import React from 'react';
import styled from 'styled-components';

const Tile = ({ date, selectedDates, onSelectDates, range = [] }) => {
  const [start, end] = range;

  const isActive = Boolean(start) && Boolean(end) ? isWithinInterval(date, { start, end }) : false;
  const isStart = Boolean(start) ? isEqual(date, start) : false;
  const isEnd = Boolean(end) ? isEqual(endOfDay(date), end) : false;

  return <Tile.Wrapper active={isActive} isToday={isEqual(endOfDay(new Date()), endOfDay(date))} isStart={isStart} isEnd={isEnd} onClick={() => onSelectDates(date.valueOf())}>
    {isStart || isEnd || selectedDates.includes(date.valueOf()) ? <Tile.Circle>{format(date, 'dd')}</Tile.Circle> : format(date, 'dd')}
  </Tile.Wrapper>;
};

Tile.Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: ${props => props.active ? '#EAE0FB' : '#fff'};
  border-radius: ${props => props.isStart && '50% 0 0 50%'};
  border-radius: ${props => props.isEnd && '0 50% 50% 0'};
  border-radius: ${props => !props.active && props.isToday && '50%'};
  border: ${props => !props.active && props.isToday && '1px solid #12072C'};

  &:hover {
    background-color: ${props => props.active ? 'none' : '#441EDA'};
    color: #fff;
    border-radius: ${props => props.active ? '0' : '50%'};
    border-radius: ${props => props.isStart && '50% 0 0 50%'};
    border-radius: ${props => props.isEnd && '0 50% 50% 0'};
  }
`;

Tile.Circle = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: #441EDA;
  border-radius: 50%;
  color: #fff;
`;

export default Tile;