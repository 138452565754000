// Absolute
import React from 'react';

// Custom hooks
import { useShallowSelector, useActions } from '@helpers/hooks';

import Events from './Events';
import * as actions from '@actions/events';

// Selectors
import * as eventsSelectors from '@reducers/events';

export default function ConnectedEvents() {
  const currentEvents = useShallowSelector(eventsSelectors.currentEventsSelector);
  const eventsCount = useShallowSelector(eventsSelectors.eventsCountSelector);
  const fetchEventsError = useShallowSelector(eventsSelectors.fetchEventsErrorSelector);

  const { fetchEvents } = useActions(actions);

  const props = {
    currentEvents,
    fetchEvents,
    eventsCount,
    fetchEventsError
  };
  return <Events {...props} />;
}
