// Absolute
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Styles
import * as Styled from './styles';
import * as StyledUI from '../UI';

// Components
import Cross from '../Cross/Cross';

// Theme
import * as colors from '@theme/colors';

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  buttons: PropTypes.element,
  childrenWrapper: PropTypes.object
};

export default function Modal({ children, close, buttons, title, childrenWrapper = { padding: '30px 20px' }, width }) {
  const modalRoot = document.getElementById('modal-root');

  const handleClick = event => {
    event.stopPropagation();
  };

  return ReactDOM.createPortal(
    <Styled.Wrapper onClick={close}>
      <Styled.ModalWindow width={width} onClick={handleClick}>
        <StyledUI.Flex borderBottom justify="space-between" padding="30px 20px">
          <StyledUI.H2 margin="0" size="xl" color={colors.black}>
            {title}
          </StyledUI.H2>
          <Cross clickHandler={close} />
        </StyledUI.Flex>
        <StyledUI.Flex {...childrenWrapper}>{children}</StyledUI.Flex>
        {buttons && (
          <StyledUI.Flex borderTop justify="flex-end" padding="20px">
            {buttons}
          </StyledUI.Flex>
        )}
      </Styled.ModalWindow>
    </Styled.Wrapper>,
    modalRoot
  );
}
