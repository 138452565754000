// Absolute
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PropTypesAirbnb from 'airbnb-prop-types';

// Styles
import * as StyledUI from '../UI';
import { linkStyles } from '@theme/linkStyles';

// Components
import Loading from '../Loading/Loading';
import Eye from '../Eye';

// Helpers
import { checkSearchQuery, validateEmailField } from '@helpers';

// Theme
import * as colors from '@theme/colors';

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  signInError: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired,
  signInAttempt: PropTypes.number.isRequired,
  resetSignInError: PropTypes.func.isRequired
};

export default function SignIn({ signIn, location, signInError, signInAttempt, resetSignInError }) {
  const [
    { email, emailError, password, passwordError, isPasswordHidden, error, isLoading, searchQuery },
    setState
  ] = useState({
    email: '',
    emailError: null,
    password: '',
    passwordError: null,
    isPasswordHidden: true,
    error: null,
    isLoading: false,
    searchQuery: location.search
  });

  useEffect(() => {
    if (!location.search) {
      setState(values => ({
        ...values,
        searchQuery: location.search,
        email: '',
        password: '',
        emailError: null,
        passwordError: null,
        error: null,
        isLoading: false
      }));
    }
  }, [location.search]);

  useEffect(() => {
    if (signInError) {
      setState(values => ({
        ...values,
        error: signInError,
        isLoading: false,
        email: '',
        password: ''
      }));
    }
  }, [signInError, signInAttempt]);

  const validatePassword = password => {
    if (!password) {
      return 'This field is required';
    }
    return null;
  };

  const handleChange = event => {
    const {
      target: { value, name }
    } = event;

    setState(values => ({
      ...values,
      [name]: value
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    setState(values => ({
      ...values,
      isLoading: true,
      error: null
    }));

    const emailError = validateEmailField(email);
    const passwordError = validatePassword(password);

    if (emailError || passwordError) {
      setState(values => ({
        ...values,
        emailError,
        passwordError,
        email: '',
        password: '',
        isLoading: false
      }));
      return;
    }

    setState(values => ({
      ...values,
      emailError: null,
      passwordError: null
    }));

    signIn({ email, password });
  };

  const togglePasswordVisibility = () => {
    setState(values => ({
      ...values,
      isPasswordHidden: !isPasswordHidden
    }));
  };

  const resetErrors = () => {
    resetSignInError();
  };

  return (
    <>
      <StyledUI.FormWrapper>
        <form onSubmit={handleSubmit}>
          <>
            <StyledUI.H2 color={colors.black} size="largest">
              {checkSearchQuery(searchQuery) ? 'First sign in' : 'Sign In'}
            </StyledUI.H2>
            <StyledUI.Label htmlFor="email" margin="0 0 15px 0">
              Email
              <StyledUI.Input
                value={email}
                onChange={handleChange}
                name="email"
                placeholder="Enter email"
                error={emailError}
                data-test="first-sign-in-email"
                id="email"
              />
              {emailError && (
                <StyledUI.Error isAbsolute data-test="first-sign-in-email-error">
                  {emailError}
                </StyledUI.Error>
              )}
            </StyledUI.Label>

            <StyledUI.Label htmlFor="password" margin="0 0 20px 0">
              {checkSearchQuery(searchQuery) ? 'Temporary password' : 'Password'}
              <StyledUI.Input
                value={password}
                onChange={handleChange}
                type={isPasswordHidden ? 'password' : 'text'}
                name="password"
                placeholder="Enter password"
                error={passwordError}
                data-test="first-sign-in-password"
                id="password"
              />
              <StyledUI.IconWrapper data-test="first-sign-in-eye" onClick={togglePasswordVisibility}>
                <Eye color={isPasswordHidden ? colors.gray : colors.violet} />
              </StyledUI.IconWrapper>
              {passwordError && (
                <StyledUI.Error isAbsolute data-test="first-sign-in-password-error">
                  {passwordError}
                </StyledUI.Error>
              )}
              {error && (
                <StyledUI.Error fontSize="16px" bottom="-28px" isAbsolute data-test="first-sign-in-general-error">
                  {error}
                </StyledUI.Error>
              )}
            </StyledUI.Label>

            <StyledUI.FormButton
              data-test="first-sign-in-button"
              background={isLoading ? colors.theLightestViolet : colors.violet}
              type="submit"
            >
              {isLoading ? <Loading /> : <span>Sign in</span>}
            </StyledUI.FormButton>

            {checkSearchQuery(searchQuery) ? (
              <>
                <StyledUI.Info data-test="first-sign-in-to-sign-in" margin="10px 0">
                  <Link to="/signin#" style={linkStyles}>
                    I already have an account
                  </Link>
                </StyledUI.Info>
              </>
            ) : (
              <>
                <StyledUI.Info data-test="sign-in-to-restore">
                  <Link to="/restore" style={linkStyles} onClick={resetErrors}>
                    Forgot password?
                  </Link>
                </StyledUI.Info>
              </>
            )}
          </>
        </form>
      </StyledUI.FormWrapper>
    </>
  );
}
