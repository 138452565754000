import styled from 'styled-components';

// Components
import { Button as UIButton } from '../UI';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

export const InviteButton = styled(UIButton)`
  width: 12%;

  @media (max-width: ${breakpoints.lg}px) {
    width: 18%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 25%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 40%;
  }
`;

export const Button = styled(UIButton)`
  width: 36%;

  @media (max-width: ${breakpoints.lg}px) {
    width: 40%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 36%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    margin: 0 0 10px 0;
  }
`;
