// Absolute imports
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

const sizes = {
  s: '14px',
  m: '16px',
  ml: '18px',
  lg: '20px',
  xl: '22px',
  largest: '30px'
};

export const HBase = css`
  margin: 0;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${colors.white};
  font-weight: bold;
  ${({ color }) => color && `color: ${color};`}
  ${({ paragraph }) => paragraph && `margin-bottom: 1em;`}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ margin }) => margin && `margin: ${margin};`}

`;

export const H1 = styled.h1`
  ${HBase}
  font-size: 48px;
  font-weight: 600;
  font-family: "Fira Sans", sans-serif;
  text-align: center;

  ${({ align }) => align && `text-align: ${align};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && `color: ${colors[color]};`}

  @media (max-width: ${breakpoints.md}px) {
    font-size: 26px;
  }
`;

export const H2 = styled.h2`
  ${HBase}
  font-size: 26px;
  margin: 0 0 20px 0;

  font-family: "Fira Sans", sans-serif;
  ${({ align }) => align && `text-align: ${align}`};
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ size }) => size && `font-size: ${sizes[size]};`}

  @media (max-width: ${breakpoints.md}px) {
    font-size: 22px;
  }
`;

export const Info = styled.p`
  color: ${colors.violet};
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  margin:20px 0 0 0;


  ${({ color }) => color && `color: ${color};`}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ size }) => size && `font-size: ${sizes[size]};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width};`}


  @media (max-width: ${breakpoints.sm}px) {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
`;

export const H3 = styled.h2`
  ${HBase}
  font-size: 28px;
  line-height: 1.23;
  font-weight: 600;
  ${({ align }) => align && `text-align: ${align};`}

  @media (min-width: 960px) {
    font-size: 40px;
  }
`;

export const H4 = styled.h2`
  ${HBase}
  font-size: 24px;
  text-align: center;
  font-family: 'Fira Sans', sans-serif;
  margin: 30px 0;

  @media (max-width: ${breakpoints.lg}px) {
    font-size: 22px;
  }

  @media (max-width: ${breakpoints.md}px) {
    font-size: 20px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 18px;
    margin: 10px 0;
  }
`;

export const Text = styled.div`
  color: ${colors.white};
  ${({ color }) => color && `color: ${color};`}

  ${({ bold }) => bold && `font-weight: ${bold};`}

  ${({ size }) => size && `font-size: ${sizes[size]};`}

  ${({ gutterBottom }) => gutterBottom && `margin-bottom: 1rem;`}

  ${({ paragraph }) => paragraph && `margin-bottom: 1em;`}

  ${({ align }) => align && `text-align: ${align};`}

  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ width }) => width && `width: ${width};`}

  ${({ dFlex }) => dFlex && `display: flex;`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: wrap;`}
  font-family: 'Roboto', sans-serif;
`;

export const AnchorLink = styled.a`
  color: ${colors.white};
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  justify-content: center;

  ${({ color }) => color && `color: ${color};`}
  ${({ padding }) => padding && `padding: ${padding};`}
`;

export const StyledLink = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  justify-content: center;

  ${({ color }) => color && `color: ${color};`}
`;

export const Span = styled.span`
  ${({ color }) => color && `color: ${color};`}
  ${({ bold }) => bold && `font-weight: 600; color: ${colors.white};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ fontSize }) => fontSize && `font-size: ${sizes[fontSize]};`}
`;
