// Absolute imports
import styled, { css } from 'styled-components/macro';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import * as colors from '@theme/colors';

export const Button = styled.button`
  width: 30%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: normal;
  margin-left: 10px;
  background: ${colors.white};
  border: 1px solid ${colors.violet};
  color: ${colors.violet};

  ${({ color }) => color && `color: ${color};`}
  ${({ background }) => background && `background: ${background};`}

  ${({ border }) => border && `border: 1px solid ${border};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ position }) => position && `position: ${position};`}


  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
    width: 100%;
    ${({ order }) => order && `order: ${order};`}
    ${({ margin }) => margin && `margin: ${margin};`}
  }
`;

export const SettingsButton = styled.button`
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};
  width: 140px;
  height: 40px;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
  color: ${colors.white};
  background: ${({ disabled }) => (disabled ? colors.gray : colors.violet)};
  font-family: Roboto;
  user-select: none;
`;
