// Libraries
import { takeLatest, put, select, delay } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
// Selectors
import * as authSelectors from '@reducers/auth';
// Actions
import * as authActions from '@actions/auth';
// Routes
import * as routes from '@routes';
// Helpers
import { getTokenFromPathname } from '@helpers';
import { redirect } from '@helpers/sagaHelpers';

function* handleLocationChange(action) {
  if (!action.payload.isFirstRendering) {
    // Workaround for the following bug: when route is changed by Link,
    // redirect works on redux level, but doesn't render proper component
    yield delay(0);
  }

  const route = action.payload.location.pathname;
  const isAuthenticated = yield select(authSelectors.isAuthenticatedSelector);
  const shouldCreatePassword = yield select(authSelectors.shouldCreatePasswordSelector);
  const tempPassword = yield select(authSelectors.tempPasswordSelector);
  const resetPasswordToken = yield select(authSelectors.resetPasswordTokenSelector);

  switch (true) {
    case isAuthenticated && shouldCreatePassword && tempPassword && !routes.match(route, routes.CREATE_PASSWORD):
      return yield redirect(routes.CREATE_PASSWORD);

    case isAuthenticated && shouldCreatePassword && !tempPassword && !routes.match(route, routes.SIGN_IN):
      return yield redirect(routes.SIGN_IN);

    case isAuthenticated && !shouldCreatePassword && (routes.isAuthRoute(route) || routes.match(route, routes.INDEX)):
      return yield redirect(routes.EVENTS);

    case !isAuthenticated && routes.match(route, routes.RESET):
      const newResetPasswordToken = getTokenFromPathname(route);
      yield put(authActions.setResetPasswordToken(newResetPasswordToken));
      return yield redirect(routes.CREATE_PASSWORD_AFTER_RESTORE);

    case !isAuthenticated && routes.match(route, routes.CREATE_PASSWORD):
      return yield redirect(routes.SIGN_IN);

    case !isAuthenticated && !routes.isAuthRoute(route):
      return yield redirect(routes.SIGN_IN);

    case routes.match(route, routes.CREATE_PASSWORD_AFTER_RESTORE) && !resetPasswordToken:
      return yield redirect(routes.SIGN_IN);

    default:
      break;
  }
}

export function* watchLocationChanges() {
  yield takeLatest(LOCATION_CHANGE, handleLocationChange);
}

function* handleAction(action) {
  switch (action.type) {
    case authActions.CREATE_NEW_PASSWORD_SUCCESS:
    case authActions.SIGN_OUT:
      return yield redirect(routes.SIGN_IN);
    case authActions.SIGN_IN_SUCCESS:
      return yield redirect(routes.EVENTS);
    default:
      break;
  }
}

export function* watchActions() {
  yield takeLatest(
    [authActions.SIGN_IN_SUCCESS, authActions.CREATE_NEW_PASSWORD_SUCCESS, authActions.SIGN_OUT],
    handleAction
  );
}
