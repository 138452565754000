// Absolute
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

PopUp.propTypes = {
  children: PropTypes.element.isRequired
};

export default function PopUp({ children }) {
  const popUpRoot = document.getElementById('popup-root');

  return ReactDOM.createPortal(<>{children}</>, popUpRoot);
}
