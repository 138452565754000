// @ts-check

import { apiCaller } from './api';
import * as objectHelpers from '@helpers/objectHelpers';
import { getOrdersUrlByParams } from '@components/PaymentHistory/helpers';

const apiCallRaw = apiCaller(process.env.REACT_APP_ADMIN_API_URL);

const apiCall = apiCaller(process.env.REACT_APP_ADMIN_API_URL, {
  defaultOptions: {
    headers: {
      'Content-Type': 'application/json'
    }
  }
});

const apiCallWithToken = (url, accessToken, options) =>
  apiCall(
    url,
    objectHelpers.mergeDeepRight(options, {
      headers: {
        Authorization: accessToken
      }
    })
  );

export const signIn = (email, password) => {
  return apiCall(`/authentication`, {
    method: 'POST',
    body: JSON.stringify({
      strategy: 'local',
      email,
      password
    })
  });
};

export const checkAuth = accessToken => {
  return apiCallWithToken(`/authentication`, accessToken, {
    method: 'POST',
    body: JSON.stringify({
      strategy: 'jwt',
      accessToken
    })
  });
};

export const patchPassword = (accessToken, tempPassword, newPassword) => {
  return apiCallWithToken(`/users`, accessToken, {
    method: 'PATCH',
    body: JSON.stringify({
      password: {
        current: tempPassword,
        new: newPassword
      }
    })
  });
};

export function resetPassword(email) {
  return apiCall(`/authManagement`, {
    method: 'POST',
    body: JSON.stringify({
      action: 'sendResetPwd',
      value: {
        email
      }
    })
  });
}

export function createNewPassword({ token, password }) {
  return apiCall(`/authManagement`, {
    method: 'POST',
    body: JSON.stringify({
      action: 'resetPwdLong',
      value: {
        token,
        password
      }
    })
  });
}

export const fetchEvents = ({ accessToken, limit, skip = 0, sortBy, sortOrder }) =>
  apiCallWithToken(`/events?$limit=${limit}&$skip=${skip}&$sort[${sortBy}]=${sortOrder}`, accessToken);

export const fetchPaymentOrders = ({ accessToken, params }) =>
  apiCallWithToken(
    getOrdersUrlByParams('/all-user-transactions', params),
    accessToken
  );

export const fetchPaymentHistory = ({ accessToken, params }) =>
  apiCallWithToken(
    getOrdersUrlByParams('/all-user-transactions', params),
    accessToken
  );

export const fetchPayoutInfo = ({ accessToken }) => apiCallWithToken(`/payout/info`, accessToken);

export const fetchWalletInfo = id => apiCaller('https://api.blockcypher.com/v1')(`/btc/main/addrs/${id}/balance`);

export const approveOrder = ({ accessToken, transactionId }) =>
  apiCallWithToken(`/all-user-transactions/${transactionId}`, accessToken, {
    method: 'PATCH',
    body: JSON.stringify({ status: 'processed' })
  });

export const rejectOrder = ({ accessToken, transactionId, rejectReason }) =>
  apiCallWithToken(`/all-user-transactions/${transactionId}`, accessToken, {
    method: 'PATCH',
    body: JSON.stringify({ status: 'rejected', rejectReason })
  });

export const getCommission = () => apiCall('/commission');

export const setCommission = (accessToken, payload) =>
  apiCallWithToken('/commission', accessToken, {
    method: 'PATCH',
    body: JSON.stringify(payload)
  });

export const fetchUsers = ({ accessToken, limit, skip = 0, sortBy, sortOrder }) =>
  apiCallWithToken(`/users?$limit=${limit}&$skip=${skip}&$sort[${sortBy}]=${sortOrder}`, accessToken);

export function deleteUser({ accessToken, email }) {
  return apiCallWithToken(`/users?email=${email}`, accessToken, {
    method: 'DELETE'
  });
}

export const fetchUser = ({ accessToken, id }) => apiCallWithToken(`/users?_id=${id}`, accessToken);

export function saveUserData({ accessToken, firstName, lastName, notifications, newPassword, currentPassword }) {
  return apiCallWithToken(`/users`, accessToken, {
    method: 'PATCH',
    body: JSON.stringify({
      firstName,
      lastName,
      notifications,
      password: {
        current: currentPassword,
        new: newPassword
      }
    })
  });
}

export function saveProfileUserData({ accessToken, firstName, lastName, notifications }) {
  return apiCallWithToken(`/users`, accessToken, {
    method: 'PATCH',
    body: JSON.stringify({
      firstName,
      lastName,
      notification: notifications
    })
  });
}

export function saveUserPassword({ accessToken, newPassword, currentPassword }) {
  return apiCallWithToken(`/users`, accessToken, {
    method: 'PATCH',
    body: JSON.stringify({
      password: {
        current: currentPassword,
        new: newPassword
      }
    })
  });
}

export const uploadPhoto = (accessToken, photo) => {
  const data = new FormData();
  data.append('uri', photo);
  const body = data;
  return apiCallRaw(`/uploads`, {
    method: 'POST',
    headers: {
      Authorization: accessToken
    },
    body
  });
};

export function inviteUser({ accessToken, email, invitationMessage }) {
  return apiCallWithToken(`/users`, accessToken, {
    method: 'POST',
    body: JSON.stringify({
      email,
      invitationMsg: invitationMessage
    })
  });
}

export const getCoinPurchasePrice = accessToken => apiCallWithToken('/coin-price', accessToken);

export const setCoinPurchasePrice = (accessToken, payload) =>
  apiCallWithToken('/coin-price', accessToken, {
    method: 'POST',
    body: JSON.stringify(payload)
  });

export const getCoinSalePrice = accessToken => apiCallWithToken('/coin-sale-price', accessToken);

export const setCoinSalePrice = (accessToken, payload) =>
  apiCallWithToken('/coin-sale-price', accessToken, {
    method: 'POST',
    body: JSON.stringify(payload)
  });

export const setUserLimit = (accessToken, payload) =>
  apiCallWithToken('/user-limits-management', accessToken, {
    method: 'POST',
    body: JSON.stringify(payload)
  });

export const fetchGeneralLimits = accessToken => apiCallWithToken('/user-limits/general', accessToken);
export const fetchIndividualLimits = ({ accessToken, limit, skip = 0, search }) =>
  apiCallWithToken(
    `/user-limits/individual?$limit=${limit}&$skip=${skip}${search ? `&email[$search]=${search}` : ''}`,
    accessToken
  );
export const removeIndividualLimit = ({ accessToken, id }) =>
  apiCallWithToken(`/user-limits/individual/${id}`, accessToken, { method: 'DELETE' });
export const setIndividualLimit = ({ accessToken, payload }) =>
  apiCallWithToken('/user-limits/individual', accessToken, { method: 'POST', body: JSON.stringify(payload) });

export const setGeneralLimit = ({ accessToken, payload }) =>
  apiCallWithToken('/user-limits/general', accessToken, { method: 'POST', body: JSON.stringify(payload) });
