import { createAction } from 'redux-actions';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT = 'SIGN_OUT';
export const PATCH_PASSWORD = 'PATCH_PASSWORD';
export const PATCH_PASSWORD_SUCCESS = 'PATCH_PASSWORD_SUCCESS';
export const PATCH_PASSWORD_FAILURE = 'PATCH_PASSWORD_FAILURE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_IS_PASSWORD_RESET_VARIABLE = 'RESET_IS_PASSWORD_RESET_VARIABLE';
export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const SET_RESET_PASSWORD_TOKEN = 'SET_RESET_PASSWORD_TOKEN';
export const CREATE_NEW_PASSWORD_FAILURE = 'CREATE_NEW_PASSWORD_FAILURE';
export const CREATE_NEW_PASSWORD_SUCCESS = 'CREATE_NEW_PASSWORD_SUCCESS';
export const SAVE_PROFILE_USER_DATA = 'SAVE_PROFILE_USER_DATA';
export const SAVE_PROFILE_USER_DATA_SUCCESS = 'SAVE_PROFILE_USER_DATA_SUCCESS';
export const SAVE_PROFILE_USER_DATA_FAILURE = 'SAVE_PROFILE_USER_DATA_FAILURE';
export const FETCH_PROFILE_USER = 'FETCH_PROFILE_USER';
export const FETCH_PROFILE_USER_SUCCESS = 'FETCH_PROFILE_USER_SUCCESS';
export const FETCH_PROFILE_USER_FAILURE = 'FETCH_PROFILE_USER_FAILURE';
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILURE = 'UPLOAD_PHOTO_FAILURE';
export const RESET_IS_PHOTO_LOADED = 'RESET_IS_PHOTO_LOADED';
export const SAVE_USER_PASSWORD = 'SAVE_USER_PASSWORD';
export const SAVE_USER_PASSWORD_SUCCESS = 'SAVE_USER_PASSWORD_SUCCESS';
export const SAVE_USER_PASSWORD_FAILURE = 'SAVE_USER_PASSWORD_FAILURE';
export const RESET_IS_PASSWORD_SAVED = 'RESET_IS_PASSWORD_SAVED';
export const RESET_IS_PROFILE_USER_DATA_SAVED = 'RESET_IS_PROFILE_USER_DATA_SAVED';
export const RESET_SIGN_IN_ERROR = 'RESET_SIGN_IN_ERROR';

export const signIn = createAction(SIGN_IN);

export const signInSuccess = createAction(SIGN_IN_SUCCESS);

export const signInFailure = createAction(SIGN_IN_FAILURE);

export const signOut = createAction(SIGN_OUT);

export const patchPassword = createAction(PATCH_PASSWORD);

export const patchPasswordSuccess = createAction(PATCH_PASSWORD_SUCCESS);

export const patchPasswordFailure = createAction(PATCH_PASSWORD_FAILURE);

export const resetPassword = createAction(RESET_PASSWORD);

export const resetPasswordFailure = createAction(RESET_PASSWORD_FAILURE);

export const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS);

export const resetIsPasswordResetVariable = createAction(RESET_IS_PASSWORD_RESET_VARIABLE);

export const createNewPassword = createAction(CREATE_NEW_PASSWORD);

export const setResetPasswordToken = createAction(SET_RESET_PASSWORD_TOKEN);

export const createNewPasswordFailure = createAction(CREATE_NEW_PASSWORD_FAILURE);

export const createNewPasswordSuccess = createAction(CREATE_NEW_PASSWORD_SUCCESS);

export const saveProfileUserData = createAction(SAVE_PROFILE_USER_DATA);

export const saveProfileUserDataSuccess = createAction(SAVE_PROFILE_USER_DATA_SUCCESS);

export const saveProfileUserDataFailure = createAction(SAVE_PROFILE_USER_DATA_FAILURE);

export const fetchProfileUser = createAction(FETCH_PROFILE_USER);

export const fetchProfileUserSuccess = createAction(FETCH_PROFILE_USER_SUCCESS);

export const fetchProfileUserFailure = createAction(FETCH_PROFILE_USER_FAILURE);

export const uploadPhoto = createAction(UPLOAD_PHOTO);

export const uploadPhotoSuccess = createAction(UPLOAD_PHOTO_SUCCESS);

export const uploadPhotoFailure = createAction(UPLOAD_PHOTO_FAILURE);

export const resetIsPhotoLoadedVariable = createAction(RESET_IS_PHOTO_LOADED);

export const saveUserPassword = createAction(SAVE_USER_PASSWORD);

export const saveUserPasswordSuccess = createAction(SAVE_USER_PASSWORD_SUCCESS);

export const saveUserPasswordFailure = createAction(SAVE_USER_PASSWORD_FAILURE);

export const resetIsPasswordSavedVariable = createAction(RESET_IS_PASSWORD_SAVED);

export const resetIsProfileUserDataSavedVariable = createAction(RESET_IS_PROFILE_USER_DATA_SAVED);

export const resetSignInError = createAction(RESET_SIGN_IN_ERROR);
