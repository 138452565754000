import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PropTypesAirbnb from 'airbnb-prop-types';

// Styles
import * as StyledUI from '../UI';
import * as Styled from './styles';
import { linkStyles } from '@theme/linkStyles';

// Constants
import { USERS_PAGE_DEFAULT_LIMIT, FIRST_STEP, SECOND_STEP } from '@constants';

// Components
import Table from '../Table/Table';
import Pagination from '../Pagination/Pagination';
import Modal from '../Modal/Modal';
import Loading from '../Loading/Loading';
import Image from '../Image/Image';
import Logo from '../Logo';

// Helpers
import { getDate, getTime, validateEmailField } from '@helpers';

// Theme
import * as colors from '@theme/colors';

Users.propTypes = {
  user: PropTypes.object.isRequired,
  currentUsers: PropTypes.array.isRequired,
  usersCount: PropTypes.number.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchUsersError: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired,
  deleteUser: PropTypes.func.isRequired,
  isUserDeleted: PropTypes.bool.isRequired,
  attemptForDeleteUser: PropTypes.number.isRequired,
  deleteUserError: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired,
  resetIsUserDeletedVariable: PropTypes.func.isRequired,
  resetDeleteUserErrorVariable: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  inviteUser: PropTypes.func.isRequired,
  isUserInvited: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.bool]).isRequired,
  inviteUserError: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired,
  resetInviteUserErrorVariable: PropTypes.func.isRequired,
  resetIsUserInvitedVariable: PropTypes.func.isRequired
};

export default function Users({
  user,
  fetchUsers,
  usersCount,
  currentUsers,
  fetchUsersError,
  deleteUser,
  isUserDeleted,
  attemptForDeleteUser,
  deleteUserError,
  resetIsUserDeletedVariable,
  resetDeleteUserErrorVariable,
  signOut,
  inviteUser,
  isUserInvited,
  inviteUserError,
  resetInviteUserErrorVariable,
  resetIsUserInvitedVariable
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isRemoveUserModalShown, setIsRemoveUserModalShown] = useState(false);
  const [isInviteUserModalShown, setIsInviteUserModalShown] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [removeUserStep, setRemoveUserStep] = useState(FIRST_STEP);
  const [inviteUserStep, setInviteUserStep] = useState(FIRST_STEP);
  const [shouldSignOut, setShouldSignOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);

  const [{ email, invitationMessage }, setInviteUserValues] = useState({
    email: '',
    invitationMessage: ''
  });

  const tableHeaders = ['Avatar', 'Name', 'Email', 'Invited by', 'Date', 'Time', 'Button'];

  const users = useMemo(() => {
    return currentUsers.map(user => {
      return {
        name: ({ rowData }) => {
          return (
            <Link onClick={resetIsUserDeletedVariable} style={linkStyles} to={`/usersprofile/${rowData.id}`}>
              {user.firstName ? `${user.firstName} ${user.lastName}` : 'Anonymous'}
            </Link>
          );
        },
        email: user.email,
        invitedBy: user.invitedBy,
        date: getDate(user.createdAt),
        time: getTime(user.createdAt),
        avatar: user.photo ? (
          <StyledUI.AvatarContainer>
            <Image photo={user.photo} />
          </StyledUI.AvatarContainer>
        ) : (
          <StyledUI.AvatarContainer>
            <StyledUI.Flex width="24px" height="24px">
              <Logo />
            </StyledUI.Flex>
          </StyledUI.AvatarContainer>
        ),
        button: ({ isRowHovered, rowData }) => {
          const setUserData = () => {
            setData(rowData);
          };
          return (
            <StyledUI.TableButton active={isRowHovered} onClick={setUserData}>
              Delete
            </StyledUI.TableButton>
          );
        },
        id: user._id
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUsers]);

  useEffect(() => {
    if (isUserInvited || inviteUserError) {
      setIsLoading(false);
      setInviteUserStep(SECOND_STEP);
    }
  }, [isUserInvited, inviteUserError]);

  const setPrevPage = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (!users.length) {
      setPrevPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const setData = rowData => {
    setCurrentData(rowData);
    setIsRemoveUserModalShown(true);
  };

  useEffect(() => {
    fetchUsers({ limit: USERS_PAGE_DEFAULT_LIMIT, skip: 0 });
  }, [fetchUsers]);

  useEffect(() => {
    if (!deleteUserError && !isRemoveUserModalShown) {
      fetchUsers({ limit: USERS_PAGE_DEFAULT_LIMIT, skip: (currentPage - 1) * USERS_PAGE_DEFAULT_LIMIT });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptForDeleteUser, isRemoveUserModalShown, deleteUserError]);

  const setPage = pageNumber => {
    setCurrentPage(pageNumber);

    fetchUsers({ limit: USERS_PAGE_DEFAULT_LIMIT, skip: (pageNumber - 1) * USERS_PAGE_DEFAULT_LIMIT });
  };

  const closeRemoveUserModal = () => {
    setIsRemoveUserModalShown(false);
    setRemoveUserStep(FIRST_STEP);
  };

  const closeInviteUserModal = () => {
    setIsInviteUserModalShown(false);
    setInviteUserStep(FIRST_STEP);

    setInviteUserValues(values => ({
      ...values,
      email: '',
      invitationMessage: ''
    }));
    setEmailError('');
  };

  const deleteUserHandler = () => {
    if (removeUserStep === FIRST_STEP) {
      deleteUser({ email: currentData.email });

      if (user.email === currentData.email) {
        setShouldSignOut(true);
      }

      setRemoveUserStep(SECOND_STEP);
    }
  };

  const inviteUserHandler = () => {
    if (inviteUserStep === FIRST_STEP) {
      const emailError = validateEmailField(email);

      if (emailError) {
        setEmailError(emailError);
        return;
      }

      inviteUser({ email, invitationMessage });
      setIsLoading(true);
      setEmailError(null);
    }
  };

  const closeAndResetRemoveUserModal = event => {
    resetDeleteUserErrorVariable();
    resetIsUserDeletedVariable();

    closeRemoveUserModal(event);

    if (shouldSignOut) {
      signOut();
    }
  };

  const closeAndResetInviteUserModal = event => {
    resetInviteUserErrorVariable();
    resetIsUserInvitedVariable();

    closeInviteUserModal(event);
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setInviteUserValues(values => ({
      ...values,
      [name]: value
    }));
  };

  const showInviteUserModal = () => {
    setIsInviteUserModalShown(true);
  };

  return (
    <>
      <StyledUI.Flex justify="space-between" align="center">
        <StyledUI.Flex direction="column">
          <StyledUI.H2 color={colors.black} margin="5px 0">
            Users
          </StyledUI.H2>
          <StyledUI.Text data-test="users-total" color={colors.darkGray} margin="10px 0" size="s">
            Total: {usersCount}
          </StyledUI.Text>
        </StyledUI.Flex>
        <Styled.InviteButton
          data-test="users-invite-btn"
          onClick={showInviteUserModal}
          background={colors.violet}
          border={colors.violet}
          color={colors.white}
        >
          Invite user
        </Styled.InviteButton>
      </StyledUI.Flex>
      {fetchUsersError && <StyledUI.Error data-test="users-fetch-error">{fetchUsersError}</StyledUI.Error>}
      <Table items={users} headers={tableHeaders} />

      {isRemoveUserModalShown && removeUserStep === FIRST_STEP ? (
        <Modal
          close={closeRemoveUserModal}
          title={'Delete user'}
          buttons={
            <StyledUI.Flex wrap="wrap" width="100%" justify="flex-end">
              <Styled.Button
                data-test="users-delete-modal-cancel-btn"
                order={2}
                onClick={closeRemoveUserModal}
                color={colors.darkRed}
                border={colors.darkRed}
              >
                Cancel
              </Styled.Button>
              <Styled.Button
                data-test="users-delete-modal-delete-btn"
                order={1}
                onClick={deleteUserHandler}
                background={colors.darkRed}
                color={colors.white}
                border={colors.darkRed}
              >
                Delete
              </Styled.Button>
            </StyledUI.Flex>
          }
        >
          <span>All the user info will be deleted permanently. You will not be able to restore it.</span>
        </Modal>
      ) : isRemoveUserModalShown && removeUserStep === SECOND_STEP && isUserDeleted ? (
        <Modal
          close={closeAndResetRemoveUserModal}
          title={'User deleted'}
          buttons={
            <Styled.Button
              data-test="users-delete-modal-got-it-btn"
              onClick={closeAndResetRemoveUserModal}
              background={colors.violet}
              color={colors.white}
              border={colors.violet}
            >
              Got it
            </Styled.Button>
          }
        >
          <span>The user has been successfully deleted from the system.</span>
        </Modal>
      ) : isRemoveUserModalShown && removeUserStep === SECOND_STEP && deleteUserError && !isUserDeleted ? (
        <Modal
          close={closeAndResetRemoveUserModal}
          title={"User wasn't deleted"}
          buttons={
            <Styled.Button
              data-test="users-delete-modal-ok-btn"
              onClick={closeAndResetRemoveUserModal}
              background={colors.darkRed}
              color={colors.white}
              border={colors.darkRed}
            >
              OK
            </Styled.Button>
          }
        >
          <span data-test="users-delete-modal-error">{deleteUserError}</span>
        </Modal>
      ) : null}

      {isInviteUserModalShown && inviteUserStep === FIRST_STEP ? (
        <Modal
          close={closeInviteUserModal}
          title={'Invite user'}
          buttons={
            <StyledUI.Flex wrap="wrap" width="100%" justify="flex-end">
              <Styled.Button
                data-test="users-invite-modal-cancel-btn"
                order={2}
                onClick={closeInviteUserModal}
                color={colors.violet}
                border={colors.violet}
              >
                Cancel
              </Styled.Button>
              <Styled.Button
                order={1}
                onClick={inviteUserHandler}
                background={isLoading ? colors.theLightestViolet : colors.violet}
                color={colors.white}
                border={isLoading ? colors.theLightestViolet : colors.violet}
                data-test="users-invite-modal-send-btn"
              >
                {isLoading ? <Loading /> : <span>Send invitation</span>}
              </Styled.Button>
            </StyledUI.Flex>
          }
        >
          <StyledUI.Flex width="100%" direction="column">
            <StyledUI.Label margin="0 0 15px 0">
              Email
              <StyledUI.Input
                data-test="users-invite-modal-email"
                value={email}
                onChange={handleChange}
                name="email"
                error={emailError}
              />
              {emailError && <StyledUI.Error isAbsolute>{emailError}</StyledUI.Error>}
            </StyledUI.Label>
            <StyledUI.Label>
              Message
              <StyledUI.TextArea
                data-test="users-invite-modal-message"
                value={invitationMessage}
                onChange={handleChange}
                name="invitationMessage"
              />
            </StyledUI.Label>
          </StyledUI.Flex>
        </Modal>
      ) : isInviteUserModalShown && inviteUserStep === SECOND_STEP && isUserInvited ? (
        <Modal
          close={closeAndResetInviteUserModal}
          title={'Invitation sent'}
          buttons={
            <>
              <Styled.Button
                data-test="users-invite-modal-got-it-btn"
                onClick={closeAndResetInviteUserModal}
                background={colors.violet}
                color={colors.white}
                border={colors.violet}
              >
                Got it
              </Styled.Button>
            </>
          }
        >
          <span>The invitation has been successfully sent to receiver.</span>
        </Modal>
      ) : isInviteUserModalShown && inviteUserStep === SECOND_STEP && inviteUserError ? (
        <Modal
          close={closeAndResetInviteUserModal}
          title={"Invitation wasn't sent"}
          buttons={
            <>
              <Styled.Button
                data-test="users-invite-modal-ok-btn"
                onClick={closeAndResetInviteUserModal}
                background={colors.darkRed}
                color={colors.white}
                border={colors.darkRed}
              >
                OK
              </Styled.Button>
            </>
          }
        >
          <span data-test="users-invite-modal-error">{inviteUserError}</span>
        </Modal>
      ) : null}

      <Pagination
        itemsPerPage={USERS_PAGE_DEFAULT_LIMIT}
        totalCount={usersCount}
        setPage={setPage}
        page={currentPage}
      />
    </>
  );
}
