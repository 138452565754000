// @ts-check
import axios from 'axios';

import { all, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from '@actions/payments';
import * as authSelectors from '@reducers/auth';
import * as locationSelectors from '@reducers/router';
import * as api from '@services/ownerAdminApi';
import { TABLE_LIMIT } from '../constants/index';
import qs from 'query-string';

function* getTxStatus(tx) {
  try {
    return yield axios.get(`https://blockchain.info/rawtx/${tx}`);
  } catch(err) {
    return null;
  }
}

function* fetchPaymentOrders({ payload }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { error, result } = yield* api.fetchPaymentOrders({
    accessToken,
    params: payload,
  });
  if (error) {
    yield put(actions.fetchPaymentOrdersFailure(error.message));
  } else {
    yield put(
      actions.fetchPaymentOrdersSuccess({
        orders: result.data,
        ordersCount: result.total
      })
    );
  }
}

export function* watchFetchPaymentOrders() {
  yield takeLatest(actions.FETCH_PAYMENT_ORDERS, fetchPaymentOrders);
}

export function* fetchUserTransactions({ payload }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { result, error } = yield* api.fetchPaymentHistory({
    accessToken,
    params: payload,
  });
  if (error) {
    yield put(actions.fetchUserTransactionsFailure(error));
  } else {
    const orders = result?.data?.length > 0 ? result.data : [];
    const statuses = yield all(orders.filter(({ tx }) => Boolean(tx)).map(({ tx }) => getTxStatus(tx)));
    const transactions = orders.map((order) => Boolean(order.tx) ? { ...order, transactionStatus: statuses.find((status) => order.tx === status?.data?.hash)?.data?.block_height > 0 } : order);
    yield put(actions.fetchUserTransactionsSuccess({ ...result, data: transactions }));
  }
}

export function* watchFetchUserTransactions() {
  yield takeLatest(actions.FETCH_USER_TRANSACTIONS, fetchUserTransactions);
}

function* fetchPayoutInfo() {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { error: payoutError, result: payout } = yield* api.fetchPayoutInfo({ accessToken });
  const { error: walletError, result: wallet } = yield api.fetchWalletInfo(payout?.wallet);

  if (payoutError) {
    yield put(actions.fetchPayoutInfoFailure(payoutError.message))
  }
  if (walletError) {
    yield put(actions.fetchPayoutInfoWalletFailure(walletError.message))
  }

  const walletBalance = wallet?.final_balance / Math.pow(10, 8);
  const isEnoughToPayAll = walletBalance >= payout?.needToBePaid;

  yield put(actions.fetchPayoutInfoSuccess({
    ...payout,
    paidOut: (wallet?.final_balance - wallet?.total_received) / Math.pow(10, 8),
    walletBalance,
    isEnoughToPayAll
  }));
}

export function* watchFetchPayoutInfo() {
  yield takeLatest(actions.FETCH_PAYOUT_INFO, fetchPayoutInfo);
}

function* approveOrder({ payload: { transactionId } }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { search } = yield select(locationSelectors.locationSelector);
  const query = qs.parse(search);
  const { error, result } = yield* api.approveOrder({ accessToken, transactionId });
  if (error) {
    yield put(actions.approveOrderFailure(error.message));
  } else {
    yield put(actions.approveOrderSuccess(result));
    yield put(actions.fetchPaymentOrders({ ...query, skip: 0, status: 'processing', pair: 'EEX/BTC', limit: TABLE_LIMIT }));
  }
}

export function* watchApproveOrder() {
  yield takeLatest(actions.APPROVE_ORDER, approveOrder);
}

function* rejectOrder({ payload: { transactionId, rejectReason } }) {
  const accessToken = yield select(authSelectors.accessTokenSelector);
  const { search } = yield select(locationSelectors.locationSelector);
  const query = qs.parse(search);
  const { error, result } = yield* api.rejectOrder({ accessToken, transactionId, rejectReason });
  if (error) {
    yield put(actions.rejectOrderFailure(error.message));
  } else {
    yield put(actions.rejectOrderSuccess(result));
    yield put(actions.fetchPaymentOrders({ ...query, skip: 0, status: 'processing', pair: 'EEX/BTC', limit: TABLE_LIMIT }));
  }
}

export function* watchRejectOrder() {
  yield takeLatest(actions.REJECT_ORDER, rejectOrder);
}
