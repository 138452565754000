import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const defaultState = {
  currentEvents: [],
  eventsCount: 0,
  fetchEventsError: null
};

export const eventsSelector = state => state.events;

export const currentEventsSelector = createSelector(eventsSelector, events => events.currentEvents);

export const eventsCountSelector = createSelector(eventsSelector, events => events.eventsCount);

export const fetchEventsErrorSelector = createSelector(eventsSelector, events => events.fetchEventsError);

export default handleActions(
  {
    FETCH_EVENTS_SUCCESS: (state, { payload: { events, eventsCount } }) => ({
      ...state,
      currentEvents: events,
      eventsCount: eventsCount,
      fetchEventsError: null
    }),
    FETCH_EVENTS_FAILURE: (state, { payload: { error } }) => ({
      ...state,
      fetchEventsError: error
    })
  },
  defaultState
);
