// Absolute
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PropTypesAirbnb from 'airbnb-prop-types';
import * as loadImage from 'blueimp-load-image';

// Components
import Profile from '../Profile/Profile';
import Modal from '../Modal/Modal';
import Eye from '../Eye';
import Loading from '../Loading/Loading';
import Image from '../Image/Image';
import Logo from '../Logo';

// Styles
import * as StyledUI from '../UI';
import * as Styled from './styles';

// Helpers
import { validateFields, validatePassword } from '@helpers';
import * as objectHelpers from '@helpers/objectHelpers';

// Constants
import { SUCCESS_MESSAGE_DELAY_MS } from '@constants';

// Theme
import * as colors from '@theme/colors';

MyProfile.propTypes = {
  user: PropTypes.object.isRequired,
  fetchProfileUser: PropTypes.func.isRequired,
  saveProfileUserData: PropTypes.func.isRequired,
  uploadPhoto: PropTypes.func.isRequired,
  isProfileUserDataSaved: PropTypes.bool.isRequired,
  isUploadingPhoto: PropTypes.bool.isRequired,
  isPhotoLoaded: PropTypes.bool.isRequired,
  attemptForSaveProfileData: PropTypes.number.isRequired,
  uploadPhotoError: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired,
  fetchProfileUserError: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired,
  resetIsPhotoLoadedVariable: PropTypes.func.isRequired,
  saveUserPassword: PropTypes.func.isRequired,
  isPasswordSaved: PropTypes.bool.isRequired,
  savePasswordError: PropTypesAirbnb.or([PropTypesAirbnb.explicitNull, PropTypes.string]).isRequired,
  attemptForSavePassword: PropTypes.number.isRequired,
  resetIsPasswordSavedVariable: PropTypes.func.isRequired,
  resetIsProfileUserDataSavedVariable: PropTypes.func.isRequired
};

export default function MyProfile({
  user,
  fetchProfileUser,
  saveProfileUserData,
  uploadPhoto,
  isProfileUserDataSaved,
  isUploadingPhoto,
  isPhotoLoaded,
  attemptForSaveProfileData,
  saveProfileUserDataError,
  uploadPhotoError,
  fetchProfileUserError,
  resetIsPhotoLoadedVariable,
  saveUserPassword,
  isPasswordSaved,
  savePasswordError,
  attemptForSavePassword,
  resetIsPasswordSavedVariable,
  resetIsProfileUserDataSavedVariable
}) {
  const [isModalShown, setIsModalShown] = useState(false);
  const [isCurrentPasswordHidden, setIsCurrentPasswordHidden] = useState(true);
  const [isNewPasswordHidden, setIsNewPasswordHidden] = useState(true);
  const [file, setFile] = useState(null);
  const [profilePictureBg, setProfilePictureBg] = useState(null);
  const [checked, setChecked] = useState(user ? user.notification : true);
  const [validationError, setValidationError] = useState(null);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForChangePassword, setIsLoadingForChangePassword] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [currentPasswordError, setCurrentPasswordError] = useState(null);

  const [success, setSuccess] = useState(false);

  const [{ currentPassword, newPassword, firstName, lastName }, setUserDataFields] = useState({
    currentPassword: '',
    newPassword: '',
    firstName: '',
    lastName: ''
  });

  useEffect(() => {
    if (user._id) {
      fetchProfileUser({ id: user._id });
    }
  }, []);

  // TODO: refactor this whole component to more transactional approach
  // for example, dispatch a single UPDATE_PROFILE, and handle all the separate requests
  // for different pieces of data (photo & userdata & creds) inside a saga
  // PS: as an example, check fee settings
  useEffect(() => {
    if (isProfileUserDataSaved && !uploadPhotoError && !isUploadingPhoto) {
      resetIsProfileUserDataSavedVariable();
      setIsLoading(false);
      setSuccessMessage('Successfully saved.');
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, SUCCESS_MESSAGE_DELAY_MS);
    }
  }, [isProfileUserDataSaved, attemptForSaveProfileData, uploadPhotoError, isUploadingPhoto]);

  useEffect(() => {
    if (isPhotoLoaded) {
      fetchProfileUser({ id: user._id });
    }
  }, [isPhotoLoaded]);

  useEffect(() => {
    if (user) {
      setUserDataFields(values => ({
        ...values,
        firstName: user.firstName || '',
        lastName: user.lastName || ''
      }));
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(false);

    setErrors([
      ...errors,
      ...(fetchProfileUserError ? [fetchProfileUserError] : []),
      ...(saveProfileUserDataError ? [saveProfileUserDataError] : []),
      ...(uploadPhotoError ? [uploadPhotoError] : [])
    ]);
  }, [fetchProfileUserError, saveProfileUserDataError, uploadPhotoError, attemptForSaveProfileData]);

  useEffect(() => {
    if (isPasswordSaved) {
      resetIsPasswordSavedVariable();

      setIsLoadingForChangePassword(false);
      setUserDataFields(values => ({
        ...values,
        currentPassword: '',
        newPassword: ''
      }));
      setIsCurrentPasswordHidden(true);
      setIsNewPasswordHidden(true);

      setSuccessMessage('Successfully saved.');
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, SUCCESS_MESSAGE_DELAY_MS);

      closeModal();
    }
  }, [isPasswordSaved, attemptForSavePassword]);

  useEffect(() => {
    if (savePasswordError) {
      setIsLoadingForChangePassword(false);
      setPasswordError(savePasswordError);
      setUserDataFields(values => ({
        ...values,
        currentPassword: '',
        newPassword: ''
      }));
    }
  }, [savePasswordError, attemptForSavePassword]);

  const togglePasswordVisibility = name => {
    if (name === 'currentPassword') {
      setIsCurrentPasswordHidden(!isCurrentPasswordHidden);
    } else if (name === 'newPassword') {
      setIsNewPasswordHidden(!isNewPasswordHidden);
    }
  };

  const resetPasswordFields = () => {
    setUserDataFields(values => ({
      ...values,
      currentPassword: '',
      newPassword: ''
    }));
  };

  const closeModal = () => {
    setIsModalShown(false);
  };

  const showModal = () => {
    setIsModalShown(true);
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setUserDataFields(values => ({
      ...values,
      [name]: value
    }));
  };

  const loadPhoto = file => {
    if (!file) return;

    loadImage(
      file,
      canvas => {
        setProfilePictureBg(canvas.toDataURL());
      },
      { orientation: true }
    );
    setFile(file);
  };

  const handleToggle = () => {
    setChecked(!checked);
  };

  const closeAndReset = () => {
    closeModal();
    resetPasswordFields();
    setValidationError(null);
    setPasswordError(null);
    setCurrentPasswordError(null);
  };

  const changePassword = () => {
    setPasswordError(null);
    const currentPasswordError = validateFields(currentPassword);
    const newPasswordErrors = validatePassword(newPassword);

    if (!objectHelpers.isEmpty(newPasswordErrors) || currentPasswordError) {
      setValidationError(Object.keys(newPasswordErrors)[0]);
      setCurrentPasswordError(currentPasswordError);
      return;
    }

    setValidationError(null);
    setCurrentPasswordError(null);

    setIsLoadingForChangePassword(true);
    saveUserPassword({
      currentPassword,
      newPassword
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    const data = {
      firstName,
      lastName,
      notifications: checked
    };

    setErrors([]);
    setIsLoading(true);
    saveProfileUserData(data);

    if (file) {
      uploadPhoto(file);
    }
  };

  const handleResetPhoto = () => {
    resetIsPhotoLoadedVariable(false);
  };

  const isLoadingOrUploadingPhoto = isLoading || isUploadingPhoto;

  const saveButton = (
    <StyledUI.Button
      type="submit"
      width="46%"
      background={isLoadingOrUploadingPhoto ? colors.theLightestViolet : colors.violet}
      color={colors.white}
      margin="25px 0 0 0 "
      border={isLoadingOrUploadingPhoto ? colors.theLightestViolet : colors.violet}
      onClick={handleResetPhoto}
    >
      {isLoadingOrUploadingPhoto ? <Loading /> : <span data-test="my-profile-save-btn">Save</span>}
    </StyledUI.Button>
  );

  const uploadImageButton = (
    <Styled.Button type="button" color={colors.violet} border={colors.violet} data-test="my-profile-upload-photo-btn">
      Upload Image
      <StyledUI.FileInput
        style={{ border: '1px solid indigo' }}
        onChange={event => loadPhoto(event.target.files[0])}
        type="file"
      />
    </Styled.Button>
  );

  const uploadImageAvatar = <StyledUI.Img src={profilePictureBg} borderRadius="50%" />;

  return (
    <>
      <Profile
        submit={handleSubmit}
        user={user ? user : null}
        successMessage={success ? successMessage : ''}
        button={saveButton}
        upload={{
          button: uploadImageButton,
          image: profilePictureBg ? (
            uploadImageAvatar
          ) : user.photo ? (
            <Image photo={user.photo} width="40px" height="40px" />
          ) : (
            <StyledUI.AvatarContainer>
              <StyledUI.Flex width="44px" height="44px">
                <Logo />
              </StyledUI.Flex>
            </StyledUI.AvatarContainer>
          )
        }}
        isMyProfile={true}
        errors={errors}
      >
        <StyledUI.Flex direction="column">
          <StyledUI.Flex wrap="wrap" justify="space-between">
            <Styled.Label width="46%">
              First name
              <StyledUI.Input
                data-test="my-profile-firstname"
                onChange={handleChange}
                value={firstName}
                name="firstName"
                height="40px"
              />
            </Styled.Label>

            <Styled.Label width="46%">
              Last name
              <StyledUI.Input
                data-test="my-profile-lastname"
                onChange={handleChange}
                value={lastName}
                name="lastName"
                height="40px"
              />
            </Styled.Label>
          </StyledUI.Flex>

          <StyledUI.Flex>
            <Styled.Label>
              Email
              <StyledUI.Input
                data-test="my-profile-email"
                disable
                height="40px"
                readOnly
                value={user ? user.email : ''}
              />
            </Styled.Label>
          </StyledUI.Flex>
        </StyledUI.Flex>

        <StyledUI.Flex justify="space-between" align="center" padding="10px 0 10px 0" border wrap="wrap">
          <StyledUI.Button
            type="button"
            onClick={showModal}
            width="46%"
            margin="0 0 15px 0"
            color={colors.violet}
            border={colors.violet}
            data-test="my-profile-change-password-btn"
          >
            Change password
          </StyledUI.Button>

          <StyledUI.CheckBoxWrapper margin="0 0 15px 0">
            <StyledUI.CheckBox
              data-test="my-profile-notifications-checkbox"
              onChange={handleToggle}
              checked={checked}
              type="checkbox"
              id="checkbox"
            />
            <StyledUI.CheckBoxLabel
              htmlFor="checkbox"
              style={
                checked
                  ? {
                      background: colors.violet,
                      ':after': {
                        content: '',
                        display: 'block',
                        borderRadius: '50%',
                        width: '18px',
                        height: '18px',
                        marginLeft: '21px',
                        transition: '0.2s'
                      }
                    }
                  : {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '42px',
                      height: '26px',
                      borderRadius: '15px',
                      background: colors.middleGray,
                      cursor: 'pointer',
                      ':after': {
                        content: '',
                        display: 'block',
                        borderRadius: '50%',
                        width: '18px',
                        height: '18px',
                        margin: '4px',
                        background: colors.white,
                        boxShadow: '1px 3px 3px 1px rgba(0, 0, 0, 0.2)',
                        transition: '0.2s'
                      }
                    }
              }
            />
            <StyledUI.Span margin="0 0 0 10px">Email notifications</StyledUI.Span>
          </StyledUI.CheckBoxWrapper>
        </StyledUI.Flex>
      </Profile>

      {isModalShown && (
        <Modal
          close={closeAndReset}
          title={'Change password'}
          buttons={
            <StyledUI.Flex wrap="wrap" width="100%" justify="flex-end">
              <Styled.ModalButton
                data-test="my-profile-modal-cancel-btn"
                order={2}
                onClick={closeAndReset}
                color={colors.violet}
                border={colors.violet}
              >
                Cancel
              </Styled.ModalButton>
              <Styled.ModalButton
                order={1}
                onClick={changePassword}
                background={isLoadingForChangePassword ? colors.theLightestViolet : colors.violet}
                color={colors.white}
                border={isLoadingForChangePassword ? colors.theLightestViolet : colors.violet}
                data-test="my-profile-modal-save-btn"
              >
                {isLoadingForChangePassword ? <Loading /> : <span>Save</span>}
              </Styled.ModalButton>
            </StyledUI.Flex>
          }
        >
          <StyledUI.Flex width="100%" direction="column">
            <StyledUI.Label margin="0 0 5px 0">
              Current password
              <StyledUI.Input
                data-test="my-profile-modal-current-password"
                value={currentPassword}
                onChange={handleChange}
                type={isCurrentPasswordHidden ? 'password' : 'text'}
                name="currentPassword"
                error={currentPasswordError}
              />
              {currentPasswordError && <StyledUI.Error isAbsolute>{currentPasswordError}</StyledUI.Error>}
              <StyledUI.IconWrapper
                data-test="my-profile-modal-eye-icon-for-current-pass"
                data-password="currentPassword"
                onClick={() => togglePasswordVisibility('currentPassword')}
              >
                <Eye color={colors.gray} color={isCurrentPasswordHidden ? colors.gray : colors.violet} />
              </StyledUI.IconWrapper>
            </StyledUI.Label>
            <StyledUI.Label margin="10px 0 5px">
              New password
              <StyledUI.Input
                data-test="my-profile-modal-new-password"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                type={isNewPasswordHidden ? 'password' : 'text'}
                error={validationError}
              />
              <StyledUI.IconWrapper
                data-test="my-profile-modal-eye-icon-for-new-pass"
                data-password="newPassword"
                onClick={() => togglePasswordVisibility('newPassword')}
              >
                <Eye color={colors.gray} color={isNewPasswordHidden ? colors.gray : colors.violet} />
              </StyledUI.IconWrapper>
              {validationError && (
                <StyledUI.Error data-test="my-profile-modal-new-pass-error" isAbsolute>
                  {validationError}
                </StyledUI.Error>
              )}
              {passwordError && (
                <StyledUI.Error margin="5px 0 0 0" isAbsolute fontSize="16px" bottom="-25px">
                  {passwordError}
                </StyledUI.Error>
              )}
            </StyledUI.Label>
          </StyledUI.Flex>
        </Modal>
      )}
    </>
  );
}
