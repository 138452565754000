import styled from 'styled-components';

// Breakpoints
import { breakpoints } from '@theme/breakpoints';

// Theme
import { mainGradient } from '@theme/colors';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 99px;
  padding: 14px 20px;

  background: linear-gradient(
    to left,
    ${mainGradient.darkViolet},
    ${mainGradient.lighterViolet},
    ${mainGradient.purpleViolet},
    ${mainGradient.purple}
  );
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileMenu = styled.div`
  margin-left: 16px;
  position: relative;
  z-index: 15;

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;
