// Absolute
import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';

// Images
import { default as SignOutImage } from '../vector/SignOut';

// Styles
import * as Styled from './styles';
import * as StyledUI from '../UI';

// Components
import Image from '../Image/Image';
import Logo from '../Logo';

UserNavMenu.propTypes = {
  user: PropType.shape({
    firstName: PropType.string,
    lastName: PropType.string,
    email: PropType.string.isRequired
  }).isRequired,
  signOut: PropType.func.isRequired
};

export default function UserNavMenu({ user, signOut }) {
  return (
    <Styled.Container>
      <Styled.TextDataContainer>
        {(user.firstName || user.lastName) && (
          <Styled.Username data-test="navbar-username" title={`${user.firstName} ${user.lastName}`}>
            {user.firstName} {user.lastName}
          </Styled.Username>
        )}
        <Styled.Email data-test="navbar-email">{user.email}</Styled.Email>
      </Styled.TextDataContainer>

      <Link to="/profile">
        {user.photo ? (
          <StyledUI.AvatarContainer>
            <Image photo={user.photo} />
          </StyledUI.AvatarContainer>
        ) : (
          <StyledUI.AvatarContainer size="md">
            <StyledUI.Flex width="24px" height="24px">
              <Logo />
            </StyledUI.Flex>
          </StyledUI.AvatarContainer>
        )}
      </Link>

      <Styled.SignOutContainer>
        <SignOutImage data-test="navbar-signout-btn" onClick={signOut} />
      </Styled.SignOutContainer>
    </Styled.Container>
  );
}
