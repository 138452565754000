// Absolute
import React from 'react';

// Theme
import * as colors from '@theme/colors';

export default function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 49 49">
      <path
        fill={colors.white}
        fillRule="evenodd"
        d="M3.405 47.462l-1.287 1.305-.003-.002-.004.004a7.273 7.273 0 0 1-2.096-5.604c.1-1.72.8-3.412 2.097-4.728L13.7 26.684c.395-.4.673-.875.834-1.38a3.466 3.466 0 0 0-.884-3.34L2.253 10.49C-.558 7.66-.562 3.066 2.244.23l16.487 16.6c4.147 4.176 4.153 10.951.013 15.134L3.405 47.462zM48.85 5.94a7.321 7.321 0 0 1-2.09 4.622L35.172 22.315a3.462 3.462 0 0 0-.831 1.368 3.465 3.465 0 0 0 .881 3.351L46.62 38.51c2.812 2.83 2.816 7.424.009 10.26l-16.488-16.6c-4.147-4.176-4.152-10.951-.012-15.134L45.372 1.634 46.754.232l.002.003.005-.005a7.264 7.264 0 0 1 2.089 5.71zM8.12 21.877a3 3 0 1 1-4.24 4.246 3 3 0 0 1 4.24-4.246zm32.76 4.246a3 3 0 1 1 4.24-4.247 3 3 0 0 1-4.24 4.247z"
      />
    </svg>
  );
}
