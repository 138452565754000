import React from 'react';

import { StyledWrapper, StyledTitle, StyledContent, ErrorContent } from './style';

const Widget = ({ title, children, error }) => (
  <StyledWrapper>
    <StyledTitle>{title}</StyledTitle>
    {error ? <ErrorContent>Not available</ErrorContent> : <StyledContent>{children}</StyledContent>}
  </StyledWrapper>
);

export default Widget;
