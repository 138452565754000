export const getDate = date => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = getMonthName(newDate.getMonth());
  const day = newDate.getDate();

  return `${month} ${day}, ${year}`;
};

const getMonthName = number => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  return monthNames[number];
};

export const getTime = date => {
  return new Date(new Date(date).toISOString()).toLocaleTimeString(undefined, { hour12: false });
};
