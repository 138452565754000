import styled, { css } from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import * as colors from '@theme/colors';

export const InfoText = styled.p`
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};
`;

export const InputsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;

  & > * {
    width: 100%;
    min-width: 200px;
    max-width: 270px;
  }

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;

    & > *:not(:first-child) {
      margin-left: 48px;
    }
  }

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;

    & > *:not(:first-child) {
      margin-top: 16px;
    }
  }
`;

export const RootContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid ${colors.lightGray};
`;

export const FormContainer = styled.div`
  width: 100%;
  padding: 35px 0;
  &:first-child {
    padding-right: 40px;
  }
  &:last-child {
    padding-left: 40px;
    border-left: 1px solid ${colors.lightGray};
  }
`;

export const Title = styled.span`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: ${colors.black};
  margin-right: ${({ margin }) => margin ?? 0};
`;

export const FormLabel = styled.span`
  color: ${colors.black};
  font-weight: ${({ weight }) => weight ?? 'normal'};
`;

export const RowText = styled.span`
  font-size: 12px;
  color: ${({ light }) => (light ? colors.darkGray : colors.black)};
`;
