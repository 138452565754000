// Absolute imports
import React from 'react';
import { Switch, Route } from 'react-router';
import PropTypes from 'prop-types';

// Components
import SignIn from '../SignIn/ConnectedSignIn';
import CreatePasswordAfterFirstSignIn from '../CreatePassword/ConnectedCreatePasswordAfterFirstSignIn';
import CreatePasswordAfterRestore from '../CreatePassword/ConnectedCreatePasswordAfterRestore';
import Payments from '../Payments';
import Events from '../Events/ConnectedEvents';
import AuthenticatedSwitchLayout from './AuthenticatedSwitchLayout';
import Restore from '../Restore/ConnectedRestore';
import Settings from '../Settings';
import Users from '../Users/ConnectedUsers';
import Finance from '../Finance/ConnectedFinance';
import UsersProfile from '../UsersProfile/ConnectedUsersProfile';
import MyProfile from '../MyProfile/ConnectedMyProfile';

// Styles
import GlobalStyle from '@theme/global-style';
import 'semantic-ui-css/semantic.min.css'

// Routes
import * as routes from '@routes';

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  shouldCreatePassword: PropTypes.bool.isRequired
};

export default function App({ isAuthenticated, shouldCreatePassword }) {
  return (
    <AuthenticatedSwitchLayout isAuthenticated={isAuthenticated} shouldCreatePassword={shouldCreatePassword}>
      <GlobalStyle />
      <Switch>
        <Route exact path={routes.SIGN_IN} component={SignIn} />
        <Route exact path={routes.CREATE_PASSWORD} component={CreatePasswordAfterFirstSignIn} />
        <Route exact path={routes.RESTORE} component={Restore} />
        <Route exact path={routes.CREATE_PASSWORD_AFTER_RESTORE} component={CreatePasswordAfterRestore} />
        <Route exact path={routes.EVENTS} component={Events} />
        <Route path={routes.PAYMENTS} component={Payments} />
        <Route path={routes.SETTINGS} component={Settings} />
        <Route exact path={routes.USERS} component={Users} />
        <Route exact path={routes.FINANCE} component={Finance} />
        <Route exact path={`${routes.USER_PROFILE}/:id`} component={UsersProfile} />
        <Route exact path={routes.MY_PROFILE} component={MyProfile} />
        <Route path={routes.NOT_FOUND}>
          <span>Not found</span>
        </Route>
      </Switch>
    </AuthenticatedSwitchLayout>
  );
}
