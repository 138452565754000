// @ts-check

// @ts-ignore
import { Decimal } from 'decimal.js';
import * as stringHelpers from './stringHelpers';
import * as constants from '@constants';
import {
  MIN_8_CHARACTERS,
  AT_LEAST_ONE_SPECIAL_CHARACTER,
  AT_LEAST_ONE_NUMBER,
  EMPTY_SPACES_ARE_NOT_ALLOWED,
  NEW_PASSWORD_IS_REQUIRED,
  CURRENT_PASSWORD_IS_REQUIRED,
  MIN_PASSWORD_LENGTH
} from '@constants';

export const validatePassword = password => {
  const errors = {};

  if (password.length < MIN_PASSWORD_LENGTH) {
    errors[MIN_8_CHARACTERS] = true;
  }

  if (!/[!@#$%&*_\^\._+-]/.test(password)) {
    errors[AT_LEAST_ONE_SPECIAL_CHARACTER] = true;
  }

  if (!/[0-9]/.test(password)) {
    errors[AT_LEAST_ONE_NUMBER] = true;
  }

  if (password.includes(' ')) {
    errors[EMPTY_SPACES_ARE_NOT_ALLOWED] = true;
  }

  return errors;
};

export const validatePasswordFields = (currentPassword, newPassword) => {
  const errors = [];

  if (!newPassword) {
    errors.push(NEW_PASSWORD_IS_REQUIRED);
  }

  if (!currentPassword) {
    errors.push(CURRENT_PASSWORD_IS_REQUIRED);
  }

  const validationErrors = validatePassword(newPassword);

  if (Object.keys(validationErrors).length) {
    Object.keys(validationErrors).map(error => errors.push(error));
  }

  return errors;
};

export const validateFields = value => {
  if (!value.length) return 'This field is required.';
  return null;
};

export const createRangeFieldValidator = ({ from, to, message }) => value => {
  const decimalValue = new Decimal(value || '0');
  const decimalFrom = new Decimal(from);
  const decimalTo = new Decimal(to);
  if (decimalValue.lessThan(decimalFrom) || decimalValue.greaterThan(decimalTo)) {
    return message ?? `Should be between ${formatCurrency(from)} and ${formatCurrency(to)}`;
  } else {
    return null;
  }
};

export const validateNonEmptyField = value => {
  if (stringHelpers.isNotEmpty(value)) {
    return null;
  } else {
    return 'Should not be empty';
  }
};

export const validateEmailField = value => {
  if (typeof value !== 'string') {
    return 'Should be string';
  }
  if (/\s/.test(value)) {
    return 'Should not contain whitespaces';
  }
  const characters = value.split('');
  const MIN_EMAIL_LENGTH = 3;
  const AT_SIGN = '@';

  if (characters.length < MIN_EMAIL_LENGTH) {
    return 'Should be at least 3 characters long';
  }
  const atSignsCount = characters.filter(character => character === AT_SIGN).length;
  if (atSignsCount !== 1) {
    return `Should contain exactly one ${AT_SIGN} character`;
  }
  const [preAtSign, postAtSign] = value.split('@');
  if (preAtSign.length === 0) {
    return `Should contain at least one character before ${AT_SIGN}`;
  }
  if (postAtSign.length === 0) {
    return `Should contain at least one character after ${AT_SIGN}`;
  }
  return null;
};

const formatCurrency = stringHelpers.formatWithDigitDelimiter(constants.CURRENCY_DELIMITER)(
  constants.CURRENCY_DELIMITER_PLACES
);
