// Absolute
import React from 'react';

// Custom hooks
import { useShallowSelector, useActions } from '@helpers/hooks';

import Restore from './Restore';
import * as actions from '@actions/auth';

// Selectors
import * as authSelectors from '@reducers/auth';

export default function ConnectedRestore() {
  const resetPasswordError = useShallowSelector(authSelectors.resetPasswordErrorSelector);
  const isPasswordReset = useShallowSelector(authSelectors.isPasswordResetSelector);
  const resetPasswordAttempt = useShallowSelector(authSelectors.resetPasswordAttemptSelector);

  const { resetPassword, resetIsPasswordResetVariable } = useActions(actions);

  const props = {
    resetPassword,
    resetPasswordError,
    isPasswordReset,
    resetPasswordAttempt,
    resetIsPasswordResetVariable
  };

  return <Restore {...props} />;
}
