import styled from 'styled-components';

// Theme
import * as colors from '@theme/colors';

export const PaginationRoot = styled.div`
  width: 100%;
  display: flex;
  padding: 5px 0;
  margin: 20px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
`;

export const PaginationLink = styled.button`
  background: ${colors.white};
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 2px 7px;
  cursor: pointer;
  border: 1px solid ${colors.lightGray};

  ${({ disabled }) => disabled && `background: ${colors.white};`}
`;
