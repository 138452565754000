// Abolute
import React from 'react';
import CreatePassword from './CreatePassword';
import * as actions from '@actions/auth';

// Custom hooks
import { useShallowSelector, useActions } from '@helpers/hooks';

// Selectors
import * as authSelectors from '@reducers/auth';

export default function ConnectedCreatePasswordAfterFirstSignIn() {
  const createNewPasswordError = useShallowSelector(authSelectors.createNewPasswordErrorSelector);
  const createPasswordAttempt = useShallowSelector(authSelectors.createPasswordAttemptSelector);
  const isNewPasswordCreated = useShallowSelector(authSelectors.isNewPasswordCreatedSelector);

  const { patchPassword } = useActions(actions);

  const props = {
    submit: patchPassword,
    createNewPasswordError,
    createPasswordAttempt,
    isNewPasswordCreated
  };

  return <CreatePassword {...props} />;
}
