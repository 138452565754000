// Absolute imports
import React from 'react';

// Components
import UnauthenticatedAppLayout from '../layouts/UnauthenticatedAppLayout/UnauthenticatedAppLayout';
import AuthenticatedAppLayout from '../layouts/AuthenticatedAppLayout/AuthenticatedAppLayout';

export default function AuthenticatedSwitchLayout({ isAuthenticated, shouldCreatePassword, children }) {
  if (isAuthenticated && !shouldCreatePassword) {
    return <AuthenticatedAppLayout>{children}</AuthenticatedAppLayout>;
  } else {
    return <UnauthenticatedAppLayout>{children}</UnauthenticatedAppLayout>;
  }
}
