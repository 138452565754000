import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';

// Styles
import * as StyledUI from '../UI';

// Components
import Modal from '@components/Modal/Modal';
import PayoutWidgets from '@components/PayoutWidgets';
import Table from '@components/SmTable';
import Search from '@components/Search';
import CheckMark from '@components/CheckMark';
import Cross from '@components/Cross/Cross';
import { useSelector } from 'react-redux';
import { fetchOrdersErrorSelector, approvingOrderSelector, ordersCountSelector, ordersSelector, ordersLoadingSelector } from '@reducers/payments';
import { useActions } from '@helpers/hooks';
import * as paymentsActions from '@actions/payments';
import Loading from '@components/Loading/Loading';
import useQueryParams from '@helpers/hooks/useQueryParams';
import DatePicker from '@components/DatePicker';

import { TABLE_LIMIT } from '../../constants/index';
import { StyledButton } from './styles';

export default function PaymentOrders() {
  const [rejectedId, setRejectedId] = useState(null);
  const [isEmptyReason, setIsEmptyReason] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [isApproveError, setIsApproveError] = useState(false);
  const loading = useSelector(ordersLoadingSelector);
  const orders = useSelector(ordersSelector);
  const ordersCount = useSelector(ordersCountSelector);
  const fetchOrdersError = useSelector(fetchOrdersErrorSelector);
  const approvingOrder = useSelector(approvingOrderSelector);
  const { fetchPaymentOrders, approveOrder, rejectOrder } = useActions(paymentsActions);

  const [params, onChangeParams] = useQueryParams();

  useEffect(() => {
    fetchPaymentOrders({ ...params, status: 'processing', pair: 'EEX/BTC', limit: TABLE_LIMIT, skip: (params.page - 1) * TABLE_LIMIT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params)]);

  const headers = [
    {
      title: 'Time',
      key: 'createdAt',
      sorted: true,
      width: 1,
      filter: (props) => <DatePicker {...props} />,
    },
    {
      title: 'Email',
      key: 'email',
      width: 1,
      filter: (props) => <Search clear {...props} />
    },
    {
      title: 'Sent',
      key: 'tokensExpected',
      sorted: true,
      width: 1,
      filter: (props) => <Search clear {...props} />
    },
    {
      title: 'Expects',
      key: 'fundsExpected',
      sorted: true,
      width: 1,
      filter: (props) => <Search clear {...props} />
    },
    {
      title: 'Exchange rate',
      key: 'exchangeRate',
      width: 1,
    },
    {
      title: 'Possibility to pay',
      key: 'possibilityToPay',
      width: 1,
    },
    {
      title: 'Transaction',
      key: 'transaction',
      width: 1,
    },
    {
      title: '',
      key: 'buttons',
      width: 1,
    }
  ];

  const tableItemMapper = order => [
    {
      title: format(new Date(order.createdAt), 'dd.MM.yyyy, HH:mm'), 
    },
    {
      title: order.email
    },
    {
      title: `${order.tokens} EEX`
    },
    {
      title: `${order.fundsExpected} BTC`
    },
    {
      title: `1 EEX= $ ${order.price} / 1 BTC= $ ${parseFloat(order.rate).toFixed(1)}`
    },
    {
      title: order.possibilityToPay ? <CheckMark /> : <Cross color="#ba2612" />
    },
    {
      title: order.eexTx ? (
        <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_TRANSACTION_LINK}/${order.eexTx}`}>
          Link
        </a>
      ) : (
        '-'
      )
    },
    {
      title: (
        <div style={{ display: 'flex' }}>
          <StyledUI.Button
            style={{ fontSize: 12, width: 70, height: 28, textTransform: 'none', border: '1px solid rgba(186, 38, 18, 0.2)', color: '#ba2612' }}
            onClick={() => setRejectedId(order._id)}
          >
            Reject
          </StyledUI.Button>
          <StyledUI.Button
            style={{ fontSize: 12, width: 70, height: 28, textTransform: 'none', border: '1px solid rgba(68, 30, 218, 0.2)', color: '#441eda' }}
            disabled={approvingOrder === order._id}
            onClick={() => {
              if (order.possibilityToPay) {
                approveOrder({ transactionId: order._id });
                onChangeParams({ page: 1 });
                return;
              }
              setIsApproveError(true);
            }}
          >
            {approvingOrder === order._id ? <Loading /> : 'Approve'}
          </StyledUI.Button>
        </div>
      )
    }
  ];

  return (
    <>
      {rejectedId && (
        <Modal
          title="Describe rejection reason"
          childrenWrapper={{ padding: '16px' }}
          close={() => setRejectedId(null)}
        >
          <StyledUI.Flex direction="column" align="flex-end" style={{ width: '100%' }}>
            <StyledUI.TextArea
              value={rejectReason}
              onChange={e => {
                if (isEmptyReason) {
                  setIsEmptyReason(!isEmptyReason);
                }
                setRejectReason(e.target.value);
              }}
              style={{ width: '100%', margin: '0', marginBottom: '16px' }}
              placeholder="Enter the reason"
            />
            <StyledUI.Flex align="center" style={{ width: '100%' }}>
              {isEmptyReason && (
                <div style={{ color: '#ba2612' }}>You must describe the reason!</div>
              )}
              <StyledButton
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  if (!rejectReason) {
                    setIsEmptyReason(true);
                    return;
                  }
                  rejectOrder({ transactionId: rejectedId, rejectReason });
                  setRejectedId(null);
                  onChangeParams(1);
                }}
              >
                <span>Send and reject</span>
              </StyledButton>
            </StyledUI.Flex>
          </StyledUI.Flex>
        </Modal>
      )}
      {isApproveError && (
        <Modal
          title="Order cannot be approved"
          close={() => setIsApproveError(false)}
          buttons={<StyledButton onClick={() => setIsApproveError(false)}>GOT IT</StyledButton>}
        >
          <>There are not enough BTC on the wallet for payment, replenish your account!</>
        </Modal>
      )}
      <StyledUI.Flex wrap="wrap">
        <PayoutWidgets />
      </StyledUI.Flex>
      {fetchOrdersError && <StyledUI.Error>{fetchOrdersError}</StyledUI.Error>}
      {loading ? (
        <div style={{ height: 540 }}>
          <Loading />
        </div>
      ) : (
        <Table 
          headers={headers} 
          items={orders} 
          totalCount={ordersCount}
          transformItem={tableItemMapper} 
          pagination={{ totalPages: Math.ceil(ordersCount / TABLE_LIMIT) }} 
        />
      )}
    </>
  );
}
