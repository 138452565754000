// Libraries
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// Components
import Table from '../Table/Table';
import Pagination from '../Pagination/Pagination';
// Constants
import * as constants from '@constants';
// Helpers
import * as stringHelpers from '@helpers/stringHelpers';
import { getDate, getTime } from '@helpers';
// Styles
import Loading from '../Loading/Loading';
import * as Styled from './styles';
import * as paymentsActions from '@actions/payments';
import { useActions } from '@helpers/hooks';
import { userOrdersLoadingSelector, userOrdersCountSelector, userOrdersSelector } from '@reducers/payments';

export default function TransactionHistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const items = useSelector(userOrdersSelector);
  const total = useSelector(userOrdersCountSelector);
  const loading = useSelector(userOrdersLoadingSelector);
  const { fetchUserTransactions } = useActions(paymentsActions);

  const headers = useMemo(() => ['Date', 'Pair', 'Status', 'Paid', 'Received', 'User ID', 'Beneficiary', 'TX'], []);
  const displayItems = useMemo(
    () =>
      items.map(item => {
        const [from, to] = item.pair
          .trim()
          .toUpperCase()
          .split('/');
        return {
          date: `${getDate(item.createdAt)} ${getTime(item.createdAt)}`,
          pair: item.pair,
          status: stringHelpers.capitalize(item.status),
          paid: (to === 'EEX' ? item.funds : item.tokens) + ' ' + from,
          received: (to === 'EEX' ? item.tokens : item.funds) + ' ' + to,
          userID: item.userId,
          beneficiary: item.beneficiary,
          tX: item.eexTx
        };
      }),
    [items]
  );

  useEffect(() => {
    fetchUserTransactions({ limit: constants.TRANSACTIONS_PAGE_DEFAULT_LIMIT, skip: 0 });
  }, [fetchUserTransactions]);

  const setPage = pageNumber => {
    setCurrentPage(pageNumber);

    fetchUserTransactions({
      limit: constants.TRANSACTIONS_PAGE_DEFAULT_LIMIT,
      skip: (pageNumber - 1) * constants.TRANSACTIONS_PAGE_DEFAULT_LIMIT
    });
  };

  return (
    <Styled.Wrapper>
      {loading ? (
        <div style={{ height: 540 }}>
          <Loading />
        </div>
      ) : (
        <Table headers={headers} items={displayItems} />
      )}
      <Pagination
        itemsPerPage={constants.TRANSACTIONS_PAGE_DEFAULT_LIMIT}
        totalCount={total}
        page={currentPage}
        setPage={setPage}
      />
    </Styled.Wrapper>
  );
}
