export const zip = (...arrays) => {
  const indexes = new Array(arrays.length).fill(0);
  const result = [];
  if (arrays.length === 0) {
    return result;
  }
  while (true) {
    const iterationResult = [];
    for (let arrayIndex = 0; arrayIndex < indexes.length; arrayIndex++) {
      const array = arrays[arrayIndex];
      const indexInArray = indexes[arrayIndex];
      if (indexInArray >= array.length) {
        return result;
      } else {
        iterationResult.push(array[indexInArray]);
        indexes[arrayIndex]++;
      }
    }
    result.push(iterationResult);
  }
};
