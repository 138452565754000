import React, { useState } from 'react';

// Styles
import * as StyledUI from '../UI';

// Components
import Logo from '../Logo';

export default function Image({ photo, width = '24px', height = '24px' }) {
  const [imageError, setImageError] = useState(false);
  const handleError = event => {
    setImageError(true);
  };

  return (
    <>
      {imageError ? (
        <StyledUI.Flex width={width} height={height}>
          <Logo />
        </StyledUI.Flex>
      ) : (
        <StyledUI.Img onError={handleError} src={photo} borderRadius="50%" />
      )}
    </>
  );
}
