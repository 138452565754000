// Absolute imports
import React, { useState, useEffect } from 'react';
import uuid from 'uuid/v4';
import PropTypes from 'prop-types';

// Styles
import * as Styled from './styles';

// Constants
import {
  PAGINATION_DOUBLE_STEP_PAGES_AMOUNT,
  PAGINATION_STEP_PAGES_AMOUNT,
  PAGINATION_VISIBLE_PAGES,
  DIFFERENCE_BETWEEN_CENTER_AND_OUTSIDE_PAGES,
  INITIAL_PAGE_TO_START_SET_CENTER_PAGE,
  FIRST_PAGE,
  INITIAL_PAGE_TO_START_SET_CENTER_PAGE_FOR_PREV_DIRECTION,
  INITIAL_CENTER_PAGE
} from '@constants';

// Theme
import * as colors from '@theme/colors';

Pagination.propTypes = {
  itemsPerPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired
};

export default function Pagination({ itemsPerPage, totalCount, setPage, page }) {
  const [activeItem, setActiveItem] = useState(1);
  const [centerPage, setCenterPage] = useState(INITIAL_CENTER_PAGE);

  const disabled = [];
  for (let i = 1; i <= PAGINATION_VISIBLE_PAGES; i++) {
    disabled.push(Math.ceil(totalCount / itemsPerPage) + i);
  }

  useEffect(() => {
    setPage(page);
    setActiveItem(page);
    if (page < INITIAL_PAGE_TO_START_SET_CENTER_PAGE_FOR_PREV_DIRECTION) {
      return;
    }
    setCenterPage(page);
  }, [page]);

  const pages = [];

  const firstVisiblePage = centerPage - DIFFERENCE_BETWEEN_CENTER_AND_OUTSIDE_PAGES;
  const lastVisiblePage = centerPage + DIFFERENCE_BETWEEN_CENTER_AND_OUTSIDE_PAGES;

  for (let i = firstVisiblePage; i <= lastVisiblePage; i++) {
    pages.push(i);
  }

  const paginate = (page, direction) => {
    if (!direction) {
      setPage(page);
      setActiveItem(page);
      if (page > INITIAL_PAGE_TO_START_SET_CENTER_PAGE) {
        setCenterPage(page);
      }
    }

    if (direction === 'prev') {
      if (page > FIRST_PAGE) {
        setPage(page - PAGINATION_STEP_PAGES_AMOUNT);
        setActiveItem(page - PAGINATION_STEP_PAGES_AMOUNT);

        if (page > INITIAL_CENTER_PAGE) {
          setCenterPage(page - PAGINATION_STEP_PAGES_AMOUNT);
        }
      }
    }

    if (direction === 'next') {
      if (page < Math.ceil(totalCount / itemsPerPage)) {
        setPage(page + 1);
        setActiveItem(page + 1);

        if (page >= INITIAL_PAGE_TO_START_SET_CENTER_PAGE_FOR_PREV_DIRECTION) {
          setCenterPage(page + 1);
        }
      }
    }

    if (direction === 'doublePrev') {
      if (page > 1) {
        setPage(page - PAGINATION_DOUBLE_STEP_PAGES_AMOUNT);
        setActiveItem(page - PAGINATION_DOUBLE_STEP_PAGES_AMOUNT);

        setCenterPage(page - PAGINATION_DOUBLE_STEP_PAGES_AMOUNT);

        if (page <= PAGINATION_VISIBLE_PAGES + INITIAL_CENTER_PAGE) {
          setCenterPage(INITIAL_CENTER_PAGE);
        }
      }

      if (page <= PAGINATION_DOUBLE_STEP_PAGES_AMOUNT) {
        setPage(1);
        setActiveItem(1);
      }
    }

    if (direction === 'doubleNext') {
      if (page < Math.ceil(totalCount / itemsPerPage) - PAGINATION_DOUBLE_STEP_PAGES_AMOUNT) {
        setPage(page + PAGINATION_DOUBLE_STEP_PAGES_AMOUNT);
        setActiveItem(page + PAGINATION_DOUBLE_STEP_PAGES_AMOUNT);

        setCenterPage(page + PAGINATION_DOUBLE_STEP_PAGES_AMOUNT);
      } else if (page < INITIAL_CENTER_PAGE) {
        setPage(Math.ceil(totalCount / itemsPerPage));
        setActiveItem(Math.ceil(totalCount / itemsPerPage));
        setCenterPage(INITIAL_CENTER_PAGE);
      } else {
        setPage(Math.ceil(totalCount / itemsPerPage));
        setActiveItem(Math.ceil(totalCount / itemsPerPage));
        setCenterPage(Math.ceil(totalCount / itemsPerPage));
      }
    }
  };

  return (
    <Styled.PaginationRoot>
      <Styled.PaginationLink
        style={{ color: colors.violet }}
        onClick={() => paginate(page, 'doublePrev')}
        data-test="pagination-double-prev"
      >
        {'«'}
      </Styled.PaginationLink>
      <Styled.PaginationLink
        style={{ color: colors.violet }}
        onClick={() => paginate(page, 'prev')}
        previous
        data-test="pagination-prev"
      >
        {'‹'}
      </Styled.PaginationLink>
      {pages.map(page => (
        <Styled.PaginationLink
          key={uuid()}
          style={
            activeItem === page
              ? { background: colors.violet, color: colors.white }
              : disabled.includes(page)
              ? { color: colors.gray }
              : { color: colors.violet }
          }
          disabled={disabled.includes(page)}
          onClick={() => paginate(page)}
          data-test="pagination-number"
        >
          {page}
        </Styled.PaginationLink>
      ))}

      <Styled.PaginationLink
        data-test="pagination-next"
        style={{ color: colors.violet }}
        onClick={() => paginate(page, 'next')}
      >
        ›
      </Styled.PaginationLink>
      <Styled.PaginationLink
        style={{ color: colors.violet }}
        onClick={() => paginate(page, 'doubleNext')}
        data-test="pagination-double-next"
      >
        {' '}
        »
      </Styled.PaginationLink>
    </Styled.PaginationRoot>
  );
}
