import { createAction } from 'redux-actions';

export const FETCH_PAYMENT_ORDERS = 'FETCH_PAYMENT_ORDERS';
export const FETCH_PAYMENT_ORDERS_SUCCESS = 'FETCH_PAYMENT_ORDERS_SUCCESS';
export const FETCH_PAYMENT_ORDERS_FAILURE = 'FETCH_PAYMENT_ORDERS_FAILURE';

export const fetchPaymentOrders = createAction(FETCH_PAYMENT_ORDERS);
export const fetchPaymentOrdersSuccess = createAction(FETCH_PAYMENT_ORDERS_SUCCESS);
export const fetchPaymentOrdersFailure = createAction(FETCH_PAYMENT_ORDERS_FAILURE);

export const FETCH_PAYOUT_INFO = 'FETCH_PAYOUT_INFO';
export const FETCH_PAYOUT_INFO_SUCCESS = 'FETCH_PAYOUT_INFO_SUCCESS';
export const FETCH_PAYOUT_INFO_FAILURE = 'FETCH_PAYOUT_INFO_FAILURE';
export const FETCH_PAYOUT_INFO_WALLET_FAILURE = 'FETCH_PAYOUT_INFO_WALLET_FAILURE';

export const fetchPayoutInfo = createAction(FETCH_PAYOUT_INFO);
export const fetchPayoutInfoSuccess = createAction(FETCH_PAYOUT_INFO_SUCCESS);
export const fetchPayoutInfoFailure = createAction(FETCH_PAYOUT_INFO_FAILURE);
export const fetchPayoutInfoWalletFailure = createAction(FETCH_PAYOUT_INFO_WALLET_FAILURE);

export const APPROVE_ORDER = 'APPROVE_ORDER';
export const APPROVE_ORDER_SUCCESS = 'APPROVE_ORDER_SUCCESS';
export const APPROVE_ORDER_FAILURE = 'APPROVE_ORDER_FAILURE';

export const approveOrder = createAction(APPROVE_ORDER);
export const approveOrderSuccess = createAction(APPROVE_ORDER_SUCCESS);
export const approveOrderFailure = createAction(APPROVE_ORDER_FAILURE);

export const REJECT_ORDER = 'REJECT_ORDER';
export const REJECT_ORDER_SUCCESS = 'REJECT_ORDER_SUCCESS';
export const REJECT_ORDER_FAILURE = 'REJECT_ORDER_FAILURE';

export const rejectOrder = createAction(REJECT_ORDER);
export const rejectOrderSuccess = createAction(REJECT_ORDER_SUCCESS);
export const rejectOrderFailure = createAction(REJECT_ORDER_FAILURE);

export const FETCH_USER_TRANSACTIONS = 'FETCH_USER_TRANSACTIONS';
export const FETCH_USER_TRANSACTIONS_SUCCESS = 'FETCH_USER_TRANSACTIONS_SUCCESS';
export const FETCH_USER_TRANSACTIONS_FAILURE = 'FETCH_USER_TRANSACTIONS_FAILURE';

export const fetchUserTransactions = createAction(FETCH_USER_TRANSACTIONS);
export const fetchUserTransactionsSuccess = createAction(FETCH_USER_TRANSACTIONS_SUCCESS);
export const fetchUserTransactionsFailure = createAction(FETCH_USER_TRANSACTIONS_FAILURE);